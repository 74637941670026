<template>
    <Head title="Ticket aanmaken" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex flex-wrap justify-between">
                <div>
                    <h1 class="text-2xl font-semibold text-gray-900">Ticket aanmaken</h1>
                </div>
                <div>
                    <NavButton v-if="can('view ticket')" :href="route('tickets.index')">Terug naar overzicht</NavButton>
                </div>
            </div>
        </template>


        <form method="POST" class="overflow-hidden bg-white p-6 shadow-sm sm:rounded-lg" @submit.prevent="createStore">
            <div class="grid grid-cols-12 gap-6">
                <div class="col-span-9">
                    <div class="grid grid-cols-6 gap-8">
                        <div class="col-span-6">
                            <Label for="title" value="Titel" required/>
                            <Input id="title" v-model="form.title" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="title" />
                        </div>

                        <div class="col-span-6">
                            <Label for="content" value="Inhoud" required/>
                            <vue-editor v-model="form.body"></vue-editor>
                        </div>
                    </div>
                </div>
                <div class="col-span-3 ">
                    <div class="grid col-span-1 gap-4">
                    <div class="">
                        <Label for="customer" value="Klant" required/>
                        <multiselect
                            v-model="form.customer_id"
                            :options="customerOptions"
                            :searchable="true"
                            :loading="isLoading"
                            :internal-search="false"
                            :clear-on-select="true"
                            :close-on-select="true"
                            :hide-selected="true"
                            :preserve-search="true"
                            :max-height="200"
                            :placeholder="'Klant zoeken...'"
                            @search-change="fetchCustomers"
                        />
                        <ValidationError name="customer" />
                    </div>

                    <div>
                        <Label for="order" value="Order" required/>
                        <multiselect
                            v-model="form.order_id"
                            :options="orderOptions"
                            :searchable="true"
                            :loading="isLoading"
                            :internal-search="false"
                            :clear-on-select="true"
                            :close-on-select="true"
                            :hide-selected="true"
                            :preserve-search="true"
                            :max-height="200"
                            :placeholder="'Bestelling zoeken...'"
                            @search-change="fetchOrders"
                            @input="onOrderChange"
                        />
                        <ValidationError name="order" />
                    </div>

                    <div>
                        <div class="col-span-6">
                            <Label for="department_id" value="Afdeling" required/>
                            <Select id="department_id" v-model="form.department_id" class="mt-1 block w-full">
                                <option value="" selected disabled>Maak een keuze</option>
                                <option v-for="department in departments" :key="department.id" :value="department.id" > {{ department.name}}</option>
                            </Select>
                            <ValidationError name="department_id" />
                        </div>
                    </div>

                    <div>
                        <div class="col-span-6">
                            <Label for="assigned_to" value="Toewijzen aan" required/>
                            <Select id="assigned_to" v-model="form.assigned_to" class="mt-1 block w-full">
                                <option value="" selected disabled hidden>Maak een keuze</option>
                                <option v-for="user in users" :key="user.id" :value="user.id"> {{ user.name}}</option>
                            </Select>
                            <ValidationError name="assigned_to" />
                        </div>
                    </div>

                    <div>
                        <div class="col-span-6">
                            <Label for="category" value="Categorie" required/>
                            <Select id="category" v-model="form.category" class="mt-1 block w-full">
                                <option value="" selected disabled hidden>Maak een keuze</option>
                                <option v-for="category in ticketCategories" :key="category.id" :value="category.id"> {{ category.name}}</option>
                            </Select>
                            <ValidationError name="category" />
                        </div>
                    </div>

                    <div>
                        <div class="col-span-6">
                            <Label for="priority" value="Prioriteit" required/>
                            <Select id="priority" v-model="form.priority" class="mt-1 block w-full">
                                <option value="" selected disabled hidden>Maak een keuze</option>
                                <option value="low">Laag</option>
                                <option value="normal">Normaal</option>
                                <option value="high">Hoog</option>
                            </Select>
                            <ValidationError name="priority" />
                        </div>
                    </div>

                    <div class="space-x-2 text-right">
                        <Button type="submit" :disabled="form.processing">Opslaan</Button>
                    </div>
                    </div>
                </div>
            </div>



        </form>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import Button from '@/Components/Button.vue'
import ValidationError from '@/Components/ValidationError.vue'
import NavButton from '@/Components/NavButton.vue'
import { VueEditor } from "vue3-editor";
import Multiselect from '@vueform/multiselect'
import Select from "@/Components/Select.vue";

export default {
    components: {
        Select,
        NavButton,
        Button,
        ValidationError,
        Input,
        Label,
        AuthenticatedLayout,
        Head,
        VueEditor,
        Multiselect,
    },
    props: {
        users: Object,
        ticketCategories: Object,
        departments: Object,
        order: Object,
        customer: Object,
    },
    data() {
        return {
            form: this.$inertia.form({
                title: '',
                order_id: null,
                customer_id: null,
                department_id: null,
                body: '',
                priority: '',
                assigned_to: null,
                category: null,
            }),
            orderOptions: [],
            customerOptions: [],
        }
    },
    mounted() {
        if (this.customer) {
            this.form.customer_id = this.customer.id;
            this.customerOptions = [{
                value: this.customer.id,
                label: `${this.customer.email} (${this.customer.firstname} ${this.customer.lastname}) #${this.customer.id}`,
            }];
        }
        if (this.order) {
            this.form.order_id = this.order.id;
            this.orderOptions = [{
                value: this.order.id,
                label: `${this.order.email} - Order extern # ${this.order.extern_id} - ${this.order.ordered_at}`,
                email: this.order.email,
                name: this.order.billing_firstname + ' ' + this.order.billing_lastname,
                customer_id: this.order.customer_id
            }];
        }
    },
    methods: {
        createStore() {
            this.form.post(route('tickets.store'), {
                preserveScroll: true
            })
        },

        fetchOrders(searchquery) {
            if (searchquery.length === 0) {
                return;
            }
            axios.get(route('tickets.search.orders', {query: searchquery, customer_id: this.form.customer_id}))
                .then(res => {
                    this.orderOptions = res.data.map(order => ({
                        value: order.id,
                        label: order.email + ' - Order extern # ' + order.extern_id + ' - ' + order.ordered_at,
                        email: order.email,
                        name: order.billing_firstname + ' ' + order.billing_lastname,
                        customer_id: order.customer_id
                    }));
                })
        },

        fetchCustomers(searchquery) {
            if (searchquery.length === 0) {
                return;
            }

            this.customerOptions = [];
            this.form.customer_id = 0;
            this.form.order_id = 0;

            axios.get(route('tickets.search.customers', {query: searchquery}))
                .then(res => {
                    this.customerOptions = res.data.map(customer => ({
                        value: customer.id,
                        label: customer.email + ' (' + customer.firstname + ' ' + customer.lastname + ') #' + customer.id,
                    }));

                });
        },

        onOrderChange(selectedID) {
            const order = this.orderOptions.find(option => option.value === selectedID);
            if (!order) return;
            this.form.customer_id = order.customer_id;

            this.customerOptions = [{
                value: this.form.customer_id,
                label: order.email + ' ('+ order.name +') #' + this.form.customer_id,
            }];
        },
    },
}
</script>
