<template>
    <Head :title="$t('shipments.incoming_shipments_check')" />

    <AuthenticatedLayout>
        <template #header>
            <h1 class="text-2xl font-semibold text-gray-900">{{ $t('shipments.incoming_shipments_check') }}</h1>
        </template>

        <div>
            <div class="flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('shipments.product') }}</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('shipments.reference_internal') }}</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('shipments.amount_expected') }}Verwachte aantal</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('shipments.amount_actual') }}Daadwerkelijke aantal</th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr v-for="(product, index) in incomingShipment.products" :key="product.id">
                                        <td class="py-6 px-6 text-sm">
                                            <div class="flex items-center">
                                                <img v-if="product.product.product.image" :src="product.product.product.image" :alt="product.product.product.name" class="mr-6 h-16 w-16 rounded object-cover object-center" />
                                                <div>
                                                    <div class="font-medium text-gray-900">
                                                        {{ product.product.product.name }}
                                                    </div>
                                                    <div class="mt-1">
                                                        {{ product.short_description_stripped }}
                                                    </div>
                                                    <div v-if="product.cost_price" class="mt-1">
                                                        {{ $t('shipments.price_costs') }}
                                                        {{ formatPrice(product.product.product.cost_price) }}
                                                    </div>
                                                    <div v-if="product.product.numbers_packed" class="mt-1">
                                                        {{ $t('shipments.in_package') }}
                                                        {{ product.product.numbers_packed }}
                                                        stuks
                                                    </div>
                                                    <div v-if="product.product.barcode" class="mt-1">
                                                        {{ $t('shipments.barcode') }}
                                                        {{ product.product.barcode }}
                                                    </div>
                                                    <ValidationError :name="'products.' + index + '.id'" />
                                                    <ValidationError :name="'products.' + index + '.actually_quantity'" />
                                                </div>
                                            </div>
                                        </td>

                                        <td class="whitespace-nowrap px-6 py-4 text-gray-900">
                                            {{ product.intern_reference }}
                                        </td>

                                        <td class="whitespace-nowrap px-6 py-4 text-gray-900">
                                            {{ product.expected_quantity }}
                                        </td>

                                        <td class="whitespace-nowrap px-6 py-4 text-gray-900">
                                            {{ product.actually_quantity ?? 0 }}

                                            <button type="button" class="ml-4 inline-flex h-6 w-6 items-center justify-center rounded-full border border-transparent bg-red-600 text-lg text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2" @click="undoBarcode(product.product.barcode)">-</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-6 flex items-center">
                <Input id="manualBarcode" v-model="manualBarcode" placeholder="Handmatig scannen" type="text" class="rounded-r-none py-2 text-sm" />
                <Button class="rounded-l-none py-2 text-sm" @click="checkBarcodeManually()">{{ $t('shipments.scan') }}</Button>
            </div>

            <div class="mt-6 flex items-center justify-center space-x-4">
                <NavButton
                    v-if="can('edit incoming shipment')"
                    :href="
                        route('incoming-shipments.edit', {
                            incoming_shipment: incomingShipment.id
                        })
                    "
                >
                    Bewerken
                </NavButton>
                <Button @click="openDenyIncomingShipment = true">{{ $t('shipments.reject') }}</Button>
                <Button @click="openAcceptIncomingShipment = true">{{ $t('shipments.approve') }}</Button>
            </div>

            <ConfirmationModal :show="openDenyIncomingShipment" @close="openDenyIncomingShipment = false">
                <template #title>{{ $t('shipments.reject') }}</template>

                <template #content>{{ $t('shipments.confirm_reject') }}</template>

                <template #footer>
                    <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="denyIncomingShipment()">{{ $t('shipments.yes') }}</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="openDenyIncomingShipment = false">{{ $t('shipments.no') }}</button>
                </template>
            </ConfirmationModal>

            <ConfirmationModal :show="openAcceptIncomingShipment" @close="openAcceptIncomingShipment = false">
                <template #title>{{ $t('shipments.approve') }}</template>

                <template #icon>
                    <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                </template>

                <template #content>{{ $t('shipments.confirm_approve') }}</template>

                <template #footer>
                    <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="acceptIncomingShipment()">{{ $t('shipments.yes') }}</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="openAcceptIncomingShipment = false">{{ $t('shipments.no') }}</button>
                </template>
            </ConfirmationModal>
        </div>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Button from '@/Components/Button.vue'
import NavButton from '@/Components/NavButton.vue'
import { useToast } from 'vue-toastification'
import ConfirmationModal from '@/Components/ConfirmationModal.vue'
import { CheckIcon } from '@heroicons/vue/24/outline'
import Input from '@/Components/Input.vue'
import ValidationError from '@/Components/ValidationError.vue'

export default {
    components: {
        ValidationError,
        Input,
        ConfirmationModal,
        NavButton,
        Button,
        AuthenticatedLayout,
        Head,
        CheckIcon
    },
    props: {
        incomingShipment: Object
    },

    setup() {
        const toast = useToast()
        return { toast }
    },

    data() {
        return {
            manualBarcode: '',
            openDenyIncomingShipment: false,
            openAcceptIncomingShipment: false,
            form: this.$inertia.form({}),
            acceptForm: this.$inertia.form({
                products: null
            })
        }
    },

    created() {
        window.addEventListener('paste', this.scanBarcodeFromEvent)
    },
    beforeUnmount() {
        window.removeEventListener('paste', this.scanBarcodeFromEvent)
    },
    methods: {
        checkBarcodeManually() {
            this.scanBarcode(this.manualBarcode)
            this.manualBarcode = ''
        },
        scanBarcodeFromEvent(event) {
            const target = event.target || event.srcElement

            if (target.tagName.toUpperCase() == 'INPUT') return
            if (target.tagName.toUpperCase() == 'TEXTAREA') return

            this.scanBarcode(event.clipboardData.getData('text'))
        },
        scanBarcode(barcode) {
            const stp = this
            let changed = 0

            _.each(this.incomingShipment.products, function (value) {
                if (value.product.barcode !== barcode) {
                    return
                }

                stp.toast.success('Gescand: ' + barcode, {
                    timeout: 1000
                })
                changed++

                value.actually_quantity = (value.actually_quantity ?? 0) + 1
            })

            if (changed === 0) {
                this.toast.error('Barcode niet gevonden: ' + barcode, {
                    timeout: 1000
                })
            }
        },
        undoBarcode(barcode) {
            _.each(this.incomingShipment.products, function (value) {
                if (value.product.barcode !== barcode) {
                    return
                }

                value.actually_quantity = (value.actually_quantity ?? 0) >= 1 ? value.actually_quantity - 1 : 0
            })
        },

        denyIncomingShipment() {
            this.form.put(
                route('incoming-shipments.deny', {
                    incoming_shipment: this.incomingShipment.id
                })
            )
        },

        acceptIncomingShipment() {
            this.acceptForm.products = _.map(this.incomingShipment.products, function (value) {
                return {
                    id: value.id.toString(),
                    actually_quantity: value.actually_quantity
                }
            })

            this.acceptForm.put(
                route('incoming-shipments.accept', {
                    incoming_shipment: this.incomingShipment.id
                }),
                {
                    onError: () => {
                        this.openAcceptIncomingShipment = false
                    }
                }
            )
        }
    }
}
</script>
