<template>
    <Head title="Wachtwoord vergeten" />

    <div class="mb-4 text-sm text-gray-600">{{ $t('auth.forgot_password') }}</div>

    <div v-if="status" class="mb-4 text-sm font-medium text-green-600">
        {{ status }}
    </div>

    <ValidationErrors class="mb-4" />

    <form @submit.prevent="submit">
        <div>
            <Label for="email" v-bind:value="$t('auth.email')" />
            <Input id="email" v-model="form.email" type="email" class="mt-1 block w-full" required autofocus autocomplete="username" />
        </div>

        <div class="mt-4 flex items-center justify-end">
            <Button :class="{ 'opacity-25': form.processing }" :disabled="form.processing">{{ $t('auth.send_link') }}</Button>
        </div>
    </form>
</template>

<script>
import Button from '@/Components/Button.vue'
import GuestLayout from '@/Layouts/Guest.vue'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import ValidationErrors from '@/Components/ValidationErrors.vue'
import { Head } from '@inertiajs/vue3'

export default {
    components: {
        Button,
        Input,
        Label,
        ValidationErrors,
        Head
    },
    layout: GuestLayout,

    props: {
        status: String
    },

    data() {
        return {
            form: this.$inertia.form({
                email: ''
            })
        }
    },

    methods: {
        submit() {
            this.form.post(this.route('password.email'))
        }
    }
}
</script>
