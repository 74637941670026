<template>
    <form method="POST" class="overflow-hidden bg-white p-6 shadow-sm sm:rounded-lg" @submit.prevent="emit('submit')">
        <div class="grid grid-cols-6 gap-8">
            <div class="col-span-3">
                <div class="py-5 sm:p-0">
                    <dl class="sm:divide-y sm:divide-gray-200">
                        <div class="sm:py-5">
                            <h3 class="text-lg font-semibold">{{ $t('credit-payment.order_info') }}</h3>
                        </div>
                        <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                            <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.id') }}</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                {{ order.extern_id }}
                            </dd>
                        </div>
                        <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                            <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.order_no') }}</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                {{ order.number }}
                            </dd>
                        </div>
                        <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                            <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.order_subtotal') }}</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                {{ formatPrice(order.order_subtotal / 100) }}
                            </dd>
                        </div>
                        <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                            <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.order_shipment') }}</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                {{ formatPrice((order.shipping_subtotal) / 100) }}
                            </dd>
                        </div>
                        <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                            <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.vat') }}</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                {{ formatPrice((order.order_tax + order.shipping_tax) / 100) }}
                            </dd>
                        </div>
                        <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                            <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.total_amount') }}</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                {{ formatPrice(order.total / 100) }}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
            <div class="col-span-3">
                <div class="py-5 sm:p-0">
                    <dl class="sm:divide-y sm:divide-gray-200">
                        <div class="sm:py-5">
                            <h3 class="text-lg font-semibold">{{ $t('credit-payment.customer_information') }}</h3>
                        </div>

                        <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                            <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.customer') }}</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                <div v-if="!order.customer">{{ $t('credit-payment.guest') }}</div>
                                <div v-else class="text-sm font-medium text-gray-900">
                                    <div>
                                        {{ order.customer.firstname }}
                                        {{ order.customer.lastname }}
                                    </div>
                                    <div class="text-sm text-gray-500">
                                        <div>
                                            {{ order.customer.email }}
                                        </div>
                                    </div>
                                </div>
                            </dd>
                        </div>

                        <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                            <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.invoice_address') }}
                            </dt>
                            <dd class="mt-1 sm:mt-0">
                                <div class="text-sm font-medium text-gray-900">
                                    <div v-if="order.billing_company_name" class="font-bold">
                                        {{ order.billing_company_name }}
                                    </div>
                                    <div>
                                        {{ order.billing_firstname }}
                                        {{ order.billing_lastname }}
                                    </div>
                                </div>
                                <div class="text-sm text-gray-500">
                                    <div>
                                        {{ order.billing_address }}
                                    </div>

                                    <div>
                                        {{ order.billing_postcode }}
                                        {{ order.billing_city }}
                                        {{ order.billing_country }}
                                    </div>
                                </div>
                                <div v-if="order.email" class="mt-2 text-sm font-medium text-gray-900">
                                    {{ order.email }}
                                </div>
                                <div v-if="order.billing_address.phone" class="text-sm text-gray-500">
                                    {{ order.billing_address.phone }}
                                </div>
                            </dd>
                        </div>

                        <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                            <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.delivery_address') }}
                            </dt>
                            <dd class="mt-1 sm:mt-0">
                                <div class="text-sm font-medium text-gray-900">
                                    <div v-if="order.shipping_company_name" class="font-bold">
                                        {{ order.shipping_company_name }}
                                    </div>
                                    <div>
                                        {{ order.shipping_firstname }}
                                        {{ order.shipping_lastname }}
                                    </div>
                                </div>
                                <div class="text-sm text-gray-500">
                                    <div>
                                        {{ order.shipping_address }}
                                    </div>

                                    <div>
                                        {{ order.shipping_postcode }}
                                        {{ order.shipping_city }}
                                        {{ order.shipping_country }}
                                    </div>
                                </div>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <hr class="col-span-6" />

            <div class="col-span-6">
                <p class="text-sm">{{ $t('credit-payment.max_refund_amount', { amount: formatPrice(order.total / 100) })
                    }}</p>
                <Label for="subtotal" v-bind:value="$t('credit-payment.amount_inc_vat')" />
                <div class="flex">
                    <MoneyInput id="remaining_order_subtotal" v-model="modelValue.amount" :min="0.01"
                        :max="order.total / 100" class="mt-1 block flex-1" :include-tax="true" />
                </div>
                <ValidationError name="amount" />
            </div>

            <div class="col-span-6">
                <Label for="reason_id" v-bind:value="$t('credit-payment.reason')" />

                <select id="reason_id" v-model="modelValue.reason_id" class="mt-1 block w-full rounded mb-4" required>
                    <option value="" disabled>{{ $t('credit-payment.select_reason') }}</option>
                    <option v-for="item in reasons" :value="item.id" :token="item.name">
                        {{ $t('credit-payment.' + item.name) }}
                    </option>
                </select>

                <ValidationError name="reason_id" />

                <template v-if="modelValue.reason_id === otherReasonId" class="mb-4">
                    <Label for="reason" v-bind:value="$t('credit-payment.reason_other_placeholder')" />

                    <Textarea id="reason" v-model="modelValue.reason" class="mt-1 block w-full" />

                    <ValidationError name="reason" />
                </template>
            </div>

            <hr class="col-span-6" />

            <div class="col-span-6 space-x-2 text-right">
                <NavButton :href="route('orders.show', { order: order.id })">{{ $t('Back') }}</NavButton>
                <Button type="submit" :disabled="modelValue.processing">{{ $t('Save') }}</Button>
            </div>
        </div>
    </form>
</template>

<script setup>
import Button from '@/Components/Button.vue';
import Label from '@/Components/Label.vue';
import MoneyInput from '@/Components/MoneyInput.vue';
import NavButton from '@/Components/NavButton.vue';
import Textarea from '@/Components/Textarea.vue';
import ValidationError from '@/Components/ValidationError.vue';
import { watch } from 'vue';
import { trans } from 'laravel-vue-i18n';

const props = defineProps({
    modelValue: Object,
    order: Object,
    reasons: Object,
    otherReasonId: Number
});

const emit = defineEmits(['submit']);

watch(() => props.modelValue.reason_id,
    (newValue) => {
        if (newValue !== trans('credit-payment.reason_other')) { // Assuming 'other' is a special value that requires 'reason_other'
            props.modelValue.reason = '';
        }
    });
</script>