<template>
    <ReportLayout :name="$t('reports.profit_sharing')" :type="type" :from="from" :to="to" :period="period">
        <div class="mb-6">
            <div class="sm:hidden">
                <label for="tabs" class="sr-only">Select a tab</label>
                <select id="tabs" v-model="currentTab" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                    <option v-for="tab in tabs" :key="tab.name" :selected="tab.action === currentTab">
                        {{ tab.name }}
                    </option>
                </select>
            </div>
            <div class="hidden sm:block">
                <nav class="relative z-0 flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
                    <button v-for="(tab, tabIdx) in tabs" :key="tab.name" :class="[tab.action === currentTab ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', tabIdx === 0 ? 'rounded-l-lg' : '', tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10']" :aria-current="tab.current ? 'page' : undefined" @click="currentTab = tab.action">
                        <span>{{ tab.name }}</span>
                        <span aria-hidden="true" :class="[tab.action === currentTab ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
                    </button>
                </nav>
            </div>
        </div>

        <div v-if="currentTab === 'general'" class="flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8"  >
                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{{ $t('reports.type') }}</th>
                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">{{ $t('reports.costs') }}</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.total_incoming_inc_vat') }}</td>
                                    <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.general_data['total']) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.vat') }}</td>
                                    <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.general_data['tax']) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.total_incoming_ex_vat') }}</td>
                                    <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.general_data['subtotal']) }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.total_costs') }}</td>
                                    <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.general_data['total_costs']) }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-16 pr-3 text-sm text-gray-500">{{ $t('reports.purchasing_costs') }}</td>
                                    <td class="whitespace-nowrap py-2 pl-2 pr-16 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.general_data['purchasing_costs']) }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-16 pr-3 text-sm text-gray-500">{{ $t('reports.shipping_costs') }}</td>
                                    <td class="whitespace-nowrap py-2 pl-2 pr-16 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.general_data['shipping_costs']) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-16 pr-3 text-sm text-gray-500">{{ $t('reports.payment_costs') }}</td>
                                    <td class="whitespace-nowrap py-2 pl-2 pr-16 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.general_data['payment_costs']) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-16 pr-3 text-sm text-gray-500">{{ $t('reports.extra_costs') }}</td>
                                    <td class="whitespace-nowrap py-2 pl-2 pr-16 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.general_data['extra_costs']) }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.profit_before_sharing') }}</td>
                                    <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.general_data['revenue']) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.profit_share_to_orders') }}</td>
                                    <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.general_data['profit_share_to_orders']) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.profit_share_to_items') }}</td>
                                    <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.general_data['profit_share_to_items']) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.revenue_lost_to_shares') }}</td>
                                    <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.general_data['revenue_lost_to_shares']) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.final_revenue')}}</td>
                                    <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.general_data['final_revenue']) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="currentTab === 'order_shares'" class="flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{{ $t('reports.type') }}</th>
                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">{{ $t('reports.costs') }}</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.profit_before_sharing') }}</td>
                                    <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.general_data['revenue']) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.profit_share_to_orders_alt') }}</td>
                                    <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.general_data['profit_share_to_orders']) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.final_revenue') }}</td>
                                    <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.general_data['revenue_after_order_profit_shares']) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="currentTab === 'order_shares'" class="mt-2 flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{{ $t('reports.name') }}</th>
                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">{{ $t('reports.email') }}</th>
                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">{{ $t('reports.profit') }}</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="item in reportData.percentage_shares" :key="item.date">
                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                        {{ item.name }}
                                    </td>
                                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                        {{ item.email }}
                                    </td>
                                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                        {{ formatPrice(item.revenue) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="currentTab === 'product_shares'" class="flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{{ $t('reports.name') }}</th>
                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">{{ $t('reports.costs') }}</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.profit_before_sharing') }}</td>
                                    <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.total_fixed_shares['revenue_before_shares']) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.profit_share_to_orders_alt') }}</td>
                                    <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.total_fixed_shares['revenue_lost_to_shares']) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.final_revenue') }}</td>
                                    <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">
                                        {{ formatPrice(reportData.total_fixed_shares['revenue_after_shares']) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="currentTab === 'product_shares'" class="mt-2 flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{{ $t('reports.name') }}</th>
                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">{{ $t('reports.email') }}</th>
                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">{{ $t('reports.profit') }}</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="item in reportData.total_fixed_shares.shares" :key="item.date">
                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                        {{ item.name }}
                                    </td>
                                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                        {{ item.email }}
                                    </td>
                                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                        {{ formatPrice(item.revenue) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="currentTab === 'products'" class="col-span-3">
            <Label for="productQuery" value="Zoek een product" />
            <input id="productQuery" type="text" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" @input="productQuery = $event.target.value" />
        </div>

        <div v-if="currentTab === 'products'">
            <div v-for="product in filteredFixedShares" :key="product['product_id']">
                <h1 class="mt-6 text-base font-bold">Product - {{ product['product_name'] }} (ID: {{ product['product_id'] }} )</h1>

                <div class="mt-1 flex flex-col">
                    <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table class="min-w-full divide-y divide-gray-300">
                                    <thead class="bg-gray-50">
                                        <tr>
                                            <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{{ $t('reports.type') }}</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">{{ $t('reports.costs') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="divide-y divide-gray-200 bg-white">
                                        <tr>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.sold') }}</td>
                                            <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">{{ product['sales'] }} {{ $t('reports.pieces') }}</td>
                                        </tr>
                                        <tr>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.profit_before_sharing') }}</td>
                                            <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">
                                                {{ formatPrice(product['revenue_before_shares']) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.profit_share_to_orders_alt') }}</td>
                                            <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">
                                                {{ formatPrice(product['revenue_lost_to_shares']) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ $t('reports.final_revenue') }}</td>
                                            <td class="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-900">
                                                {{ formatPrice(product['revenue_after_shares']) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-2 flex flex-col">
                    <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table class="min-w-full divide-y divide-gray-300">
                                    <thead class="bg-gray-50">
                                        <tr>
                                            <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{{ $t('reports.name') }}</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">{{ $t('reports.email') }}</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">{{ $t('reports.profit') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="divide-y divide-gray-200 bg-white">
                                        <tr v-for="item in product.shares" :key="item.date">
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                {{ item.name }}
                                            </td>
                                            <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                                {{ item.email }}
                                            </td>
                                            <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                                {{ formatPrice(item.revenue) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ReportLayout>
</template>

<script>
import ReportLayout from './Layout.vue'
import Label from '@/Components/Label.vue'

export default {
    components: {
        ReportLayout,
        Label
    },
    props: {
        type: String,
        from: String,
        to: String,
        period: String,
        reportData: Object
    },
    data() {
        return {
            tabs: [
                { name: this.$t('reports.standard'), action: 'general' },
                { name: this.$t('reports.profit_share_percentage'), action: 'order_shares' },
                { name: this.$t('reports.profit_share_products'), action: 'product_shares' },
                { name: this.$t('reports.products'), action: 'products' }
            ],
            currentTab: 'general',
            productQuery: ''
        }
    },
    computed: {
        filteredFixedShares() {
            return this.productQuery === ''
                ? this.reportData.fixed_shares
                : this.reportData.fixed_shares.filter((product) => {
                    return product['product_name'].toLowerCase().includes(this.productQuery.toLowerCase())
                })
        }
    }
}
</script>
