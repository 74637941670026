<template>
    <Head title="API" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex flex-wrap items-center justify-between">
                <h1 class="text-2xl font-semibold text-gray-900">{{ $t('api.api_authenticate') }}</h1>
                <div class="flex items-center space-x-1">
                    <Input id="token_name" v-model="createForm.token_name" type="text" class="py-1" required />

                    <!-- Todo: not working -->
                    <!-- <NavButton v-if="can('create api auth')" @click="createToken">+</NavButton> -->
                </div>
            </div>
        </template>

        <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Naam</th>
                                    <th scope="col" class="relative px-6 py-3">
                                        <span class="sr-only">{{ $t('api.delete') }}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="token in tokens" :key="token.id">
                                    <td class="whitespace-nowrap px-6 py-4">
                                        <div class="">
                                            {{ token.name }}
                                        </div>
                                        <div class="text-sm text-gray-500">
                                            {{ $t('api.last_used') }}

                                            <span v-if="token.last_used_at">
                                                {{ dayjs(token.last_used_at).format('lll') }}
                                            </span>
                                            <span v-else>{{ $t('api.not_yet') }}</span>
                                        </div>
                                    </td>

                                    <td class="space-x-4 whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
                                        <button v-if="can('delete api auth')" class="text-indigo-600 hover:text-indigo-900" @click="confirmingTokenDeletion = token">{{ $t('api.delete') }}</button>
                                    </td>
                                </tr>
                                <tr v-if="tokens.length == 0">
                                    <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900" colspan="3">{{ $t('api.no_results_found') }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <ConfirmationModal v-if="can('delete api auth')" :show="confirmingTokenDeletion != null" @close="confirmingTokenDeletion = null">
            <template #title>
                {{ $t('api.delete_api_token') }}
                <span v-if="confirmingTokenDeletion != null">- {{ confirmingTokenDeletion.name }}</span>
            </template>

            <template #content>{{ $t('api.warning_confirmation') }}</template>

            <template #footer>
                <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="deleteToken">{{ $t('api.delete_api_token') }}</button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="confirmingTokenDeletion = null">{{ $t('api.cancel') }}</button>
            </template>
        </ConfirmationModal>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import NavButton from '@/Components/NavButton.vue'
import ConfirmationModal from '@/Components/ConfirmationModal.vue'
import Input from '@/Components/Input.vue'

export default {
    components: {
        Input,
        ConfirmationModal,
        NavButton,
        AuthenticatedLayout,
        Head
    },
    props: {
        tokens: Array
    },
    data() {
        return {
            confirmingTokenDeletion: null,
            deleteForm: this.$inertia.form({}),
            createForm: this.$inertia.form({
                token_name: ''
            })
        }
    },
    methods: {
        createToken() {
            this.createForm.post(route('api-auth.store'), {
                preserveScroll: true
            })
        },
        deleteToken() {
            this.deleteForm.delete(
                route('api-auth.destroy', {
                    token: this.confirmingTokenDeletion.id
                })
            )
            this.confirmingTokenDeletion = null
        }
    }
}
</script>
