<template>
    <Head :title="$t('retour.all_retours')" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex items-center justify-between">
                <h1 class="text-2xl font-semibold text-gray-900">
                    {{ indexTitle }}
                </h1>
                <div>
                    <div class="flex rounded-md shadow-sm">
                        <div class="relative flex flex-grow items-stretch focus-within:z-10">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </div>
                            <input id="search" v-model="searchQuery" type="text" name="search" class="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="" />
                        </div>
                        <button type="button" class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500" @click="search">{{$t('retour.search') }}</button>
                    </div>
                </div>
            </div>
        </template>

        <pagination class="mb-2" :links="retours" />

        <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-4 lg:px-8">
                    <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('retour.id') }}</th>
                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('retour.retour_date') }}</th>
                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('retour.original_order') }}</th>
                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('retour.payback') }}</th>
                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('retour.status') }}</th>
                                    <th scope="col" class="relative px-4 py-2">
                                        <span class="sr-only">{{ $t('retour.view') }}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="retour in retours.data" :key="retour.id">
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">{{ $t('retour.retour') }} {{ retour.id }}</td>
                                    <td class="whitespace-nowrap px-4 py-2">
                                        <div class="text-xs font-medium text-gray-900">
                                            {{ dayjs(retour.created_at).format('LL') }}
                                        </div>
                                        <div class="text-xs text-gray-500">
                                            {{ dayjs(retour.created_at).format('LT') }}
                                        </div>
                                    </td>

                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        <Link
                                            v-if="can('view order')"
                                            :href="
                                                route('orders.show', {
                                                    order: retour.order.id
                                                })
                                            "
                                            class="text-indigo-600 hover:text-indigo-900"
                                        >
                                            {{ $t('retour.order') }}
                                            {{ retour.order.extern_id }}
                                        </Link>
                                        <span v-else class="text-indigo-600 hover:text-indigo-900">
                                            {{ $t('retour.order') }}
                                            {{ retour.order.extern_id }}
                                        </span>
                                    </td>

                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        {{ formatPrice(retour.pay_to_customer) }}
                                    </td>

                                    <td class="whitespace-nowrap px-4 py-2">
                                        <status-badge :status="retour.status"></status-badge>
                                    </td>
                                    <td class="whitespace-nowrap px-4 py-2 text-right text-xs font-medium">
                                        <Link
                                            v-if="can('view retour')"
                                            :href="
                                                route('retours.show', {
                                                    retour: retour.id
                                                })
                                            "
                                            class="text-indigo-600 hover:text-indigo-900"
                                        >
                                            {{ $t('retour.details') }}
                                        </Link>
                                    </td>
                                </tr>
                                <tr v-if="retours.data.length == 0">
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900" colspan="7">{{ $t('retour.no_results_found') }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <pagination class="mt-2" :links="retours" />
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head, Link } from '@inertiajs/vue3'
import Pagination from '@/Components/Pagination.vue'
import StatusBadge from '@/Components/StatusBadge.vue'

export default {
    components: {
        StatusBadge,
        Pagination,
        AuthenticatedLayout,
        Head,
        Link
    },
    props: {
        retours: Object,
        indexTitle: String,
        oldSearchQuery: String,
        currentType: String
    },
    data() {
        return {
            searchQuery: this.oldSearchQuery
        }
    },

    methods: {
        search() {
            this.$inertia.get(
                route(route().current()),
                { query: this.searchQuery, type: this.currentType },
                {
                    preserveState: true
                }
            )
        }
    }
}
</script>
