<template>
    <TransitionRoot as="template" :show="order != false">
        <Dialog as="div" class="fixed inset-0 z-10 overflow-y-auto" @close="$emit('close')">
            <div class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle">
                        <div>
                            <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-indigo-100">
                                <AdjustmentsVerticalIcon class="h-6 w-6 text-indigo-600" aria-hidden="true" />
                            </div>
                            <div class="mt-3 sm:mt-5">
                                <DialogTitle as="h3" class="text-center text-lg font-medium leading-6 text-gray-900">{{ $t('orders.change_status') }} {{ order.extern_id }}</DialogTitle>
                                <div class="mt-4 space-y-4">
                                    <div>
                                        <Label for="status" value="Status" />
                                        <Select id="status" v-model="form.status" class="mt-1 block w-full">
                                            <option value="completed" :selected="form.status == 'completed'">{{ $t('status.completed') }}</option>
                                            <option value="pending" :selected="form.status == 'pending'">{{ $t('status.pending') }}</option>
                                            <option value="processing" :selected="form.status == 'processing'">{{ $t('status.processing') }}</option>
                                            <option value="cancelled" :selected="form.status == 'cancelled'">{{ $t('status.cancelled') }}</option>
                                            <option value="for inspection" :selected="form.status == 'for inspection'">{{ $t('status.for inspection') }}</option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 sm:mt-6">
                            <Button class="w-full justify-center" @click="updateStatus" :disabled="form.processing">{{ $t('status.save') }}</Button>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { AdjustmentsVerticalIcon } from '@heroicons/vue/24/outline'
import Button from '@/Components/Button.vue'
import Label from '@/Components/Label.vue'
import Select from '@/Components/Select.vue'

export default {
    components: {
        Select,
        Label,
        Button,
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        AdjustmentsVerticalIcon
    },
    props: {
        order: Object
    },
    emits: ['close'],
    data() {
        return {
            form: this.$inertia.form({
                status: null
            })
        }
    },
    updated() {
        this.form.status = this.order ? this.order.status : null
    },
    methods: {
        updateStatus() {
            this.form.put(this.route('orders.status', { order: this.order.id }), {
                preserveScroll: true,
                onSuccess: this.$emit('close')
            })
        }
    }
}
</script>
