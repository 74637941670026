<template>
    <Head v-bind:title="$t('auth.2fa_confirmation')" />

    <div class="mb-4 text-sm text-gray-600">
        <template v-if="!recovery">{{ $t('auth.notice_2fa') }}</template>

        <template v-else>{{ $t('auth.notice_emergency_code')}}</template>
    </div>

    <ValidationErrors class="mb-4" />

    <form @submit.prevent="submit">
        <div v-if="!recovery">
            <Label for="code" value="Code" />
            <Input id="code" ref="code" v-model="form.code" type="text" inputmode="numeric" class="mt-1 block w-full" autofocus autocomplete="one-time-code" />
        </div>

        <div v-else>
            <Label for="recovery_code" v-bind:value="$t('auth.recover_code')" />
            <Input id="recovery_code" ref="recovery_code" v-model="form.recovery_code" type="text" class="mt-1 block w-full" autocomplete="one-time-code" />
        </div>

        <div class="mt-4 flex items-center justify-end">
            <button type="button" class="text-sm text-gray-600 underline hover:text-gray-900" @click.prevent="toggleRecovery">
                <template v-if="!recovery">{{  $t('auth.use_recover_code') }}</template>

                <template v-else>{{ $t('auth.use_emergency_code') }}</template>
            </button>

            <Button class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">{{$t('auth.login') }}</Button>
        </div>
    </form>
</template>

<script>
import Button from '@/Components/Button.vue'
import GuestLayout from '@/Layouts/Guest.vue'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import ValidationErrors from '@/Components/ValidationErrors.vue'
import { Head } from '@inertiajs/vue3'

export default {
    components: {
        Button,
        Input,
        Label,
        ValidationErrors,
        Head
    },
    layout: GuestLayout,

    data() {
        return {
            recovery: false,
            form: this.$inertia.form({
                code: '',
                recovery_code: ''
            })
        }
    },

    methods: {
        toggleRecovery() {
            this.recovery ^= true
            this.$nextTick(() => {
                if (this.recovery) {
                    this.$refs.recovery_code.focus()
                    this.form.code = ''
                } else {
                    this.$refs.code.focus()
                    this.form.recovery_code = ''
                }
            })
        },
        submit() {
            this.form.post(this.route('two-factor.login'))
        }
    }
}
</script>
