<template>
    <TransitionRoot as="template" :show="order != false">
        <Dialog as="div" class="fixed inset-0 z-10 overflow-y-auto" @close="$emit('close')">
            <div class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6 sm:align-middle">
                        <div>
                            <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-indigo-100">
                                <TruckIcon class="h-6 w-6 text-indigo-600" aria-hidden="true" />
                            </div>
                            <div class="mt-3 sm:mt-5">
                                <DialogTitle as="h3" class="text-center text-lg font-medium leading-6 text-gray-900">
                                    {{ $t('orders.generate_shipment') }}
                                    {{ order.extern_id }}
                                </DialogTitle>
                                <div class="mt-4 grid grid-cols-12 gap-4">
                                    <div class="col-span-6">
                                        <Label for="firstname" :value="$t('orders.first_name') + '*'" />
                                        <Input id="firstname" v-model="form.firstname" type="text" class="mt-1 block w-full" required />
                                        <ValidationError name="firstname" />
                                    </div>
                                    <div class="col-span-6">
                                        <Label for="lastname" :value="$t('orders.last_name') + '*'" />
                                        <Input id="lastname" v-model="form.lastname" type="text" class="mt-1 block w-full" required />
                                        <ValidationError name="lastname" />
                                    </div>
                                    <div class="col-span-6">
                                        <Label for="email" :value="$t('orders.email') + '*'" />
                                        <Input id="email" v-model="form.email" type="text" class="mt-1 block w-full" required />
                                        <ValidationError name="email" />
                                    </div>
                                    <div class="col-span-6">
                                        <Label for="phone" :value="$t('orders.tel') + '*'" />
                                        <Input id="phone" v-model="form.phone" type="text" class="mt-1 block w-full" required />
                                        <ValidationError name="phone" />
                                    </div>
                                    <div class="col-span-7">
                                        <Label for="street_name" :value="$t('orders.street') + '*'" />
                                        <Input id="street_name" v-model="form.street_name" type="text" class="mt-1 block w-full" required />
                                        <ValidationError name="street_name" />
                                    </div>
                                    <div class="col-span-3">
                                        <Label for="house_number" :value="$t('orders.house_no') + '*'" />
                                        <Input id="house_number" v-model="form.house_number" type="text" class="mt-1 block w-full" required />
                                        <ValidationError name="house_number" />
                                    </div>
                                    <div class="col-span-2">
                                        <Label for="house_number_ext" :value="$t('orders.suffix') + '*'" />
                                        <Input id="house_number_ext" v-model="form.house_number_ext" type="text" class="mt-1 block w-full" />
                                        <ValidationError name="house_number_ext" />
                                    </div>
                                    <div class="col-span-4">
                                        <Label for="postcode" :value="$t('orders.postal') + '*'" />
                                        <Input id="postcode" v-model="form.postcode" type="text" class="mt-1 block w-full" required />
                                        <ValidationError name="postcode" />
                                    </div>

                                    <div class="col-span-4">
                                        <Label for="city" :value="$t('orders.city') + '*'" />
                                        <Input id="city" v-model="form.city" type="text" class="mt-1 block w-full" required />
                                        <ValidationError name="city" />
                                    </div>

                                    <div class="col-span-4">
                                        <Label for="country" :value="$t('orders.country') + '*'" />
                                        <Input id="country" v-model="form.country" type="text" class="mt-1 block w-full" required />
                                        <ValidationError name="country" />
                                    </div>

                                    <div class="col-span-6">
                                        <Label for="weight" :value="$t('orders.weight') + '*'" />
                                        <Input id="weight" v-model="form.weight" type="number" class="mt-1 block w-full" required />
                                        <ValidationError name="weight" />
                                    </div>

                                    <div class="col-span-6">
                                        <Label for="volume" :value="$t('orders.volume') + '*'" />
                                        <Input id="volume" v-model="form.volume" type="number" class="mt-1 block w-full" />
                                        <ValidationError name="volume" />
                                    </div>

                                    <div class="col-span-4">
                                        <Label for="length" :value="$t('orders.length') + '*'" />
                                        <Input id="length" v-model="form.length" type="number" class="mt-1 block w-full" />
                                        <ValidationError name="length" />
                                    </div>
                                    <div class="col-span-4">
                                        <Label for="height" :value="$t('orders.height') + '*'" />
                                        <Input id="height" v-model="form.height" type="number" class="mt-1 block w-full" />
                                        <ValidationError name="height" />
                                    </div>

                                    <div class="col-span-4">
                                        <Label for="width" :value="$t('orders.width') + '*'" />
                                        <Input id="width" v-model="form.width" type="number" class="mt-1 block w-full" />
                                        <ValidationError name="width" />
                                    </div>
                                    <div class="col-span-12">
                                        <Label for="type" value="Soort zending" />
                                        <Select id="type" v-model="form.type" class="mt-1 block w-full">
                                            <option value="postnl_package">{{ $t('orders.postnl_package') }}</option>
                                            <option value="postnl_mailbox">{{ $t('orders.postnl_mailbox') }}</option>
                                            <option value="trunkrs_sameday">{{ $t('orders.trunkrs_same_day') }}</option>
                                        </Select>
                                        <ValidationError name="type" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 sm:mt-6">
                            <Button class="w-full justify-center" @click="createShipment">{{ $t('orders.generate_shipment') }}</Button>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { TruckIcon } from '@heroicons/vue/24/outline'
import Button from '@/Components/Button.vue'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import Select from '@/Components/Select.vue'
import ValidationError from '@/Components/ValidationError.vue'

export default {
    components: {
        ValidationError,
        Select,
        Label,
        Input,
        Button,
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        TruckIcon
    },
    props: {
        order: Object
    },
    emits: ['close'],
    data() {
        return {
            form: this.$inertia.form({
                firstname: null,
                lastname: null,
                email: null,
                phone: null,
                street_name: null,
                house_number: null,
                house_number_ext: null,
                postcode: null,
                city: null,
                country: null,

                weight: null,
                height: null,
                volume: null,
                width: null,

                type: 'postnl_package'
            })
        }
    },
    updated() {
        this.form.firstname = this.order ? this.order.shipping_firstname : null
        this.form.lastname = this.order ? this.order.shipping_lastname : null
        this.form.email = this.order ? this.order.email : null
        this.form.phone = this.order ? this.order.phone : null
        this.form.street_name = this.order ? this.order.splitted_shipping_address.street_name : null
        this.form.house_number = this.order ? this.order.splitted_shipping_address.house_number : null
        this.form.house_number_ext = this.order ? this.order.splitted_shipping_address.house_number_ext : null
        this.form.postcode = this.order ? this.order.shipping_postcode : null
        this.form.city = this.order ? this.order.shipping_city : null
        this.form.country = this.order ? this.order.shipping_country : null
    },
    methods: {
        createShipment() {
            this.form.post(this.route('shipment.create', { order: this.order.id }), {
                preserveScroll: true,
                onSuccess: this.$emit('close')
            })
        }
    }
}
</script>
