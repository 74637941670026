<template>
    <Head title="Tickets" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex flex-wrap justify-between">
                <div>
                    <h1 class="text-2xl font-semibold text-gray-900">Tickets</h1>
                    <!--                    <p class="max-w-2xl text-sm">-->
                    <!--                        Op deze pagina kun je systeem rollen aanmaken, bewerken en verwijderen. Een systeem rol is een rol met permissies die over het gehele syteem gelden. Dit is bijvoorbeeld toestemming voor het aanmaken van gebruikers en winkels.-->
                    <!--                        <br />-->
                    <!--                        <br />-->
                    <!--                        Winkel rollen kun je aanmaken bij het inzien van de desbetreffende winkel. Winkel rollen geven toegang om bijvoorbeeld bestellingen en retours te kunnen inzien.-->
                    <!--                    </p>-->
                </div>
                <div>
                    <NavButton v-if="can('create ticket')" :href="route('tickets.create')">Ticket aanmaken</NavButton>
                </div>
            </div>
        </template>

        <div class="h-full w-full flex antialiased text-gray-800">
            <!-- section body side nav -->
            <div class="flex-1 flex flex-col">
                <!-- main content -->
                <main class="flex-grow flex min-h-0">
                    <!-- section update to tickets -->
                    <!-- section content -->
                    <section aria-label="main content" class="flex min-h-0 flex-col flex-auto">
                        <div class="flex items-start space-x-4">
                            <div class="flex flex-col">
                                <label for="department-select" class="text-gray-700 font-medium mb-1">Afdeling</label>
                                <select id="department-select" v-model="selectedDepartmentId" class="w-auto bg-white border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-base font-medium text-gray-700" @change="handleFilterChange()">
                                    <option value="">Alles</option>
                                    <template v-for="department in departments" :key="department.id">
                                        <option v-if="can(`view ${department.name} tickets`) || department.id == $page.props.auth.user.department.id" :value="department.id">{{ department.name }}</option>
                                    </template>
                                </select>
                            </div>

                            <div class="flex flex-col">
                                <label for="status-select" class="text-gray-700 font-medium mb-1">Status</label>
                                <select id="status-select" v-model="selectedStatus" class="w-auto bg-white border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-base font-medium text-gray-700" @change="handleFilterChange()">
                                    <option value="open">Open</option>
                                    <option value="closed">Gesloten</option>
                                </select>
                            </div>
                        </div>

                        <div class="mt-8 flow-root">
                            <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <table class="min-w-full border">
                                        <thead class="bg-white">
                                        <tr>
                                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">id</th>
                                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Onderwerp</th>
                                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Categorie</th>
                                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Aangemaakt door</th>
                                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Aangemaakt op</th>
                                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Voor/Afdeling</th>
                                            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                                <span class="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody class="bg-white">

                                        <tr class="border-t border-gray-200">
                                            <th colspan="7" scope="colgroup" class="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"><span class="font-normal mr-1">Prioriteit</span> Hoog</th>
                                        </tr>
                                        <tr v-if="highPriorityTickets.data.length == 0">
                                            <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900" colspan="7">Geen tickets gevonden</td>
                                        </tr>

                                        <tr v-for="ticket in highPriorityTickets.data" :key="ticket.id" role="row" class="border-t border-gray-300">
                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ticket.id }}</td>
                                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 w-36">
                                                <div class="group relative">
                                                    {{ ticket.title.substring(0, 50) }}..
                                                    <span class="hidden group-hover:block ml-4 mt-10 w-auto absolute top-0 border shadow-lg p-6 bg-white rounded-md z-50 text-gray-900">
                                                        <article>
                                                            <header>
                                                                <div>
                                                                    <span class="px-3 py-1 uppercase text-xs leading-none rounded-sm bg-red-600 text-white">{{ ticket.status }}</span>
                                                                    <span class="mx-2 text-gray-700">#{{ ticket.id }}</span>
                                                                    <TicketStatusBadge :status="ticket.priority"></TicketStatusBadge>
                                                                </div>
                                                            </header>
                                                            <section class="mt-5">
                                                                <h1 class="text-sm font-semibold mt-3">{{ ticket.title }}</h1>
                                                                <div class="mt-3" v-html="ticket.message"></div>
                                                            </section>
                                                        </article>
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {{ ticket.categories[0]?.name }}
                                            </td>
                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {{ ticket.user.name }}
                                            </td>
                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {{ dayjs(ticket.updated_at).format('LL HH:mm') }}
                                            </td>
                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <span v-if="ticket.assigned_to_user">
                                                    {{ ticket.assigned_to_user.name }}
                                                </span>
                                                <span v-if="ticket.assigned_to_user && ticket.department"> / </span>
                                                <span v-if="ticket.department">
                                                    {{ ticket.department.name }}
                                                </span>
                                            </td>
                                            <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                                <a :href="route('tickets.show', { ticket: ticket.id })" target="_BLANK" class="text-indigo-600 hover:text-indigo-900">Bekijken</a>
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th colspan="7" scope="colgroup" class="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"><span class="font-normal mr-1">Prioriteit</span> Gemiddeld</th>
                                        </tr>
                                        <tr v-if="mediumPriorityTickets.data.length == 0">
                                            <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900" colspan="7">Geen tickets gevonden</td>
                                        </tr>
                                        <tr v-for="ticket in mediumPriorityTickets.data" :key="ticket.id" role="row" class="border-t border-gray-300">
                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ticket.id }}</td>
                                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 w-36">
                                                <div class="group relative">
                                                    {{ ticket.title.substring(0, 50) }}..
                                                    <span class="hidden group-hover:block ml-4 mt-10 w-auto absolute top-0 border shadow-lg p-6 bg-white rounded-md z-50 text-gray-900">
                                                        <article>
                                                            <header>
                                                                <div>
                                                                     <TicketStatusBadge :status="ticket.status"></TicketStatusBadge>
                                                                    <span class="mx-2 text-gray-700">#{{ ticket.id }}</span>
                                                                    <TicketStatusBadge :status="ticket.priority"></TicketStatusBadge>
                                                                </div>
                                                            </header>
                                                            <section class="mt-5">
                                                                <h1 class="text-sm font-semibold mt-3">{{ ticket.title }}</h1>
                                                                <div class="mt-3" v-html="ticket.message"></div>
                                                            </section>
                                                        </article>
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {{ ticket.categories[0].name }}
                                            </td>
                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {{ ticket.user.name }}
                                            </td>
                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {{ dayjs(ticket.updated_at).format('LL HH:mm') }}
                                            </td>
                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <span v-if="ticket.assigned_to_user">
                                                    {{ ticket.assigned_to_user.name }}
                                                </span>
                                                <span v-if="ticket.assigned_to_user && ticket.department"> / </span>
                                                <span v-if="ticket.department">
                                                    {{ ticket.department.name }}
                                                </span>
                                            </td>
                                            <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                                <a :href="route('tickets.show', { ticket: ticket.id })" target="_BLANK" class="text-indigo-600 hover:text-indigo-900">Bekijken</a>
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th colspan="7" scope="colgroup" class="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"><span class="font-normal mr-1">Prioriteit</span> Laag</th>
                                        </tr>
                                        <tr v-if="lowPriorityTickets.data.length == 0">
                                            <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900" colspan="7">Geen tickets gevonden</td>
                                        </tr>
                                        <tr v-for="ticket in lowPriorityTickets.data" :key="ticket.id" role="row" class="border-t border-gray-300">
                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ticket.id }}</td>
                                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 w-36">
                                                <div class="group relative">
                                                    {{ ticket.title.substring(0, 50) }}..
                                                    <span class="hidden group-hover:block ml-4 mt-10 w-auto absolute top-0 border shadow-lg p-6 bg-white rounded-md z-50 text-gray-900">
                                                        <article>
                                                            <header>
                                                                <div>
                                                                    <TicketStatusBadge :status="ticket.status"></TicketStatusBadge>
                                                                    <span class="mx-2 text-gray-700">#{{ ticket.id }}</span>
                                                                    <TicketStatusBadge :status="ticket.priority"></TicketStatusBadge>
                                                                </div>
                                                            </header>
                                                            <section class="mt-5">
                                                                <h1 class="text-sm font-semibold mt-3">{{ ticket.title }}</h1>
                                                                <div class="mt-3" v-html="ticket.message"></div>
                                                            </section>
                                                        </article>
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {{ ticket.categories[0].name }}
                                            </td>
                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {{ ticket.user.name }}
                                            </td>
                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {{ dayjs(ticket.updated_at).format('LL HH:mm') }}
                                            </td>
                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <span v-if="ticket.assigned_to_user">
                                                    {{ ticket.assigned_to_user.name }}
                                                </span>
                                                <span v-if="ticket.assigned_to_user && ticket.department"> / </span>
                                                <span v-if="ticket.department">
                                                    {{ ticket.department.name }}
                                                </span>
                                            </td>
                                            <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                                <a :href="route('tickets.show', { ticket: ticket.id })" target="_BLANK" class="text-indigo-600 hover:text-indigo-900">Bekijken</a>
                                            </td>
                                        </tr>




                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import NavButton from '@/Components/NavButton.vue'
import TicketStatusBadge from "@/Components/TicketStatusBadge.vue"

export default {
    components: {
        TicketStatusBadge,
        NavButton,
        AuthenticatedLayout,
        Head,
    },
    props: {
        stores: Array,
        tickets: Object,
        departments: Object,

        filteredDepartmentId: Number,
        filteredStatus: String,

        lowPriorityTickets: Object,
        mediumPriorityTickets: Object,
        highPriorityTickets: Object,
    },
    data() {
        return {
            confirmingStoreDeletion: null,
            form: this.$inertia.form({}),

            selectedDepartmentId: this.filteredDepartmentId,
            selectedStatus: this.filteredStatus,
        }
    },
    methods: {
        handleFilterChange() {
            if(this.selectedDepartmentId) {
                this.$inertia.visit(`/tickets/?department_id=${this.selectedDepartmentId}&status=${this.selectedStatus}`, {preserveState: true})
            } else {
                this.$inertia.visit(`/tickets/?status=${this.selectedStatus}`, {preserveState: true})
            }
        },
    }
}
</script>
<style>
html {
    font-size: 14px;
    line-height: 1.285;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Arial,
    sans-serif;
}

/* can be configured in tailwind.config.js */
.group:hover .group-hover\:block {
    display: block;
}

.focus\:cursor-text:focus {
    cursor: text;
}

.focus\:w-64:focus {
    width: 16rem;
}

/* zendesk styles */
.h-16 {
    height: 50px;
}
.bg-teal-900 {
    background: #03363d;
}
.bg-gray-100 {
    background: #f8f9f9;
}
.hover\:border-green-500:hover {
    border-color: #78a300;
}
</style>
