<template>
    <Head title="Profiel" />

    <AuthenticatedLayout>
        <template #header>
            <h1 class="text-2xl font-semibold text-gray-900">Profiel wijzigen</h1>
        </template>

        <form method="POST" class="overflow-hidden bg-white p-6 shadow-sm sm:rounded-lg" @submit.prevent="updateProfile">
            <div class="grid grid-cols-6 gap-8">
                <div class="col-span-3">
                    <Label for="name" value="Naam" />
                    <Input id="name" v-model="updateForm.name" type="text" class="mt-1 block w-full" required />
                    <ValidationError name="name" />
                </div>
                <div class="col-span-3">
                    <Label for="email" value="E-mailadres" />
                    <Input id="email" v-model="updateForm.email" type="text" class="mt-1 block w-full" required />
                    <ValidationError name="email" />
                </div>
                <div class="col-span-6 space-x-2 text-right">
                    <Button type="submit">Opslaan</Button>
                </div>
            </div>
        </form>

        <h1 class="mt-6 mb-2 block text-2xl font-semibold text-gray-900">Wachtwoord wijzigen</h1>
        <form method="POST" class="overflow-hidden bg-white p-6 shadow-sm sm:rounded-lg" @submit.prevent="updatePassword">
            <div class="grid grid-cols-6 gap-8">
                <div class="col-span-4">
                    <Label for="current_password" value="Huidige wachtwoord" />
                    <Input id="current_password" v-model="passwordForm.current_password" type="password" class="mt-1 block w-full" required />
                    <ValidationError name="current_password" />
                </div>
                <div class="col-span-4">
                    <Label for="password" value="Wachtwoord" />
                    <Input id="password" v-model="passwordForm.password" type="password" class="mt-1 block w-full" required />
                    <ValidationError name="password" />
                </div>
                <div class="col-span-4">
                    <Label for="password_confirmation" value="Bevestig Wachtwoord" />
                    <Input id="password_confirmation" v-model="passwordForm.password_confirmation" type="password" class="mt-1 block w-full" required />
                    <ValidationError name="password_confirmation" />
                </div>
                <div class="col-span-6 space-x-2 text-right">
                    <Button type="submit">Opslaan</Button>
                </div>
            </div>
        </form>

        <h1 class="mt-6 mb-2 block text-2xl font-semibold text-gray-900">
            Twee-factor-authenticatie -
            <span v-if="twoFactorEnabled" class="text-green-500">Ingeschakeld</span>
            <span v-else class="text-red-500">Uitgeschakeld</span>
        </h1>
        <div class="overflow-hidden bg-white p-6 shadow-sm sm:rounded-lg">
            <div class="max-w-xl text-sm text-gray-600">
                <p>Als twee-factor-authenticatie is ingeschakeld, wordt je tijdens de authenticatie om een veilige, willekeurige token gevraagd. Je kunt dit token ophalen uit de Google Authenticator-applicatie op je telefoon.</p>
            </div>

            <div v-if="twoFactorEnabled">
                <div v-if="qrCode">
                    <div class="mt-4 max-w-xl text-sm text-gray-600">
                        <p class="font-semibold">Twee-factor-authenticatie is nu ingeschakeld. Scan de volgende QR-code met de authenticatie applicatie op je telefoon.</p>
                    </div>

                    <div class="mt-4" v-html="qrCode"></div>
                </div>

                <div v-if="recoveryCodes.length > 0">
                    <div class="mt-4 max-w-xl text-sm text-gray-600">
                        <p class="font-semibold">Bewaar deze herstelcodes in een beveiligde wachtwoordbeheerder. Ze kunnen worden gebruikt om de toegang tot je account te herstellen als je twee-factor-authenticatieapparaat verloren is gegaan.</p>
                    </div>

                    <div class="mt-4 grid max-w-xl gap-1 rounded-lg bg-gray-100 px-4 py-4 font-mono text-sm">
                        <div v-for="code in recoveryCodes" :key="code">
                            {{ code }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-full space-x-2 text-right">
                <Button v-if="!twoFactorEnabled" type="button" :class="{ 'opacity-25': enabling }" :disabled="enabling" @click="enableTwoFactorAuthentication">Inschakelen</Button>
                <Button v-if="twoFactorEnabled" type="button" @click="regenerateRecoveryCodes">Herstelcodes Opnieuw Genereren</Button>
                <Button v-if="twoFactorEnabled" type="button" @click="showRecoveryCodes">Toon herstelcodes</Button>
                <Button v-if="twoFactorEnabled" type="button" :class="{ 'opacity-25': disabling }" :disabled="disabling" @click="disableTwoFactorAuthentication">Uitschakelen</Button>
            </div>
        </div>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import Button from '@/Components/Button.vue'
import ValidationError from '@/Components/ValidationError.vue'

export default {
    components: {
        Button,
        ValidationError,
        Input,
        Label,
        AuthenticatedLayout,
        Head
    },
    data() {
        return {
            enabling: false,
            disabling: false,
            qrCode: null,
            recoveryCodes: [],
            updateForm: this.$inertia.form({
                _method: 'PUT',
                name: this.$page.props.auth.user.name,
                email: this.$page.props.auth.user.email
            }),
            passwordForm: this.$inertia.form({
                _method: 'PUT',
                current_password: '',
                password: '',
                password_confirmation: ''
            })
        }
    },
    computed: {
        twoFactorEnabled() {
            return !this.enabling && this.$page.props.auth.user.two_factor_enabled
        }
    },
    methods: {
        updateProfile() {
            this.updateForm.post(route('profile.update'), {
                preserveScroll: true
            })
        },
        updatePassword() {
            this.passwordForm.post(route('profile.update-password'), {
                preserveScroll: true
            })
        },
        enableTwoFactorAuthentication() {
            this.enabling = true
            this.$inertia.post(
                route('two-factor.enable'),
                {},
                {
                    preserveScroll: true,
                    onSuccess: () => Promise.all([this.showQrCode(), this.showRecoveryCodes()]),
                    onFinish: () => (this.enabling = false)
                }
            )
        },
        showQrCode() {
            return axios.get(route('two-factor.qr-code')).then((response) => {
                this.qrCode = response.data.svg
            })
        },
        showRecoveryCodes() {
            return axios.get(route('two-factor.recovery-codes')).then((response) => {
                this.recoveryCodes = response.data
            })
        },
        regenerateRecoveryCodes() {
            axios.post(route('two-factor.recovery-codes')).then(() => {
                this.showRecoveryCodes()
            })
        },
        disableTwoFactorAuthentication() {
            this.disabling = true
            this.$inertia.delete(route('two-factor.disable'), {
                preserveScroll: true,
                onSuccess: () => (this.disabling = false)
            })
        }
    }
}
</script>
