<template>
    <Head v-bind:title="$t('auth.reset_password')" />

    <ValidationErrors class="mb-4" />

    <form @submit.prevent="submit">
        <div>
            <Label for="email" v-bind:value="$t('auth.email')" />
            <Input id="email" v-model="form.email" type="email" class="mt-1 block w-full" required autofocus autocomplete="username" />
        </div>

        <div class="mt-4">
            <Label for="password" v-bind:value="$t('auth.label_password')" />
            <Input id="password" v-model="form.password" type="password" class="mt-1 block w-full" required autocomplete="new-password" />
        </div>

        <div class="mt-4">
            <Label for="password_confirmation" v-bind:value="$t('auth.confirm_password')" />
            <Input id="password_confirmation" v-model="form.password_confirmation" type="password" class="mt-1 block w-full" required autocomplete="new-password" />
        </div>

        <div class="mt-4 flex items-center justify-end">
            <Button :class="{ 'opacity-25': form.processing }" :disabled="form.processing">{{ $t('auth.reset_password') }}</Button>
        </div>
    </form>
</template>

<script>
import Button from '@/Components/Button.vue'
import GuestLayout from '@/Layouts/Guest.vue'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import ValidationErrors from '@/Components/ValidationErrors.vue'
import { Head } from '@inertiajs/vue3'

export default {
    components: {
        Button,
        Input,
        Label,
        ValidationErrors,
        Head
    },
    layout: GuestLayout,

    props: {
        email: String,
        token: String
    },

    data() {
        return {
            form: this.$inertia.form({
                token: this.token,
                email: this.email,
                password: '',
                password_confirmation: ''
            })
        }
    },

    methods: {
        submit() {
            this.form.post(this.route('password.update'), {
                onFinish: () => this.form.reset('password', 'password_confirmation')
            })
        }
    }
}
</script>
