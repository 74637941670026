<script setup>
import { onMounted, ref } from 'vue'

defineProps({
    modelValue: String | Number,
})

defineEmits(['update:modelValue'])

const inputRef = ref(null)

onMounted(() => {
    if (inputRef.value.hasAttribute('autofocus')) {
        inputRef.value.focus()
    }
})

defineExpose({ focus: () => input.value.focus() })
</script>

<template>
    <input ref="inputRef"
        class="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" />
</template>
