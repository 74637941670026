<template>
    <Head title="Winkel aanmaken" />

    <AuthenticatedLayout>
        <template #header>
            <h1 class="text-2xl font-semibold text-gray-900">{{ $t('stores.create_store') }}</h1>
        </template>

        <form method="POST" class="overflow-hidden bg-white p-6 shadow-sm sm:rounded-lg" @submit.prevent="createStore">
            <div class="grid grid-cols-6 gap-8">
                <div class="col-span-3">
                    <Label for="name" v-bind:value="$t('stores.name')" />
                    <Input id="name" v-model="form.name" type="text" class="mt-1 block w-full" required />
                    <ValidationError name="name" />
                </div>

                <div class="col-span-3">
                    <Label for="shop_type" v-bind:value="$t('stores.shop_type')" />
                    <Select id="shop_type" v-model="form.shop_type" class="mt-1 block w-full">
                        <option disabled value="">{{ $t('stores.select_an_option') }}</option>
                        <option v-for="type in shopTypeOptions" :key="type" :value="type" :selected="form.shop_type === type">
                            {{ type }}
                        </option>
                    </Select>
                    <ValidationError name="shop_type" />
                </div>

                <div class="col-span-6">
                    <Label for="is_fulfillment" v-bind:value="$t('stores.is_fulfillment')" />
                    <Select id="is_fulfillment" v-model="form.is_fulfillment" class="mt-1 block w-full">
                        <option disabled value="">{{ $t('stores.select_an_option') }}</option>
                        <option :value="1">{{ $t('stores.true') }}</option>
                        <option :value="0">{{ $t('stores.false') }}</option>
                    </Select>
                    <ValidationError name="is_fulfillment" />
                </div>

                <div :class="[form.shop_type === 'Lunar' ? 'col-span-3':'col-span-6']">
                    <Label for="site_url" v-bind:value="$t('stores.site_url')" />
                    <Input id="site_url" v-model="form.site_url" type="text" class="mt-1 block w-full" required />
                    <ValidationError name="site_url" />
                </div>

                <div class="col-span-3" v-if="form.shop_type === 'Lunar'">
                    <Label for="api_key" v-bind:value="$t('stores.api_key')" />
                    <Input id="api_key" v-model="form.api_key" type="text" class="mt-1 block w-full" required />
                    <ValidationError name="api_key" />
                </div>

                <div class="col-span-3" v-if="form.shop_type === 'WooCommerce'">
                    <Label for="consumer_key" v-bind:value="$t('stores.consumer_key')" />
                    <Input id="consumer_key" v-model="form.consumer_key" type="text" class="mt-1 block w-full" required />
                    <ValidationError name="consumer_key" />
                </div>

                <div class="col-span-3" v-if="form.shop_type === 'WooCommerce'">
                    <Label for="password" v-bind:value="$t('stores.consumer_secret')" />
                    <Input id="consumer_secret" v-model="form.consumer_secret" type="text" class="mt-1 block w-full" required />
                    <ValidationError name="consumer_secret" />
                </div>

                <div class="col-span-6" v-if="form.shop_type === 'WooCommerce'">
                    <Label for="order_states" v-bind:value="$t('stores.order_states_to_sync')" />
                    <select id="order_states" v-model="form.order_states" multiple size="9" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                        <option v-for="status in orderStatuses" :key="status" :value="status">
                            {{ status }}
                        </option>
                    </select>
                    <ValidationError name="order_states" />
                </div>

                <hr class="col-span-6" />

                <div class="col-span-6">
                    <div class="col-span-6">
                       <h1 class="mb-4 text-xl font-bold text-gray-900">{{ $t('stores.settings') }}</h1>

                        <div class="grid grid-cols-6 gap-6 py-2">
                            <div class="col-span-6">
                                <Label for="snelstart_api_clientkey" v-bind:value="$t('stores.snelstart_key')" />
                                <Input id="snelstart_api_clientkey" v-model="form.snelstart_api_clientkey" type="text" class="mt-1 block w-full" />
                                <ValidationError name="snelstart_api_clientkey" />
                            </div>
                            <div class="col-span-6">
                                <Label for="general_ledger_code_revenue_goods_high" v-bind:value="$t('stores.general_ledger_code_revenue_goods_high')" />
                                <Input id="general_ledger_code_revenue_goods_high" v-model="form.general_ledger_code_revenue_goods_high" type="number" class="mt-1 block w-full" />
                                <ValidationError name="general_ledger_code_revenue_goods_high" />
                            </div>

                            <div class="col-span-6">
                                <Label for="general_ledger_code_revenue_shipping_high" v-bind:value="$t('stores.general_ledger_code_revenue_shipping_high')" />
                                <Input id="general_ledger_code_revenue_shipping_high" v-model="form.general_ledger_code_revenue_shipping_high" type="number" class="mt-1 block w-full" />
                                <ValidationError name="general_ledger_code_revenue_shipping_high" />
                            </div>

                            <div class="col-span-3">
                                <Label for="kiyoh_key" v-bind:value="$t('stores.kiyoh_key')" />
                                <Input id="kiyoh_key" v-model="form.kiyoh_key" type="text" class="mt-1 block w-full" />
                                <ValidationError name="kiyoh_key" />
                            </div>

                            <div class="col-span-3">
                                <Label for="kiyoh_location_id" v-bind:value="$t('stores.kiyoh_location_id')" />
                                <Input id="kiyoh_location_id" v-model="form.kiyoh_location_id" type="text" class="mt-1 block w-full" />
                                <ValidationError name="kiyoh_location_id" />
                            </div>

                            <div class="col-span-6">
                                <div class="flex gap-3">
                                    <Checkbox id="kiyoh_invite" v-model="form.kiyoh_invite" name="kiyoh_invite" />
                                    <Label for="kiyoh_invite" v-bind:value="$t('stores.kiyoh_invite')" />
                                    <ValidationError name="kiyoh_invite" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="col-span-6" />

                <div class="col-span-6">
                    <div v-for="(shippingMethod, index) in form.shippingMethods" :key="index" class="grid grid-cols-10 gap-4 py-2">
                        <div class="col-span-3">
                            <Label :for="'shipping_method-' + index + '-name'" v-bind:value="$t('stores.name')" />
                            <Input :id="'shipping_method-' + index + '-name'" v-model="shippingMethod.name" type="text" class="mt-1 block w-full" />
                            <ValidationError :name="'shippingMethods.' + index + '.name'" />
                        </div>

                        <div class="col-span-3">
                            <Label :for="'shipping_method-' + index + '-bagisto_name'" v-bind:value="$t('stores.name_in_bagisto')" />
                            <Input :id="'shipping_method-' + index + '-bagisto_name'" v-model="shippingMethod.bagisto_name" type="text" class="mt-1 block w-full" />
                            <ValidationError :name="'shippingMethods.' + index + '.bagisto_name'" />
                        </div>

                        <div class="col-span-4">
                            <Label :for="'shipping_method-' + index + '-price'" v-bind:value="$t('stores.price')" />

                            <div class="flex">
                                <MoneyInput :id="'shipping_method-' + index + '-price'" v-model="shippingMethod.price" class="mt-1 block flex-1" />
                                <Button class="ml-1" type="button" @click="deleteShippingMethod(index)">X</Button>
                            </div>
                            <ValidationError :name="'shippingMethods.' + index + '.price'" />
                        </div>
                    </div>

                    <Button type="button" @click="addShippingMethod">{{ $t('stores.add_shipping_method') }}</Button>
                </div>

                <hr class="col-span-6" />

                <div class="col-span-6">
                    <div v-for="(paymentMethod, index) in form.paymentMethods" :key="index" class="grid grid-cols-10 gap-4 py-2">
                        <div class="col-span-3">
                            <Label :for="'payment_method-' + index + '-name'" v-bind:value="$t('stores.name')" />
                            <Input :id="'payment_method-' + index + '-name'" v-model="paymentMethod.name" type="text" class="mt-1 block w-full" />
                            <ValidationError :name="'paymentMethods.' + index + '.name'" />
                        </div>

                        <div class="col-span-3">
                            <Label :for="'payment_method-' + index + '-bagisto_name'" v-bind:value="$t('stores.name_in_bagisto')" />
                            <Input :id="'payment_method-' + index + '-bagisto_name'" v-model="paymentMethod.bagisto_name" type="text" class="mt-1 block w-full" />
                            <ValidationError :name="'paymentMethods.' + index + '.bagisto_name'" />
                        </div>

                        <div class="col-span-4">
                            <Label :for="'payment_method-' + index + '-price'" v-bind:value="$t('stores.price')" />

                            <div class="flex">
                                <MoneyInput :id="'payment_method-' + index + '-price'" v-model="paymentMethod.price" class="mt-1 block flex-1" />
                                <Button class="ml-1" type="button" @click="deletePaymentMethod(index)">X</Button>
                            </div>
                            <ValidationError :name="'paymentMethods.' + index + '.price'" />
                        </div>
                    </div>

                    <Button type="button" @click="addPaymentMethod">{{ $t('stores.add_payment_method') }}</Button>
                </div>

                <hr class="col-span-6" />

                <div class="col-span-6">
                    <h1 class="mb-4 text-xl font-bold text-gray-900">{{ $t('stores.cost_items') }}</h1>

                    <div v-for="(extraCost, index) in form.extraCosts" :key="index" class="mb-4 grid grid-cols-10 gap-4 py-2">
                        <div class="col-span-5">
                            <Label :for="'extra_cost-' + index + '-name'" v-bind:value="$t('stores.name')" />
                            <Input :id="'extra_cost-' + index + '-name'" v-model="extraCost.name" type="text" class="mt-1 block w-full" />
                            <ValidationError :name="'extraCosts.' + index + '.name'" />
                        </div>

                        <div class="col-span-5">
                            <Label :for="'extra_cost-' + index + '-price'" v-bind:value="$t('stores.price')" />

                            <div class="flex">
                                <MoneyInput :id="'extra_cost-' + index + '-price'" v-model="extraCost.price" class="mt-1 block flex-1" />
                                <Button class="ml-1" type="button" @click="deleteExtraCost(index)">X</Button>
                            </div>
                            <ValidationError :name="'extraCosts.' + index + '.price'" />
                        </div>
                    </div>

                    <div v-if="form.extraCosts.length == 0" class="mb-4">{{ $t('stores.no_cost_items_have_been_created_at_this_time') }}</div>

                    <Button type="button" @click="addExtraCost">{{ $t('stores.add_cost_item') }}</Button>
                </div>

                <hr class="col-span-6" />

                <div class="col-span-6 space-x-2 text-right">
                    <NavButton :href="route('stores.index')">{{ $t('stores.back') }}</NavButton>
                    <Button type="submit" :disabled="form.processing">{{ $t('stores.save') }}</Button>
                </div>
            </div>
        </form>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Input from '@/Components/Input.vue'
import Checkbox from '@/Components/Checkbox.vue'
import Label from '@/Components/Label.vue'
import Button from '@/Components/Button.vue'
import ValidationError from '@/Components/ValidationError.vue'
import NavButton from '@/Components/NavButton.vue'
import MoneyInput from '@/Components/MoneyInput.vue'
import Select from "@/Components/Select.vue";

export default {
    components: {
        Select,
        MoneyInput,
        NavButton,
        Button,
        ValidationError,
        Input,
        Label,
        Checkbox,
        AuthenticatedLayout,
        Head
    },
    props: {
        shopTypeOptions: Object,
        orderStatuses: Array,
    },
    data() {
        return {
            form: this.$inertia.form({
                name: '',
                shippingMethods: [],
                paymentMethods: [],
                extraCosts: [],
                shop_type: '',
                is_fulfillment: '',
                site_url: '',
                consumer_key: '',
                consumer_secret: '',
                order_states: [],
                general_ledger_code_revenue_goods_high : '',
                general_ledger_code_revenue_shipping_high : '',
                api_key: '',
                kiyoh_key: '',
                kiyoh_location_id: '',
                kiyoh_invite:'',
                snelstart_api_clientkey: ''
            })
        }
    },
    methods: {
        createStore() {
            this.form.post(route('stores.store'), {
                preserveScroll: true
            })
        },
        addShippingMethod() {
            this.form.shippingMethods.push({
                name: '',
                bagisto_name: '',
                price: 5
            })
        },
        deleteShippingMethod(index) {
            this.form.shippingMethods.splice(index, 1)
        },
        addPaymentMethod() {
            this.form.paymentMethods.push({
                name: '',
                bagisto_name: '',
                price: 5
            })
        },
        deletePaymentMethod(index) {
            this.form.paymentMethods.splice(index, 1)
        },
        addExtraCost() {
            this.form.extraCosts.push({
                name: '',
                price: 5
            })
        },
        deleteExtraCost(index) {
            this.form.extraCosts.splice(index, 1)
        }
    }
}
</script>
