<template>

    <Head :title="$t('credit-payment.edit_credit_payment') + ' - ' + order.extern_id" />

    <AuthenticatedLayout>
        <template #header>
            <h1 class="text-2xl font-semibold text-gray-900">{{ $t('credit-payment.edit_credit_payment') }} - {{
        order.extern_id }}</h1>
        </template>
        <CreditPaymentForm v-model="form" @submit="updateCreditPayment" :order="order" :reasons="reasons"
            :other-reason-id="otherReasonId" />
    </AuthenticatedLayout>
</template>

<script setup>

import AuthenticatedLayout from "@/Layouts/Authenticated.vue";
import { Head, useForm } from '@inertiajs/vue3'
import CreditPaymentForm from './Components/Form.vue';

const props = defineProps({
    creditPayment: Object,
    order: Object,
    reasons: Object,
    finalProductsForShipment: Object,
    otherReasonId: Number
});

const form = useForm(props.creditPayment);

const updateCreditPayment = () => {
    form.post(route('credit-payment.update', { order: props.order.id }), {
        preserveScroll: true
    })
}
</script>
