<template>

    <Head :title="$t('coupons.coupon')" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex flex-wrap justify-between">
                <div>
                    <h1 class="text-2xl font-semibold text-gray-900">{{ $t('coupons.coupon') }}</h1>
                </div>
                <div>
                    <NavButton v-if="can('update coupons')" :href="route('coupons.edit', coupon)">{{ $t('coupons.edit')
                        }}
                    </NavButton>
                </div>
            </div>
        </template>

        <div class="grid grid-cols-12 gap-8">
            <div class="col-span-12 md:col-span-6">
                <div class="overflow-visible bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">

                                <dt class="text-sm font-bold text-gray-500">{{ $t('coupons.info') }}</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0"></dd>
                                <dt class="text-sm font-medium text-gray-500">{{ $t('coupons.code') }}</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div class="text-sm font-medium text-gray-900"></div>
                                    <div>{{ coupon.code }}</div>
                                </dd>

                                <dt class="text-sm font-medium text-gray-500">{{ $t('coupons.description') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ coupon.description ?? '-' }}
                                </dd>

                                <dt class="text-sm font-medium text-gray-500">{{ $t('coupons.amount') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <div class="text-sm font-medium text-gray-900">
                                        {{ coupon.discount_type === 'percent' ? '' : '€' }}{{ coupon.amount }}{{
        coupon.discount_type === 'percent' ? '%' : '' }}
                                    </div>
                                    <div class="text-sm text-gray-500">
                                        {{ $t('coupons.' + coupon.discount_type) }}
                                    </div>
                                </dd>
                            </div>

                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-bold text-gray-500">{{ $t('coupons.usage') }}</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0"></dd>
                                <dt class="text-sm font-medium text-gray-500">{{ $t('coupons.date_expires') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ coupon.date_expires }}
                                </dd>
                                <dt class="text-sm font-medium text-gray-500">{{ $t('coupons.usage_count') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ removeZeroNumbers(coupon.usage_count) }}
                                </dd>
                                <dt class="text-sm font-medium text-gray-500">{{ $t('coupons.usage_limit') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ removeZeroNumbers(coupon.usage_limit) }}
                                </dd>
                                <dt class="text-sm font-medium text-gray-500">{{ $t('coupons.usage_limit_per_user')
                                    }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ removeZeroNumbers(coupon.usage_limit_per_user) }}
                                </dd>
                                <dt class="text-sm font-medium text-gray-500">{{
        $t('coupons.limit_usage_to_x_items') }}
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ removeZeroNumbers(coupon.limit_usage_to_x_items) }}
                                </dd>
                                <dt class="text-sm font-medium text-gray-500">{{ $t('coupons.minimum_amount') }}
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ removeZeroNumbers(coupon.minimum_amount) }}
                                </dd>
                                <dt class="text-sm font-medium text-gray-500">{{ $t('coupons.maximum_amount') }}
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ removeZeroNumbers(coupon.maximum_amount) }}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <div class="col-span-12 md:col-span-6">
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('coupons.individual_use') }}</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div class="text-sm font-medium text-gray-900">{{ coupon.individual_use ? 'Ja' :
        'Nee' }}
                                    </div>
                                </dd>
                                <dt class="text-sm font-medium text-gray-500">{{ $t('coupons.free_shipping') }}
                                </dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div class="text-sm font-medium text-gray-900">{{ coupon.free_shipping ? 'Ja' :
        'Nee' }}
                                    </div>
                                </dd>
                                <dt class="text-sm font-medium text-gray-500">{{ $t('coupons.exclude_sale_items')
                                    }}</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div class="text-sm font-medium text-gray-900">{{ coupon.exclude_sale_items ? 'Ja' :
        'Nee'
                                        }}</div>
                                </dd>
                            </div>

                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('coupons.products') }}</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div class="text-sm font-medium text-gray-900">{{ includedProducts }}</div>
                                </dd>
                                <dt class="text-sm font-medium text-gray-500">{{ $t('coupons.excluded_products') }}
                                </dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div class="text-sm font-medium text-gray-900">{{ excludedProducts }}</div>
                                </dd>
                            </div>

                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('coupons.email_restrictions') }}
                                </dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div class="text-sm font-medium text-gray-900">{{emailRestrictions}}
                                    </div>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    </AuthenticatedLayout>
</template>

<script setup>
import NavButton from '@/Components/NavButton.vue';
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import { computed } from 'vue'

const props = defineProps({
    coupon: {
        type: Object,
    }
});

const includedProducts = computed(() => props.coupon.products.filter(product => !product.pivot.is_excluded).map(item => item.name).join(', '));
const excludedProducts = computed(() => props.coupon.products.filter(product => product.pivot.is_excluded).map(item => item.name).join(', '));

const emailRestrictions = computed(() => props.coupon.email_restrictions.map(item => item.email).join(', '));

const removeZeroNumbers = (num) => {
    return num === 0 ? '' : num;
}
</script>
