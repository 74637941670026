<template>
    <Head title="Dashboard" />

    <AuthenticatedLayout>
        <template #header>
            <h1 class="text-2xl font-semibold text-gray-900">{{ $t('dashboard.dashboard') }}</h1>
        </template>

        <dl class="grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div v-if="can('view orders placed')" class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt class="text-sm font-medium text-gray-500">{{ $t('dashboard.orders_today') }}</dt>
                <dd class="mt-1 text-3xl font-semibold text-gray-900">
                    {{ statistics.orders_today }}
                </dd>
            </div>
            <div v-if="can('view orders processed')" class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt class="text-sm font-medium text-gray-500">{{ $t('dashboard.orders_processed_today') }}</dt>
                <dd class="mt-1 text-3xl font-semibold text-gray-900">
                    {{ statistics.orders_processed_today }}
                </dd>
            </div>

            <div v-if="can('view sales data')" class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt class="text-sm font-medium text-gray-500">{{ $t('dashboard.average_value_orders_today') }}</dt>
                <dd class="mt-1 text-3xl font-semibold text-gray-900">
                    {{ formatPrice(statistics.average_today/100) }}
                </dd>
            </div>

            <div v-if="can('view sales data')" class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt class="text-sm font-medium text-gray-500">{{ $t('dashboard.revenue_today') }}</dt>
                <dd class="mt-1 text-3xl font-semibold text-gray-900">
                    {{ formatPrice(statistics.revenue_today/100) }}
                </dd>
            </div>
            <div v-if="can('view sales data')" class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt class="text-sm font-medium text-gray-500">{{ $t('dashboard.revenue_past_7_days') }}</dt>
                <dd class="mt-1 text-3xl font-semibold text-gray-900">
                    {{ formatPrice(statistics.revenue_last_week/100) }}
                </dd>
            </div>

            <div v-if="can('view sales data')" class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt class="text-sm font-medium text-gray-500">{{ $t('dashboard.revenue_past_30_days') }}</dt>
                <dd class="mt-1 text-3xl font-semibold text-gray-900">
                    {{ formatPrice(statistics.revenue_last_month/100) }}
                </dd>
            </div>
        </dl>

        <div class="mt-6 grid grid-cols-1 gap-6">
            <div>
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div v-if="can('view order')" class="px-4 py-5 sm:p-0">
                        <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                            <h3 class="text-lg font-semibold">{{ $t('dashboard.latest_orders') }}</h3>
                        </div>

                        <div class="flex flex-col">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div class="overflow-hidden shadow sm:rounded-lg">
                                        <table class="min-w-full">
                                            <tbody>
                                                <tr v-for="order in latestOrders" :key="order.id" class="border-b border-gray-200 bg-white flex justify-between items-center">
                                                    <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 flex gap-3 w-1/3 items-center">
                                                        <Link
                                                            clas="hover:underline"
                                                            :href="
                                                                route('orders.show', {
                                                                    order: order.id
                                                                })
                                                            "
                                                        >
                                                            {{ order.extern_id }}
                                                        </Link>

                                                        <status-badge :status="order.status"></status-badge>
                                                    </td>
                                                    <td class="whitespace-nowrap px-6 py-4 text-right text-sm flex gap-3 text-gray-500 w-1/3 items-center">
                                                        <Link
                                                            :href="
                                                                route('orders.show', {
                                                                    order: order.id
                                                                })
                                                            "
                                                            class="block text-xs font-medium text-gray-900 hover:underline"
                                                        >
                                                            {{ dayjs(order.ordered_at).format('LL') }}
                                                        </Link>
                                                        <Link
                                                            :href="
                                                                route('orders.show', {
                                                                    order: order.id
                                                                })
                                                            "
                                                            class="block text-xs text-gray-500 hover:underline"
                                                        >
                                                            {{ dayjs(order.ordered_at).format('LT') }}
                                                        </Link>
                                                    </td>
                                                    <td class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500 w-1/3 items-center">
                                                        <Link
                                                            class="hover:underline"
                                                            :href="
                                                                route('orders.show', {
                                                                    order: order.id
                                                                })
                                                            "
                                                        >
                                                            {{ formatPrice(order.total/100) }}
                                                        </Link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                            <NavButton :href="route('orders.index')">{{ $t('dashboard.view_all_orders') }}</NavButton>
                        </div>
                    </div>
                </div>
            </div>

<!--            <div>-->
<!--                <div class="overflow-hidden bg-white shadow sm:rounded-lg">-->
<!--                    <div class="px-4 py-5 sm:p-0">-->
<!--                        <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">-->
<!--                            <h3 class="text-lg font-semibold">{{ $t('dashboard.products_low_on_stock') }}</h3>-->
<!--                        </div>-->

<!--                        <div class="flex flex-col">-->
<!--                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">-->
<!--                                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">-->
<!--                                    <div class="overflow-hidden shadow sm:rounded-lg">-->
<!--                                        <table class="min-w-full">-->
<!--                                            <tbody>-->
<!--                                                <tr v-for="variation in productsLowStock" :key="variation.id" class="border-b border-gray-200 bg-white">-->
<!--                                                    <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">-->
<!--                                                        <div v-if="can('view product variation')">-->
<!--                                                            <Link-->
<!--                                                                v-if="variation.product.name"-->
<!--                                                                :href="-->
<!--                                                                    route('products.variations.show', {-->
<!--                                                                        product: variation.product_id,-->
<!--                                                                        store: storeId,-->
<!--                                                                        variation: variation.id-->
<!--                                                                    })-->
<!--                                                                "-->
<!--                                                                class="block font-bold text-gray-900 hover:underline"-->
<!--                                                            >-->
<!--                                                                {{ variation.product.name }}-->
<!--                                                            </Link>-->
<!--                                                            <Link-->
<!--                                                                v-if="variation.name"-->
<!--                                                                :href="-->
<!--                                                                    route('products.variations.show', {-->
<!--                                                                        product: variation.product_id,-->
<!--                                                                        store: storeId,-->
<!--                                                                        variation: variation.id-->
<!--                                                                    })-->
<!--                                                                "-->
<!--                                                                class="block font-medium text-gray-900 hover:underline"-->
<!--                                                            >-->
<!--                                                                {{ variation.name }}-->
<!--                                                            </Link>-->
<!--                                                            <Link-->
<!--                                                                v-if="variation.numbers_packed"-->
<!--                                                                :href="-->
<!--                                                                    route('products.variations.show', {-->
<!--                                                                        product: variation.product_id,-->
<!--                                                                        store: storeId,-->
<!--                                                                        variation: variation.id-->
<!--                                                                    })-->
<!--                                                                "-->
<!--                                                                class="block font-medium text-gray-900 hover:underline"-->
<!--                                                            >-->
<!--                                                                {{ variation.numbers_packed }}-->
<!--                                                                {{ $t('dashboard.packaged') }}-->
<!--                                                            </Link>-->
<!--                                                            <Link-->
<!--                                                                v-if="variation.barcode"-->
<!--                                                                :href="-->
<!--                                                                    route('products.variations.show', {-->
<!--                                                                        product: variation.product_id,-->
<!--                                                                        store: storeId,-->
<!--                                                                        variation: variation.id-->
<!--                                                                    })-->
<!--                                                                "-->
<!--                                                                class="block font-medium text-gray-900 hover:underline"-->
<!--                                                            >-->
<!--                                                                {{ $t('dashboard.barcode') }}-->
<!--                                                                {{ variation.barcode }}-->
<!--                                                            </Link>-->
<!--                                                        </div>-->

<!--                                                        <div v-else>-->
<!--                                                            <div v-if="variation.product.name" class="block font-bold text-gray-900">-->
<!--                                                                {{ variation.product.name }}-->
<!--                                                            </div>-->
<!--                                                            <div v-if="variation.name" class="font-medium text-gray-900">-->
<!--                                                                {{ variation.name }}-->
<!--                                                            </div>-->
<!--                                                            <div v-if="variation.numbers_packed" class="font-medium text-gray-900">-->
<!--                                                                {{ variation.numbers_packed }}-->
<!--                                                                {{ $t('dashboard.packaged') }}-->
<!--                                                            </div>-->
<!--                                                            <div v-if="variation.barcode" class="font-medium text-gray-900">-->
<!--                                                                {{ $t('dashboard.barcode') }}-->
<!--                                                                {{ variation.barcode }}-->
<!--                                                            </div>-->
<!--                                                        </div>-->
<!--                                                    </td>-->
<!--                                                    <td class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">-->
<!--                                                        {{ variation.quantity }}-->

<!--                                                        {{ $t('dashboard.on_stock') }}-->
<!--                                                    </td>-->
<!--                                                </tr>-->
<!--                                            </tbody>-->
<!--                                        </table>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Link, Head } from '@inertiajs/vue3'
import NavButton from '@/Components/NavButton.vue'
import StatusBadge from '@/Components/StatusBadge.vue'

export default {
    components: {
        StatusBadge,
        NavButton,
        AuthenticatedLayout,
        Head,
        Link
    },
    props: {
        statistics: Object,
        latestOrders: Object,
        productsLowStock: Object
    }
}
</script>
