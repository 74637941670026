<template>
    <Head :title="'Rol wijzigen - ' + role.name" />

    <AuthenticatedLayout>
        <template #header>
            <div>
                <h1 class="text-2xl font-semibold text-gray-900">Rol wijzigen - {{ role.name }}</h1>
                <h3 class="text-lg text-gray-900">Winkel - {{ store.name }}</h3>
            </div>
        </template>

        <form method="POST" class="overflow-hidden bg-white p-6 shadow-sm sm:rounded-lg" @submit.prevent="updateRole">
            <div class="grid grid-cols-6 gap-8">
                <div class="col-span-3">
                    <Label for="name" value="Naam" />
                    <Input id="name" v-model="form.name" type="text" class="mt-1 block w-full" required />
                    <ValidationError name="name" />
                </div>
                <div class="col-span-6 grid grid-cols-3 gap-6">
                    <div v-for="(permissions, name) in availablePermissions" :key="name">
                        <h1 class="text-lg font-medium text-gray-900">
                            {{ name }}
                        </h1>
                        <div class="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200">
                            <div v-for="permission in permissions" :key="permission" class="relative flex items-start py-4">
                                <div class="min-w-0 flex-1 text-sm">
                                    <label :for="permissionId(permission)" class="select-none font-medium text-gray-700">
                                        {{ permission }}
                                    </label>
                                </div>
                                <div class="ml-3 flex h-5 items-center">
                                    <input :id="permissionId(permission)" :checked="form.selectedPermissions.includes(permission)" name="permissions[]" :value="permission" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" @change="togglePermission(permission)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-6 space-x-2 text-right">
                    <NavButton :href="route('roles.show', { role: role.id })">Terug</NavButton>
                    <Button type="submit" :disabled="form.processing">Opslaan</Button>
                </div>
            </div>
        </form>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import Button from '@/Components/Button.vue'
import ValidationError from '@/Components/ValidationError.vue'
import NavButton from '@/Components/NavButton.vue'

export default {
    components: {
        NavButton,
        Button,
        ValidationError,
        Input,
        Label,
        AuthenticatedLayout,
        Head
    },
    props: {
        role: Object,
        store: Object,
        currentPermissions: Object,
        availablePermissions: Object
    },
    data() {
        return {
            form: this.$inertia.form({
                _method: 'PUT',
                name: this.role.name,
                selectedPermissions: this.currentPermissions
            })
        }
    },
    methods: {
        updateRole() {
            this.form.post(
                route('stores.roles.update', {
                    store: this.store.id,
                    role: this.role.id
                }),
                {
                    preserveScroll: true
                }
            )
        },
        togglePermission(permission) {
            const index = this.form.selectedPermissions.indexOf(permission)
            if (index === -1) {
                this.form.selectedPermissions.push(permission)
            } else {
                this.form.selectedPermissions.splice(index, 1)
            }
        },
        permissionId(str) {
            str = str.replace(/^\s+|\s+$/g, '') // trim
            str = str.toLowerCase()

            // remove accents, swap ñ for n, etc
            var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
            var to = 'aaaaeeeeiiiioooouuuunc------'
            for (var i = 0, l = from.length; i < l; i++) {
                str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
            }

            str = str
                .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                .replace(/\s+/g, '-') // collapse whitespace and replace by -
                .replace(/-+/g, '-') // collapse dashes

            return 'permission-' + str
        }
    }
}
</script>
