<template>
    <TransitionRoot as="template" :show="transaction != false">
        <Dialog as="div" class="fixed inset-0 z-10 overflow-y-auto" @close="$emit('close')">
            <div class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="relative inline-block transform rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle">
                        <div>
                            <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-indigo-100">
                                <CreditCardIcon class="h-6 w-6 text-indigo-600" aria-hidden="true" />
                            </div>
                            <div class="mt-3 sm:mt-5">
                                <DialogTitle as="h3" class=" text-l font-medium leading-6 text-gray-900">Transactie #: {{ transaction.id }}</DialogTitle>
                                <p>{{ transaction.raw_content }}</p>
                                <div class="mt-4 space-y-4">
                                    <div>
                                        <Label for="order" value="Order" required/>
                                        <multiselect
                                            v-model="form.order_id"
                                            :options="orderOptions"
                                            :searchable="true"
                                            :loading="isLoading"
                                            :internal-search="false"
                                            :clear-on-select="true"
                                            :close-on-select="true"
                                            :hide-selected="true"
                                            :preserve-search="true"
                                            :max-height="200"
                                            :placeholder="'Bestelling zoeken...'"
                                            @search-change="fetchOrders"
                                            @input="onOrderChange"
                                        />
                                        <ValidationError name="order" />
                                    </div>

                                    <div v-if="form.order_id > 0">
                                        <Label for="status" value="Order Status" />
                                        <Select id="status" v-model="form.status" class="mt-1 block w-full">
                                            <option value="system" :selected="form.status == 'system'">Systeem bepaald</option>
                                            <option value="processing" :selected="form.status == 'processing'">In behandeling</option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 sm:mt-6">
                            <Button class="w-full justify-center" @click="connectTransaction">Transactie koppelen</Button>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import Button from '@/Components/Button.vue'
import { CreditCardIcon } from '@heroicons/vue/24/outline'
import Label from '@/Components/Label.vue'
import Select from '@/Components/Select.vue'
import ValidationError from '@/Components/ValidationError.vue'
import Multiselect from '@vueform/multiselect'

export default {
    components: {
        ValidationError,
        Select,
        Label,
        Button,
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        Multiselect,
        CreditCardIcon
    },
    props: {
        transaction: Object
    },
    emits: ['close'],
    data() {
        return {
            form: this.$inertia.form({
                order_id: null,
                status: 'system',
            }),
            orderOptions: [],
        }
    },
    methods: {
        connectTransaction() {
            this.form.post(this.route('transactions.connect', { transaction: this.transaction.id }), {
                preserveScroll: false,
                onSuccess: this.$emit('close')
            })
        },
        fetchOrders(searchquery) {

            if (searchquery.length === 0) {
                return;
            }

            axios.get(route('transactions.search.orders', {query: searchquery}))
                .then(res => {
                    this.orderOptions = res.data.map(order => ({
                        value: order.id,
                        label: order.billing_firstname + ' ' + order.billing_lastname + ' ' + order.email + ' - ' + order.number + ' - ' + this.formatPrice(order.total/100),
                        email: order.email,
                        name: order.billing_firstname + ' ' + order.billing_lastname,
                        customer_id: order.customer_id
                    }));
                })
        },
        formatPrice(price) {
            const formatter = new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR'
            })
            return formatter.format(price)
        }
    }
}
</script>
