<template>
    <Head :title="'Product - ' + product.name" />
    <AuthenticatedLayout>
        <template #header>
            <div class="flex flex-wrap items-center justify-between">
                <div>
                    <h1 class="text-2xl font-semibold text-gray-900"> {{ $t('stores.product') }} - {{ product.name }}</h1>
                    <h3 class="text-lg text-gray-900">  {{ $t('stores.store') }} - {{ store.name }}</h3>
                </div>
                <div class="space-x-2">
                    <NavButton v-if="can('view store')" :href="route('stores.show', { store: store.id })">{{ $t('stores.to_shop') }}</NavButton>
                    <NavButton
                        v-if="can('edit product')"
                        :href="
                            route('products.edit', {
                                store: store.id,
                                product: product.id
                            })
                        "
                    >
                        {{ $t('stores.edit') }}
                    </NavButton>
                    <Button v-if="can('delete product')" @click="confirmingProductDeletion = true"> {{ $t('stores.delete') }}</Button>
                </div>
            </div>
        </template>

        <div class="grid grid-cols-12 gap-8">
            <div class="col-span-12">
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">  {{ $t('stores.name') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ product.name }}
                                </dd>
                            </div>
<!--                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">-->
<!--                                <dt class="text-sm font-medium text-gray-500">Verkoopprijs</dt>-->
<!--                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">-->
<!--                                    {{ formatPrice(product.price) }}-->
<!--                                </dd>-->
<!--                            </div>-->
                            <div v-if="product.cost_price" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">Kostprijs</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice(product.cost_price) }}
                                </dd>
                            </div>
                            <div v-if="product.short_description_stripped" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">Omschrijving</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ product.short_description_stripped }}
                                </dd>
                            </div>

                            <div v-if="product.image" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">Afbeelding</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <img :src="'/storage/' + product.image" :alt="product.name" class="mr-6 h-32 w-32 rounded object-cover object-center" />
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
        <ConfirmationModal v-if="can('delete product')" :show="confirmingProductDeletion" @close="confirmingProductDeletion = false">
            <template #title>Product verwijderen - {{ product.name }}</template>
            <template #content>Weet je zeker dat je dit product wilt verwijderen?</template>
            <template #footer>
                <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="deleteProduct">Product verwijderen</button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="confirmingProductDeletion = false">Annuleren</button>
            </template>
        </ConfirmationModal>

        <ConfirmationModal v-if="can('delete product variation')" :show="confirmingVariationDeletion != null" @close="confirmingVariationDeletion = null">
            <template #title>Variatie verwijderen</template>

            <template #content>Weet je zeker dat je deze variate wilt verwijderen?</template>

            <template #footer>
                <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="deleteVariation">Variatie verwijderen</button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="confirmingVariationDeletion = null">Annuleren</button>
            </template>
        </ConfirmationModal>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Button from '@/Components/Button.vue'
import NavButton from '@/Components/NavButton.vue'
import ConfirmationModal from '@/Components/ConfirmationModal.vue'

export default {
    components: {
        ConfirmationModal,
        NavButton,
        Button,
        AuthenticatedLayout,
        Head
    },
    props: {
        store: Object,
        product: Object,
        currentUsers: Array
    },
    data() {
        return {
            confirmingProductDeletion: false,
            confirmingVariationDeletion: null,
            form: this.$inertia.form({})
        }
    },
    methods: {
        deleteProduct() {
            this.form.delete(
                route('products.destroy', {
                    store: this.store.id,
                    product: this.product.id
                })
            )
            this.confirmingProductDeletion = null
        },
        deleteVariation() {
            this.form.delete(
                route('products.variations.destroy', {
                    store: this.store.id,
                    product: this.product.id,
                    variation: this.confirmingVariationDeletion.id
                })
            )
            this.confirmingVariationDeletion = null
        }
    }
}
</script>
