<template>
    <nav role="navigation" class="flex items-center justify-between">
        <div>
            <p class="text-sm leading-5 text-gray-700">

                <span v-if="links.from" class="font-bold">
                    <span>{{ links.from }}</span>-<span>{{ links.to }}</span>
                </span>
                <span v-else>
                    {{ links.data.length }}
                </span>
                {{ $t('pagination.from') }}
                <span class="font-medium">{{ links.total }}</span>
            </p>
        </div>

        <div class="flex">
            <template v-for="(link, p) in links.links" :key="p">
                <div
                    v-if="link.url === null"
                    class="mr-1 mb-1 rounded border bg-white px-4 py-2 text-sm leading-4 text-gray-400"
                    :class="{
                        'hidden sm:block': p !== links.links.length - 1 && p !== 0
                    }"
                    v-html="link.label"
                ></div>
                <Link
                    v-else
                    class="mr-1 mb-1 rounded border px-4 py-2 text-sm leading-4"
                    :class="{
                        'bg-gray-800 text-white': link.active,
                        'hover:gray-100 bg-white focus:border-gray-900 focus:text-gray-900': !link.active,
                        'hidden sm:block': p !== links.links.length - 1 && p !== 0
                    }"
                    :href="link.url"
                    v-html="link.label"
                ></Link>
            </template>
        </div>
    </nav>
</template>

<script>
import { Link } from '@inertiajs/vue3'

export default {
    components: {
        Link
    },

    props: {
        links: Object
    }
}
</script>
