<template>

    <Head :title="$t('coupons.create')" />

    <AuthenticatedLayout>
        <template #header>
            <h1 class="text-2xl font-semibold text-gray-900">{{ $t('coupons.create') }}</h1>
        </template>

        <CouponForm v-model="form" :products="products" @submit="createCoupon()" />
    </AuthenticatedLayout>
</template>

<script setup>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head, useForm } from '@inertiajs/vue3'
import CouponForm from './Components/Form.vue';

const props = defineProps({
    products: {
        type: Object,
    }
});

const form = useForm({
    code: '',
    amount: '',
    discount_type: 'fixed_cart',
    description: '',
    date_expires: '',
    usage_limit: 0,
    usage_limit_per_user: 0,
    limit_usage_to_x_items: 0,
    individual_use: false,
    free_shipping: false,
    exclude_sale_items: false,
    minimum_amount: null,
    maximum_amount: null,
    included_products: [],
    excluded_products: [],
    email_restrictions: [],
});

const createCoupon = () => {
    form.post(route('coupons.store', { form }));
}

</script>