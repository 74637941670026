<template>
    <Head title="Systeem rollen" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex flex-wrap justify-between">
                <div>
                    <h1 class="text-2xl font-semibold text-gray-900">Systeem rollen</h1>
                    <p class="max-w-2xl text-sm">
                        Op deze pagina kun je systeem rollen aanmaken, bewerken en verwijderen. Een systeem rol is een rol met permissies die over het gehele syteem gelden. Dit is bijvoorbeeld toestemming voor het aanmaken van gebruikers en winkels.
                        <br />
                        <br />
                        Winkel rollen kun je aanmaken bij het inzien van de desbetreffende winkel. Winkel rollen geven toegang om bijvoorbeeld bestellingen en retours te kunnen inzien.
                    </p>
                </div>
                <div>
                    <NavButton v-if="can('create role')" :href="route('roles.create')">Rol aanmaken</NavButton>
                </div>
            </div>
        </template>

        <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Naam</th>
                                    <th scope="col" class="relative px-6 py-3">
                                        <span class="sr-only">Bekijken</span>
                                        <span class="sr-only">Wijzigen</span>
                                        <span class="sr-only">Verwijderen</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="role in roles" :key="role.id">
                                    <td class="whitespace-nowrap px-6 py-4">
                                        <div class="">
                                            {{ role.name }}
                                        </div>
                                        <div class="text-sm text-gray-500">
                                            {{ role.permissions_count }}
                                            permissie(s)
                                        </div>
                                    </td>

                                    <td class="space-x-4 whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
                                        <Link
                                            v-if="can('view role')"
                                            :href="
                                                route('roles.show', {
                                                    role: role.id
                                                })
                                            "
                                            class="text-indigo-600 hover:text-indigo-900"
                                        >
                                            Bekijken
                                        </Link>
                                        <Link
                                            v-if="can('edit role')"
                                            :href="
                                                route('roles.edit', {
                                                    role: role.id
                                                })
                                            "
                                            class="text-indigo-600 hover:text-indigo-900"
                                        >
                                            Wijzigen
                                        </Link>
                                        <button v-if="can('delete role')" class="text-indigo-600 hover:text-indigo-900" @click="confirmingRoleDeletion = role">Verwijderen</button>
                                    </td>
                                </tr>
                                <tr v-if="roles.length == 0">
                                    <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900" colspan="3">Geen resultaten gevonden</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <ConfirmationModal v-if="can('delete role')" :show="confirmingRoleDeletion != null" @close="confirmingRoleDeletion = null">
            <template #title>
                Rol verwijderen
                <span v-if="confirmingRoleDeletion != null">- {{ confirmingRoleDeletion.name }}</span>
            </template>

            <template #content>Weet je zeker dat je deze rol wilt verwijderen?</template>

            <template #footer>
                <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="deleteRole">Rol verwijderen</button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="confirmingRoleDeletion = null">Annuleren</button>
            </template>
        </ConfirmationModal>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head, Link } from '@inertiajs/vue3'
import NavButton from '@/Components/NavButton.vue'
import ConfirmationModal from '@/Components/ConfirmationModal.vue'

export default {
    components: {
        ConfirmationModal,
        NavButton,
        AuthenticatedLayout,
        Head,
        Link
    },
    props: {
        roles: Array
    },
    data() {
        return {
            confirmingRoleDeletion: null,
            form: this.$inertia.form({})
        }
    },
    methods: {
        deleteRole() {
            this.form.delete(
                route('roles.destroy', {
                    role: this.confirmingRoleDeletion.id
                })
            )
        }
    }
}
</script>
