<template>
    <Head :title="$t('order_pack') +' - ' + order.extern_id" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex items-center justify-between">
                <h1 class="text-2xl font-semibold text-gray-900">{{ $t('orders.order_pack') }} - {{ order.extern_id }}</h1>

                <div class="mt-6 flex items-center justify-center space-x-4">
                    <NavButton v-if="can('view order')" :href="route('orders.show', { order: order.id })">{{ $t('orders.to_order') }}</NavButton>

                    <button v-if="can('create shipment')" class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-green-600 focus:border-green-900 focus:outline-none active:bg-green-900" @click="openPackOrderAndShip = true">{{ $t('orders.pack_and_send') }}</button>
                </div>
            </div>
        </template>
        {{ packItemsFinal }}
        <div>

            <!-- Todo: static content -->
            <div class="grid grid-cols-12 gap-8">
                <div class="col-span-12 md:col-span-4">
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:p-0">
                            <dl class="sm:divide-y sm:divide-gray-200">
                                <div class="py-4 sm:py-5 sm:px-6">
                                    <h3 class="text-lg font-semibold">Bestelling informatie</h3>
                                </div>
                                <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">ID</dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">1043883</dd>
                                </div>
                                <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">Besteld op</dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">12 apr 2023 16:53</dd>
                                </div>

                                <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">Verzendmethode</dt>
                                    <dd class="text-m font-bold text-gray-900 sm:col-span-2" style="margin-top:-2px">Pakket</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>

                <div class="col-span-12 md:col-span-4">
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:p-0">
                            <dl class="sm:divide-y sm:divide-gray-200">
                                <div class="py-4 sm:py-5 sm:px-6">
                                    <h3 class="text-lg font-semibold">Aflever informatie</h3>
                                </div>
                                <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">Afleveradres</dt>
                                    <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                        <div class="text-sm font-medium text-gray-900">
                                            <div v-if="order.shipping_company_name" class="font-bold">
                                                {{ order.shipping_company_name }}
                                            </div>
                                            <div>
                                                {{ order.shipping_firstname }}
                                                {{ order.shipping_lastname }}
                                            </div>
                                        </div>
                                        <div class="text-sm text-gray-500">
                                            <div>
                                                {{ order.shipping_address }}
                                            </div>

                                            <div>
                                                {{ order.shipping_postcode }}
                                                {{ order.shipping_city }}
                                                {{ order.shipping_country }}
                                            </div>
                                        </div>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>


                <div class="col-span-12 md:col-span-4">
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:p-0">
                            <dl>
                                <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                                    <h3 class="text-lg font-semibold">Zendingen</h3>
                                </div>

                                <div class="flex flex-col">
                                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                            <div class="overflow-hidden">
                                                <table class="min-w-full">
                                                    <tbody>
                                                    <tr v-for="shipment in order.shipments" :key="shipment.id" class="border-b border-gray-200 bg-white">
                                                        <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                                            <span v-if="!shipment.error">
                                                                {{ shipment.barcode ?? 'Geen barcode' }}
                                                            </span>
                                                            <span v-if="shipment.error" class="text-red-500">
                                                                {{ shipment.error }}
                                                            </span>
                                                        </td>
                                                        <td class="flex items-center justify-end space-x-1 whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                                            <a
                                                                v-if="can('download shipment')"
                                                                :href="
                                                                    route('shipment.download', {
                                                                        order: order.id,
                                                                        shipment: shipment.id,
                                                                        type: 'label'
                                                                    })
                                                                "
                                                                class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-800 p-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-gray-700 focus:border-gray-900 focus:outline-none active:bg-gray-900"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                    <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
                                                                </svg>
                                                            </a>
                                                            <a
                                                                v-if="can('download shipment')"
                                                                :href="
                                                                    route('shipment.download', {
                                                                        order: order.id,
                                                                        shipment: shipment.id,
                                                                        type: 'packing'
                                                                    })
                                                                "
                                                                class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-800 p-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-gray-700 focus:border-gray-900 focus:outline-none active:bg-gray-900"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                                                                </svg>
                                                            </a>
                                                            <a
                                                                v-if="can('cancel shipment')"
                                                                :href="
                                                                    route('shipment.cancel', {
                                                                        order: order.id,
                                                                        shipment: shipment.id
                                                                    })
                                                                "
                                                                class="focus:shadow-outline-red inline-flex items-center rounded-md border border-transparent bg-red-500 p-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-red-700 focus:border-red-900 focus:outline-none active:bg-red-900"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                </svg>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="py-4 sm:py-5 sm:px-6">
                                    <Button v-if="can('create shipment') && order.status != 'pending'" @click="createShipment = order">Zending aanmaken</Button>
                                    <span v-if="order.status == 'pending'" class="text-sm text-gray-500">
                                    Kan nog geen zending aanmaken, order moet betaald zijn.
                                </span>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>

                <div class="col-span-12">
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:p-0">
                            <dl class="sm:divide-y sm:divide-gray-200">
                                <div class="py-4 sm:py-5 sm:px-6">
                                    <h3 class="text-lg font-semibold">Producten</h3>
                                </div>
                                <div class="w-full md:w-auto">
                                    <table class="w-full divide-y divide-gray-300">
                                        <thead class="bg-gray-50">
                                        <tr class="divide-x divide-gray-200">
                                            <th scope="col" class="w-1/4 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                                            <th scope="col" class="w-1/2 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6">Product</th>
                                            <th scope="col" class="w-1/4 px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Nodig</th>
                                        </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-200">
                                        <tr v-for="item in order.items" :key="item.id" class="divide-x divide-gray-200">
                                            <td class="w-1/4 whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                                                <img v-if="item.product.image" :src="item.product.image" :alt="item.name" class="mr-6 h-30 w-50 rounded object-cover object-center" />
                                            </td>
                                            <td class="w-1/2 whitespace-nowrap py-4 pl-4 pr-4 text-right text-xl font-bold text-gray-900 sm:pl-6">{{ item.name }}</td>
                                            <td class="w-1/4 whitespace-nowrap p-4 text-xl font-bold text-gray-500">{{ item.quantity }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>

            </div>

            <!--            <div class="overflow-hidden bg-white shadow sm:rounded-md">-->
            <!--                <ul role="list" class="divide-y divide-gray-200">-->
            <!--                    <li v-for="(packItem, index) in packItemsFinal" :key="index" class="flex flex-wrap items-center justify-between bg-white px-4 py-4 sm:px-6">-->
            <!--                        <div class="flex w-full md:w-auto">-->
            <!--                            <img v-if="packItem.image" :src="imageSrc(packItem.image)" :alt="packItem.name" class="mr-6 h-16 w-16 rounded object-cover object-center" />-->

            <!--                            <div class="ml-4">-->
            <!--                                <p class="text-sm font-medium text-indigo-600">-->
            <!--                                    {{ packItem.name }}-->
            <!--                                </p>-->
            <!--                                <p class="text-sm font-medium text-gray-500">{{ packItem.quantity }} totaal aantal</p>-->
            <!--                            </div>-->
            <!--                        </div>-->

            <!--                        <div class="w-full md:w-auto">-->
            <!--                            <table class="divide-y divide-gray-300">-->
            <!--                                <thead class="bg-gray-50">-->
            <!--                                    <tr class="divide-x divide-gray-200">-->
            <!--                                        <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"></th>-->
            <!--                                        <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Nodig</th>-->
            <!--                                        <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6">Gescand</th>-->
            <!--                                    </tr>-->
            <!--                                </thead>-->
            <!--                                <tbody class="divide-y divide-gray-200 bg-white">-->
            <!--                                    <tr v-for="neededItems in packItem.neededItems" :key="neededItems['barcode']" class="divide-x divide-gray-200">-->
            <!--                                        <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">-->
            <!--                                            {{ neededItems['barcode'] }}-->
            <!--                                            <span v-if="neededItems['out_of_stock']" class="teext-right block font-bold text-red-500">( Uitverkocht )</span>-->
            <!--                                        </td>-->
            <!--                                        <td class="whitespace-nowrap p-4 text-sm text-gray-500">-->
            <!--                                            {{ neededItems['quantity'] }}-->
            <!--                                        </td>-->
            <!--                                        <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">-->
            <!--                                            {{ neededItems['scanned'] }}-->

            <!--                                            <button type="button" class="ml-4 inline-flex h-6 w-6 items-center justify-center rounded-full border border-transparent bg-red-600 text-lg text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2" @click="undoBarcode(neededItems['barcode'])">-</button>-->
            <!--                                        </td>-->
            <!--                                    </tr>-->
            <!--                                </tbody>-->
            <!--                            </table>-->

            <!--                            <ValidationError class="text-right" :name="'items.' + index + '.scanned'" />-->
            <!--                        </div>-->
            <!--                    </li>-->
            <!--                </ul>-->
            <!--            </div>-->

            <!--            <div class="mt-6 flex items-center">-->
            <!--                <Input id="manualBarcode" v-model="manualBarcode" placeholder="Handmatig scannen" type="text" class="rounded-r-none py-2 text-sm" />-->
            <!--                <Button class="rounded-l-none py-2 text-sm" @click="checkBarcodeManually()">Scan</Button>-->
            <!--            </div>-->

            <!--            <div class="mt-6 flex items-center justify-center space-x-4">-->
            <!--                <button class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-red-600 focus:border-red-900 focus:outline-none active:bg-red-900" @click="openDenyOrder = true">Afwijzen</button>-->

            <!--                <button class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-indigo-500 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-indigo-600 focus:border-indigo-900 focus:outline-none active:bg-indigo-900" @click="openPackOrder = true">Inpakken en terug</button>-->

            <!--                <button v-if="can('create shipment')" class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-green-600 focus:border-green-900 focus:outline-none active:bg-green-900" @click="openPackOrderAndShip = true">Inpakken en versturen</button>-->
            <!--            </div>-->

            <ConfirmationModal :show="openDenyOrder" @close="openDenyOrder = false">
                <template #title>Bestelling afwijzen - {{ order.extern_id }}</template>

                <template #content>
                    Weet je zeker dat je deze bestelling wilt afwijzen?

                    <div class="mt-4 text-left">
                        <Label for="packing_denied_message" value="Reden" />
                        <Textarea id="packing_denied_message" v-model="denyForm.packing_denied_message" class="mt-1 block w-full" />
                        <ValidationError name="packing_denied_message" />
                    </div>
                </template>

                <template #footer>
                    <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="denyOrder()">Bestelling afwijzen</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="openDenyOrder = false">Annuleren</button>
                </template>
            </ConfirmationModal>

            <ConfirmationModal :show="openPackOrder" @close="openPackOrder = false">
                <template #title>Bestelling inpakken - {{ order.extern_id }}</template>

                <template #icon>
                    <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                </template>

                <template #content>Weet je zeker dat je deze bestelling wilt inpakken?</template>

                <template #footer>
                    <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="packOrderAndReturn()">Bestelling inpakken</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="openPackOrder = false">Annuleren</button>
                </template>
            </ConfirmationModal>

            <ConfirmationModal v-if="can('create shipment')" :show="openPackOrderAndShip" @close="openPackOrderAndShip = false">
                <template #title>Bestelling inpakken en versturen - {{ order.extern_id }}</template>

                <template #icon>
                    <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                </template>

                <template #content>
                    <div class="grid grid-cols-12 gap-4 text-left">
                        <div class="col-span-12">Weet je zeker dat je deze bestelling wilt inpakken en versturen?</div>
                        <div class="col-span-6">
                            <Label for="firstname" value="Voornaam *" />
                            <Input id="firstname" v-model="packAndShipForm.firstname" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="firstname" />
                        </div>
                        <div class="col-span-6">
                            <Label for="lastname" value="Achternaam *" />
                            <Input id="lastname" v-model="packAndShipForm.lastname" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="lastname" />
                        </div>
                        <div class="col-span-6">
                            <Label for="email" value="E-mailadres *" />
                            <Input id="email" v-model="packAndShipForm.email" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="email" />
                        </div>
                        <div class="col-span-6">
                            <Label for="phone" value="Telefoonnummer" />
                            <Input id="phone" v-model="packAndShipForm.phone" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="phone" />
                        </div>
                        <div class="col-span-7">
                            <Label for="street_name" value="Straatnaam *" />
                            <Input id="street_name" v-model="packAndShipForm.street_name" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="street_name" />
                        </div>
                        <div class="col-span-3">
                            <Label for="house_number" value="Huisnummer *" />
                            <Input id="house_number" v-model="packAndShipForm.house_number" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="house_number" />
                        </div>
                        <div class="col-span-2">
                            <Label for="house_number_ext" value="Toevoeging" />
                            <Input id="house_number_ext" v-model="packAndShipForm.house_number_ext" type="text" class="mt-1 block w-full" />
                            <ValidationError name="house_number_ext" />
                        </div>
                        <div class="col-span-4">
                            <Label for="postcode" value="Postcode *" />
                            <Input id="postcode" v-model="packAndShipForm.postcode" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="postcode" />
                        </div>

                        <div class="col-span-4">
                            <Label for="city" value="Stad *" />
                            <Input id="city" v-model="packAndShipForm.city" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="city" />
                        </div>

                        <div class="col-span-4">
                            <Label for="country" value="Land *" />
                            <Input id="country" v-model="packAndShipForm.country" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="country" />
                        </div>

                        <div class="col-span-6">
                            <Label for="weight" value="Gewicht *" />
                            <Input id="weight" v-model="packAndShipForm.weight" type="number" class="mt-1 block w-full" required />
                            <ValidationError name="weight" />
                        </div>

                        <div class="col-span-6">
                            <Label for="volume" value="Volume" />
                            <Input id="volume" v-model="packAndShipForm.volume" type="number" class="mt-1 block w-full" />
                            <ValidationError name="volume" />
                        </div>

                        <div class="col-span-4">
                            <Label for="length" value="Lengte" />
                            <Input id="length" v-model="packAndShipForm.length" type="number" class="mt-1 block w-full" />
                            <ValidationError name="length" />
                        </div>
                        <div class="col-span-4">
                            <Label for="height" value="Hoogte" />
                            <Input id="height" v-model="packAndShipForm.height" type="number" class="mt-1 block w-full" />
                            <ValidationError name="height" />
                        </div>

                        <div class="col-span-4">
                            <Label for="width" value="Breedte" />
                            <Input id="width" v-model="packAndShipForm.width" type="number" class="mt-1 block w-full" />
                            <ValidationError name="width" />
                        </div>
                        <div class="col-span-12">
                            <Label for="type" value="Soort zending" />
                            <Select id="type" v-model="packAndShipForm.type" class="mt-1 block w-full">
                                <option value="postnl_package">PostNL - Pakket</option>
                                <option value="postnl_mailbox">PostNL - Brievenbus</option>
                                <option value="trunkrs_sameday">Trunkrs - Sameday</option>
                            </Select>
                            <ValidationError name="type" />
                        </div>
                    </div>
                </template>

                <template #footer>
                    <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="packOrderAndShip()">Bestelling inpakken</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="openPackOrderAndShip = false">Annuleren</button>
                </template>
            </ConfirmationModal>
        </div>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Button from '@/Components/Button.vue'
import NavButton from '@/Components/NavButton.vue'
import { useToast } from 'vue-toastification'
import Input from '@/Components/Input.vue'
import ConfirmationModal from '@/Components/ConfirmationModal.vue'
import { CheckIcon } from '@heroicons/vue/24/outline'
import ValidationError from '@/Components/ValidationError.vue'
import Label from '@/Components/Label.vue'
import Select from '@/Components/Select.vue'
import Textarea from '@/Components/Textarea.vue'

export default {
    components: {
        Textarea,
        Select,
        Label,
        ValidationError,
        NavButton,
        Button,
        AuthenticatedLayout,
        Head,
        Input,
        ConfirmationModal,
        CheckIcon
    },
    props: {
        order: Object,
        packItems: Object
    },
    data() {
        return {
            toast: useToast(),
            manualBarcode: '',

            packItemsFinal: [],

            openPackOrderAndShip: false,
            openPackOrder: false,
            openDenyOrder: false,

            denyForm: this.$inertia.form({
                packing_denied_message: ''
            }),
            packForm: this.$inertia.form({
                items: null
            }),
            packAndShipForm: this.$inertia.form({
                items: null,

                firstname: null,
                lastname: null,
                email: null,
                phone: null,
                street_name: null,
                house_number: null,
                house_number_ext: null,
                postcode: null,
                city: null,
                country: null,

                weight: null,
                height: null,
                volume: null,
                width: null,

                type: 'postnl_package'
            }),
        }
    },
    created() {
        const vm = this

        _.forEach(this.packItems, function (variation) {
            variation.neededItems = vm.whatWeNeed(variation)
        })

        this.packItemsFinal = this.packItems

        window.addEventListener('paste', this.scanBarcodeFromEvent)

        this.packAndShipForm.firstname = this.order ? this.order.shipping_address.firstName : null
        this.packAndShipForm.lastname = this.order ? this.order.shipping_address.lastName : null
        this.packAndShipForm.email = this.order ? this.order.shipping_address.email : null
        this.packAndShipForm.phone = this.order ? this.order.shipping_address.phone : null
        this.packAndShipForm.street_name = this.order ? this.order.splitted_shipping_address.street_name : null
        this.packAndShipForm.house_number = this.order ? this.order.splitted_shipping_address.house_number : null
        this.packAndShipForm.house_number_ext = this.order ? this.order.splitted_shipping_address.house_number_ext : null
        this.packAndShipForm.postcode = this.order ? this.order.shipping_address.postcode : null
        this.packAndShipForm.city = this.order ? this.order.shipping_address.city : null
        this.packAndShipForm.country = this.order ? this.order.shipping_address.country : null
    },

    beforeUnmount() {
        //window.removeEventListener('paste', this.scanBarcodeFromEvent)
    },
    methods: {
        packOrderAndShip() {
            let neededItems = []

            _.forEach(this.packItemsFinal, function (variation) {
                _.forEach(variation.neededItems, function (neededItem) {
                    neededItems.push(neededItem)
                })
            })

            this.packAndShipForm.items = neededItems

            this.packAndShipForm.put(
                route('orders.save-pack-and-ship', {
                    order: this.order.id
                }),
                {
                    onError: (errors) => {
                        if (Object.keys(errors).filter((s) => s.includes('items')).length >= 1) {
                            this.openPackOrderAndShip = false
                        }
                    }
                }
            )
        },

        packOrderAndReturn() {
            let neededItems = []

            _.forEach(this.packItemsFinal, function (variation) {
                _.forEach(variation.neededItems, function (neededItem) {
                    neededItems.push(neededItem)
                })
            })

            this.packForm.items = neededItems

            this.packForm.put(
                route('orders.save-pack', {
                    order: this.order.id
                }),
                {
                    onError: () => {
                        this.openPackOrder = false
                    }
                }
            )
        },

        denyOrder() {
            this.denyForm.put(
                route('orders.deny-pack', {
                    order: this.order.id
                }),
                {
                    onError: () => {
                        this.openDenyOrder = false
                    }
                }
            )
        },

        whatWeNeed(item) {
            let toShare = item.quantity

            let neededVariations = []

            _.forEach(item.variations, function (variation) {
                if ((toShare < variation.numbers_packed || (variation.quantity < 1 && variation.numbers_packed > 1)) && variation.numbers_packed > 1) {
                    return
                }

                let canFit = Math.floor(toShare / variation.numbers_packed)

                if (canFit > variation.quantity) {
                    canFit = variation.quantity
                }

                if (canFit == 0 && variation.numbers_packed == 1) {
                    canFit = toShare
                }

                let outOfStock = variation.quantity < 1 ? true : false

                if (variation.numbers_packed == 1 && variation.quantity < toShare) {
                    outOfStock = true
                }

                toShare -= canFit * variation.numbers_packed
                neededVariations.push({
                    quantity: canFit,
                    barcode: variation.barcode,
                    scanned: 0,
                    out_of_stock: outOfStock
                })
            })

            return neededVariations
        },

        checkBarcodeManually() {
            this.scanBarcode(this.manualBarcode)
            this.manualBarcode = ''
        },
        scanBarcodeFromEvent(event) {
            const target = event.target || event.srcElement

            if (target.tagName.toUpperCase() == 'INPUT') return
            if (target.tagName.toUpperCase() == 'TEXTAREA') return

            this.scanBarcode(event.clipboardData.getData('text'))
        },
        scanBarcode(barcode) {
            const stp = this
            let changed = 0

            _.each(this.packItemsFinal, function (value) {
                _.each(value.neededItems, function (value) {
                    if (value.barcode !== barcode) {
                        return
                    }

                    if (value.scanned >= value.quantity) {
                        stp.toast.warning('Te veel gescand: ' + barcode, {
                            timeout: 1000
                        })
                    } else {
                        stp.toast.success('Gescand: ' + barcode, {
                            timeout: 1000
                        })
                    }

                    changed++

                    value.scanned = (value.scanned ?? 0) + 1
                })
            })

            if (changed === 0) {
                this.toast.error('Barcode niet gevonden: ' + barcode, {
                    timeout: 1000
                })
            }
        },
        undoBarcode(barcode) {
            _.each(this.packItemsFinal, function (value) {
                _.each(value.neededItems, function (value) {
                    if (value.barcode !== barcode) {
                        return
                    }

                    value.scanned = (value.scanned ?? 0) >= 1 ? value.scanned - 1 : 0
                })
            })
        },

        generateNeededItems() {},
        imageSrc(imageName) {
            return `/storage/images/${imageName}`;
        },
    }
}
</script>
