<template>
    <TransitionRoot as="template" :show="ticket != false">
        <Dialog as="div" class="fixed inset-0 z-10 overflow-y-auto" @close="$emit('close')">
            <div class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:p-6 sm:align-middle">
                        <div>
                            <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                                Reactie plaatsen op #{{ ticket.id }}
                            </DialogTitle>

                            <div class="mt-4 grid grid-cols-12 gap-4">
                                <div class="col-span-12">
                                    <vue-editor v-model="form.body"></vue-editor>
                                </div>
                            </div>

                            <div v-if="ticket.status != 'closed' && (can('close all tickets') || ticket.user_id == $page.props.auth.user.id || ticket.assigned_to == $page.props.auth.user.id || ticket.department_id == $page.props.auth.user.department.id)" class="mt-4 block">
                                <label class="flex items-center">
                                    <Checkbox v-model:checked="form.solved" name="remember" />
                                    <span class="ml-2 text-sm text-gray-600">Ticket is met deze reactie opgelost</span>
                                </label>
                            </div>

                            <div class="mt-5 sm:mt-6">
                                <Button class="w-full justify-center" @click="placeReaction">Reactie plaatsen</Button>
                            </div>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import Button from '@/Components/Button.vue'
import { VueEditor } from 'vue3-editor'
import Checkbox from '@/Components/Checkbox.vue'

export default {
    components: {
        Checkbox,
        Button,
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        VueEditor,
        TransitionRoot,
    },
    props: {
        ticket: Object
    },
    emits: ['close'],
    data() {
        return {
            form: this.$inertia.form({
                body : '',
                solved: false
            })
        }
    },
    methods: {
        placeReaction() {
            this.form.post(this.route('tickets.reaction.place', { ticket: this.ticket.id }), {
                preserveScroll: true,
                onSuccess: () => {
                    this.$emit('close')
                    this.form.body = ''
                }
            })
        }
    }
}
</script>
