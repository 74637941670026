<template>
    <span class="px-3 py-1 uppercase text-xs leading-none rounded-sm" :class="color ?? 'bg-gray-100 text-gray-800'">
        {{ text ?? status ?? $t('status.unknown') }}
    </span>
</template>

<script>
export default {
    props: {
        status: String
    },
    computed: {
        text() {
            return {
                open:  this.$t('status.open'),
                completed: this.$t('status.completed'),
                low: this.$t('status.low'),
                normal: this.$t('status.normal'),
                high: this.$t('status.high'),
                closed: this.$t('status.closed'),
            }[this.status.toLowerCase()]
        },
        color() {
            return {
                open: 'bg-blue-500 text-white',
                completed: 'bg-green-100 text-green-800',
                low: 'bg-cyan-400 text-white',
                normal: 'bg-orange-400 text-white',
                high: 'bg-red-600 text-white'
            }[this.status.toLowerCase()]
        }
    }
}
</script>
