<template>
    <Head v-bind:title="$t('customers.customers')" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex items-center justify-between">
                <h1 class="text-2xl font-semibold text-gray-900">
                    {{ $t('customers.customers') }}
                </h1>
                <div>
                    <div class="flex rounded-md shadow-sm">
                        <div class="relative flex flex-grow items-stretch focus-within:z-10">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </div>
                            <input id="search" v-model="searchQuery" type="text" name="search" class="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="" @keyup="search" />
                        </div>
                        <button type="button" class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500" @click="search">{{ $t('customers.search') }}</button>
                    </div>
                </div>
            </div>
        </template>

        <pagination class="mb-2" :links="customers" />

        <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-4 lg:px-8">
                    <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.id') }}</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.name') }}</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.contact_data') }}</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.no_orders') }}</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.spent_amount') }}</th>
                                <th v-if="can('view ticket')"  scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.open_tickets') }}</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.last_order') }}</th>
                                <th scope="col" class="relative px-4 py-2">
                                    <span class="sr-only">{{ $t('customers.view') }}</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="customer in customers.data" :key="customer.id" :class="[customer.tickets.length > 0 ? 'bg-yellow-100' : '', 'even:bg-gray-100']">
                                <td class="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                                    {{ customer.id }}
                                </td>
                                <td class="whitespace-nowrap px-4 py-2">
                                    {{ customer.firstname }}  {{ customer.lastname }}
                                </td>
                                <td class="whitespace-nowrap px-4 py-2">
                                    <div class="text-sm font-medium text-gray-900">
                                        <a :href="'mailto:' + customer.email" class="hover:underline hover:text-indigo-900">{{ customer.email }}</a>
                                    </div>
                                    <div class="text-sm font-medium text-gray-900">
                                        {{ customer.phone }}
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-4 py-2">
                                    <span v-if="customer.orders[0]">
                                        {{ customer.orders[0].total_orders }}
                                    </span>
                                    <span v-else>
                                        0
                                    </span>
                                </td>
                                <td class="whitespace-nowrap px-4 py-2">
                                    <span v-if="customer.orders[0]">
                                       {{ formatPrice(customer.orders[0].total_spend/100) }}
                                    </span>
                                    <span v-else>
                                        {{ formatPrice(0) }}
                                    </span>
                                </td>
                                <td v-if="can('view ticket')" class="whitespace-nowrap px-4 py-2">
                                    <div v-for="ticket in customer.tickets" :key="ticket.priority" class="text-sm font-medium text-gray-900">
                                        <TicketPriorityBadge :status="ticket.priority"><a :href="route('tickets.index', { ticket: ticket.id })" class="hover:underline">#{{ ticket.id }}</a></TicketPriorityBadge>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-4 py-2">
                                    <div v-if="customer.orders[0]">
                                        <a :href="route('orders.show', { order: customer.orders[0].last_order_id })" class="hover:underline hover:text-indigo-900">{{ customer.orders[0].last_order_extern_id }}</a>
                                    </div>
                                </td>
                                <td class="flex flex-col space-y-2 whitespace-nowrap px-4 py-2 text-right text-sm font-medium">
                                    <Link
                                        v-if="can('view customers')"
                                        target="_blank"
                                        :href="
                                                route('customers.show', {
                                                    customer: customer.id
                                                })
                                            "
                                        class="text-indigo-600 hover:text-indigo-900"
                                    >
                                        {{ $t('customers.details') }}
                                    </Link>

                                    <Link
                                        v-if="can('create ticket')"
                                        :href="
                                                route('tickets.create', {
                                                    customer: customer.id
                                                })
                                            "
                                        class="text-indigo-600 hover:text-indigo-900"
                                    >
                                        {{ $t('customers.create_ticket') }}
                                    </Link>

                                </td>
                            </tr>

                            <tr v-if="customers.data.length == 0">
                                <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900" colspan="8">{{ $t('customers.no_results_found') }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <pagination class="mt-2" :links="customers" />
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head, Link } from '@inertiajs/vue3'
import Pagination from '@/Components/Pagination.vue'
import TicketPriorityBadge from "@/Components/TicketPriorityBadge.vue";

export default {
    components: {
        TicketPriorityBadge,
        Pagination,
        AuthenticatedLayout,
        Head,
        Link
    },

    props: {
        customers: Object,
        oldSearchQuery: String,
        currentType: String
    },
    data() {
        return {
            updateOrderStatus: false,
            searchQuery: this.oldSearchQuery
        }
    },

    methods: {
        search() {
            this.$inertia.get(
                route(route().current()),
                { query: this.searchQuery },
                {
                    preserveState: true
                }
            )
        }
    }
}
</script>
