<template>
    <Head :title="$t('orders.edit_order') + ' - ' + order.extern_id" />

    <AuthenticatedLayout>
        <template #header>
            <h1 class="text-2xl font-semibold text-gray-900">{{ $t('orders.edit_order') }} - {{ order.extern_id }}</h1>
        </template>

        <form method="POST" class="overflow-hidden bg-white p-6 shadow-sm sm:rounded-lg" @submit.prevent="saveOrder">
            <div class="grid grid-cols-6 gap-8">
<!--                <div class="col-span-3">-->
<!--                    <Label for="shipping_method" value="Verzendmethode" />-->
<!--                    <Input id="shipping_method" v-model="form.shipping_method" type="text" class="mt-1 block w-full" required />-->
<!--                    <ValidationError name="shipping_method" />-->
<!--                </div>-->

<!--                <div class="col-span-3">-->
<!--                    <Label for="payment_method" value="Betaalmethode" />-->
<!--                    <Input id="payment_method" v-model="form.payment_method" type="text" class="mt-1 block w-full" required />-->
<!--                    <ValidationError name="payment_method" />-->
<!--                </div>-->

                <div class="col-span-3">
                    <div class="grid grid-cols-6 gap-4">
                        <div class="col-span-6">
                            <h3 class="text-lg font-semibold">{{$t('orders.invoice_address') }}</h3>
                        </div>

                        <div class="col-span-6">
                            <Label for="billing_company_name" :value="$t('orders.company_name')" />
                            <Input id="billing_company_name" v-model="form.billing_company_name" type="text" class="mt-1 block w-full" />
                            <ValidationError name="billing_company_name" />
                        </div>

                        <div class="col-span-3">
                            <Label for="billing_firstname">{{ $t('orders.first_name') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="billing_firstname" v-model="form.billing_firstname" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="billing_firstname" />
                        </div>
                        <div class="col-span-3">
                            <Label for="billing_lastname">{{ $t('orders.last_name') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="billing_lastname" v-model="form.billing_lastname" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="billing_lastname" />
                        </div>

                        <div class="col-span-3">
                            <Label for="billing_address">{{ $t('orders.address') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="billing_address" v-model="form.billing_address" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="billing_address" />
                        </div>

                        <div class="col-span-3">
                            <Label for="billing_postcode">{{ $t('orders.postal') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="billing_postcode" v-model="form.billing_postcode" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="billing_postcode" />
                        </div>

                        <div class="col-span-3">
                            <Label for="billing_city">{{ $t('orders.city') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="billing_city" v-model="form.billing_city" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="billing_city" />
                        </div>

                        <div class="col-span-3">
                            <Label for="billing_country">{{ $t('orders.country') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="billing_country" v-model="form.billing_country" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="billing_country" />
                        </div>
                    </div>
                </div>

                <div class="col-span-3">
                    <div class="grid grid-cols-6 gap-4">
                        <div class="col-span-6">
                            <h3 class="text-lg font-semibold">{{ $t('orders.delivery_address') }}</h3>
                        </div>

                        <div class="col-span-6">
                            <Label for="shipping_company_name" :value="$t('orders.company_name')" />
                            <Input id="shipping_company_name" v-model="form.shipping_company_name" type="text" class="mt-1 block w-full" />
                            <ValidationError name="shipping_company_name" />
                        </div>

                        <div class="col-span-3">
                            <Label for="shipping_firstname">{{ $t('orders.first_name') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="shipping_firstname" v-model="form.shipping_firstname" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="shipping_firstname" />
                        </div>
                        <div class="col-span-3">
                            <Label for="shipping_lastname">{{ $t('orders.last_name') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="shipping_lastname" v-model="form.shipping_lastname" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="shipping_lastname" />
                        </div>

                        <div class="col-span-3">
                            <Label for="shipping_address">{{ $t('orders.address') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="shipping_address" v-model="form.shipping_address" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="shipping_address" />
                        </div>

                        <div class="col-span-3">
                            <Label for="shipping_postcode">{{ $t('orders.postal') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="shipping_postcode" v-model="form.shipping_postcode" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="shipping_postcode" />
                        </div>

                        <div class="col-span-3">
                            <Label for="shipping_city">{{ $t('orders.city') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="shipping_city" v-model="form.shipping_city" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="shipping_city" />
                        </div>

                        <div class="col-span-3">
                            <Label for="shipping_country">{{ $t('orders.country') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="shipping_country" v-model="form.shipping_country" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="shipping_country" />
                        </div>
                    </div>
                </div>


                <div class="col-span-6">
                    <Label for="notes" value="Notities" />
                    <Textarea id="notes" v-model="form.notes" class="mt-1 block w-full" />
                    <ValidationError name="notes" />
                </div>

                <div class="col-span-6 space-x-2 text-right">
                    <NavButton :href="route('orders.show', { order: order.id })">{{ $t('orders.back') }}</NavButton>
                    <Button type="submit" :disabled="form.processing">{{ $t('orders.save') }}</Button>
                </div>
            </div>
        </form>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import Button from '@/Components/Button.vue'
import ValidationError from '@/Components/ValidationError.vue'
import NavButton from '@/Components/NavButton.vue'
import Textarea from '@/Components/Textarea.vue'

export default {
    components: {
        Textarea,
        NavButton,
        Button,
        ValidationError,
        Input,
        Label,
        AuthenticatedLayout,
        Head
    },
    props: {
        order: Object
    },
    data() {
        return {
            form: this.$inertia.form({
                _method: 'PUT',
                shipping_method: this.order.shipping_method,
                payment_method: this.order.payment_method,

                external_billing_address_id: this.order.external_billing_address_id,
                billing_company_name: this.order.billing_company_name,
                billing_firstname: this.order.billing_firstname,
                billing_lastname: this.order.billing_lastname,
                billing_address: this.order.billing_address,
                billing_postcode: this.order.billing_postcode,
                billing_city: this.order.billing_city,
                billing_country: this.order.billing_country,

                external_shipping_address_id: this.order.external_shipping_address_id,
                shipping_company_name: this.order.shipping_company_name,
                shipping_firstname: this.order.shipping_firstname,
                shipping_lastname: this.order.shipping_lastname,
                shipping_address: this.order.shipping_address,
                shipping_postcode: this.order.shipping_postcode,
                shipping_city: this.order.shipping_city,
                shipping_country: this.order.shipping_country,


                notes: this.order.notes
            })
        }
    },
    methods: {
        saveOrder() {
            this.form.post(route('orders.update', { order: this.order.id }), {
                preserveScroll: true
            })
        }
    }
}
</script>
