<template>
    <Head :title="'Gebruiker wijzigen - ' + user.name" />

    <AuthenticatedLayout>
        <template #header>
            <h1 class="text-2xl font-semibold text-gray-900">Gebruiker wijzigen - {{ user.name }}</h1>
        </template>

        <form method="POST" class="overflow-hidden bg-white p-6 shadow-sm sm:rounded-lg" @submit.prevent="createStore">
            <div class="grid grid-cols-6 gap-8">
                <div class="col-span-3">
                    <Label for="name" value="Naam" />
                    <Input id="name" v-model="form.name" type="text" class="mt-1 block w-full" required />
                    <ValidationError name="name" />
                </div>
                <div class="col-span-3">
                    <Label for="email" value="E-mailadres" />
                    <Input id="email" v-model="form.email" type="text" class="mt-1 block w-full" required />
                    <ValidationError name="email" />
                </div>
                <div class="col-span-2">
                    <Label for="role" value="Rol" />
                    <select id="role" v-model="form.role" type="text" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                        <option v-for="role in roles" :key="role">
                            {{ role }}
                        </option>
                    </select>
                    <ValidationError name="role" />
                </div>

                <div class="col-span-2">
                    <Label for="department" value="Afdeling" />
                    <select id="department" v-model="form.department" type="text" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                        <option value="">Maak een keuze</option>
                        <option v-for="department in departments" :key="department.id" :value="department.id">
                            {{ department.name }}
                        </option>
                    </select>
                    <ValidationError name="department" />
                </div>

                <div class="col-span-2">
                    <Label for="status" value="Status" />
                    <select id="status" v-model="form.status" type="text" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                        <option value="1">Actief</option>
                        <option value="0">Inactief</option>
                    </select>
                    <ValidationError name="status" />
                </div>
                <div class="col-span-6 space-x-2 text-right">
                    <NavButton :href="route('users.show', { user: user.id })">Terug</NavButton>
                    <Button type="submit" :disabled="form.processing">Opslaan</Button>
                </div>
            </div>
        </form>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import Button from '@/Components/Button.vue'
import ValidationError from '@/Components/ValidationError.vue'
import NavButton from '@/Components/NavButton.vue'

export default {
    components: {
        NavButton,
        Button,
        ValidationError,
        Input,
        Label,
        AuthenticatedLayout,
        Head
    },
    props: {
        user: Object,
        roles: Array,
        departments: Array,
    },
    data() {
        return {
            form: this.$inertia.form({
                _method: 'PUT',
                name: this.user.name,
                email: this.user.email,
                role: this.user.role,
                department: this.user.department_id,
                status: this.user.status
            })
        }
    },
    methods: {
        createStore() {
            this.form.post(route('users.update', { user: this.user.id }), {
                preserveScroll: true
            })
        }
    }
}
</script>
