<template>
    <Head :title="'Product wijzigen - ' + store.name" />

    <AuthenticatedLayout>
        <template #header>
            <h1 class="text-2xl font-semibold text-gray-900">{{ $t('stores.product') }} {{ $t('stores.edit') }} - {{ product.name }}</h1>
        </template>

        <form method="POST" class="overflow-hidden bg-white p-6 shadow-sm sm:rounded-lg" @submit.prevent="createStore">
            <div class="grid grid-cols-6 gap-8">
                <div class="col-span-6">
                    <Label for="name" v-bind:value="$t('stores.name')" />
                    <Input id="name" v-model="form.name" type="text" class="mt-1 block w-full" required />
                    <ValidationError name="name" />
                </div>

                <div v-if="product.image" class="col-span-6">
                    <img :src="'/storage/' + product.image" :alt="product.name" class="mr-6 h-32 w-32 rounded object-cover object-center" />
                </div>

                <div class="col-span-6">
                    <Label for="image" v-bind:value="$t('stores.file')" />
                    <Input id="image" type="file" class="mt-1" accept="image/*" @change="onFileChange" />
                    <ValidationError name="image" />
                </div>
                <hr class="col-span-6" />
                <div class="col-span-6 space-x-2 text-right">
                    <NavButton :href="route('stores.show', { store: store.id })">{{ $t('stores.save') }}</NavButton>
                    <Button type="submit">{{ $t('stores.back') }}</Button>
                </div>
            </div>
        </form>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import Button from '@/Components/Button.vue'
import ValidationError from '@/Components/ValidationError.vue'
import NavButton from '@/Components/NavButton.vue'

export default {
    components: {
        NavButton,
        Button,
        ValidationError,
        Input,
        Label,
        AuthenticatedLayout,
        Head
    },
    props: {
        store: Object,
        product: Object,
        users: Object
    },
    data() {
        return {
            form: this.$inertia.form({
                _method: 'PUT',
                name: this.product.name,
                image: this.product.image,
                cost_price: this.product.cost_price ?? 0,
                productUsers: this.product.users,
                extraCosts: this.product.extraCosts
            })
        }
    },
    methods: {
        createStore() {
            this.form.post(
                route('products.update', {
                    store: this.store.id,
                    product: this.product.id
                }),
                {
                    preserveScroll: true
                }
            )
        },
        addUser() {
            this.form.productUsers.push({
                user_id: '',
                profit_sharing_type: 'Fixed',
                profit_sharing_value: 2
            })
        },
        deleteUser(index) {
            this.form.productUsers.splice(index, 1)
        },
        addExtraCost() {
            this.form.extraCosts.push({
                name: '',
                price: 5
            })
        },
        deleteExtraCost(index) {
            this.form.extraCosts.splice(index, 1)
        },
        onFileChange(event) {
            this.form.image = event.target.files[0];
        },
    }
}
</script>
