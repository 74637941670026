<template>
    <Head title="Wachtwoord bevestigen" />

    <div class="mb-4 text-sm text-gray-600">{{ $t('auth.notice_confirm') }}</div>

    <ValidationErrors class="mb-4" />

    <form @submit.prevent="submit">
        <div>
            <Label for="password" value="Wachtwoord" />
            <Input id="password" v-model="form.password" type="password" class="mt-1 block w-full" required autocomplete="current-password" autofocus />
        </div>

        <div class="mt-4 flex justify-end">
            <Button class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">{{ $t('auth.confirm') }}</Button>
        </div>
    </form>
</template>

<script>
import Button from '@/Components/Button.vue'
import GuestLayout from '@/Layouts/Guest.vue'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import ValidationErrors from '@/Components/ValidationErrors.vue'
import { Head } from '@inertiajs/vue3'

export default {
    components: {
        Button,
        Input,
        Label,
        ValidationErrors,
        Head
    },
    layout: GuestLayout,

    data() {
        return {
            form: this.$inertia.form({
                password: ''
            })
        }
    },

    methods: {
        submit() {
            this.form.post(this.route('password.confirm'), {
                onFinish: () => this.form.reset()
            })
        }
    }
}
</script>
