<template>
    <Head :title="title" />

    <AuthenticatedLayout>
        <div class="py-32 md:py-64">
            <div class="text-center">
                <svg v-if="status === 500" xmlns="http://www.w3.org/2000/svg" class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414" />
                </svg>

                <svg v-if="status === 404" xmlns="http://www.w3.org/2000/svg" class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>

                <svg v-if="status === 403" xmlns="http://www.w3.org/2000/svg" class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>

                <h3 class="mt-2 text-lg font-semibold text-gray-900">
                    {{ title }}
                </h3>
                <p class="mx-auto mt-1 max-w-md text-base text-gray-500">
                    {{ description }}
                </p>
            </div>
        </div>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'

export default {
    components: {
        AuthenticatedLayout,
        Head
    },
    props: {
        status: Number
    },
    computed: {
        title() {
            return {
                500: '500: Serverfoutmelding',
                404: '404: Pagina niet gevonden',
                403: '403: Geen toegang'
            }[this.status]
        },
        description() {
            return {
                500: 'Oeps... Er is iets fout gegaan op onze servers.',
                404: 'Sorry, de pagina die u zoekt kon niet worden gevonden.',
                403: 'Sorry, u bent niet geautoriseerd om deze pagina te openen.'
            }[this.status]
        }
    }
}
</script>
