<template>
    <Head :title="$t('shipments.notice_create')" />

    <AuthenticatedLayout>
        <template #header>
            <h1 class="text-2xl font-semibold text-gray-900">{{ $t('shipments.notice_create') }}</h1>
        </template>

        <form method="POST" class="overflow-hidden bg-white p-6 shadow-sm sm:rounded-lg" @submit.prevent="createIncomingShipment">
            <div class="grid grid-cols-6 gap-8">
                <div class="col-span-6">
                    <Label for="reference" value="Referentie" />
                    <Input id="reference" v-model="form.reference" type="text" class="mt-1 block w-full" />
                    <ValidationError name="reference" />
                </div>

                <div class="col-span-6">
                    <Label for="notes" value="Notes" />
                    <Textarea id="notes" v-model="form.notes" class="mt-1 block w-full" />
                    <ValidationError name="notes" />
                </div>

                <hr class="col-span-6" />

                <div class="col-span-6">
                    <div v-for="(product, index) in form.products" :key="index" class="grid grid-cols-9 gap-4 py-2">
                        <div class="col-span-3">
                            <Label :for="'product-' + index + '-barcode'" value="Product variatie" />
                            <Select :id="'product-' + index + '-barcode'" v-model="product.barcode" class="mt-1 block w-full">
                                <option value="">Kies een product</option>
                                <optgroup v-for="storeProduct in store.products" :key="storeProduct.id" :label="storeProduct.name">
                                    <option v-for="variation in storeProduct.variations" :key="variation.barcode" :value="variation.barcode" :selected="form.products[index]['barcode'] === variation.barcode">
                                        {{ variation.numbers_packed }} {{ $t('shipments.packed') }} - {{ $t('shipments.barcode') }}:
                                        {{ variation.barcode ?? $t('shipments.unknown') }}
                                    </option>
                                </optgroup>
                            </Select>
                            <ValidationError :name="'products.' + index + '.barcode'" />
                        </div>
                        <div class="col-span-3">
                            <Label :for="'product-' + index + '-intern_reference'" :value="$t('shipments.reference_internal')" />
                            <Input :id="'product-' + index + '-intern_reference'" v-model="product.intern_reference" type="text" class="mt-1 block w-full" />
                            <ValidationError :name="'products.' + index + '.intern_reference'" />
                        </div>

                        <div class="col-span-3">
                            <Label :for="'product-' + index + '-expected_quantity'" :value="$t('shipments.amount_expected')" />

                            <div class="flex">
                                <Input :id="'product-' + index + '-expected_quantity'" v-model="product.expected_quantity" type="number" class="mt-1 block flex-1" />
                                <Button class="ml-1" type="button" @click="deleteProduct(index)">X</Button>
                            </div>
                            <ValidationError :name="'products.' + index + '.expected_quantity'" />
                        </div>
                    </div>

                    <Button type="button" @click="addProduct">{{ $t('shipments.add_product') }}</Button>
                    <ValidationError name="products" />
                </div>

                <hr class="col-span-6" />

                <div class="col-span-6 space-x-2 text-right">
                    <NavButton :href="route('incoming-shipments.index')">{{ $t('shipments.back') }}</NavButton>
                    <Button type="submit">{{ $t('shipments.save') }}</Button>
                </div>
            </div>
        </form>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Select from '@/Components/Select.vue'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import Button from '@/Components/Button.vue'
import ValidationError from '@/Components/ValidationError.vue'
import NavButton from '@/Components/NavButton.vue'
import Textarea from '@/Components/Textarea.vue'

export default {
    components: {
        Textarea,
        NavButton,
        Button,
        ValidationError,
        Input,
        Select,
        Label,
        AuthenticatedLayout,
        Head
    },
    props: {
        store: Object
    },
    data() {
        return {
            form: this.$inertia.form({
                reference: '',
                notes: '',
                products: []
            })
        }
    },
    methods: {
        createIncomingShipment() {
            this.form.post(route('incoming-shipments.store'), {
                preserveScroll: true
            })
        },
        addProduct() {
            this.form.products.push({
                barcode: null,
                intern_reference: '',
                expected_quantity: 1
            })
        },
        deleteProduct(index) {
            this.form.products.splice(index, 1)
        }
    }
}
</script>
