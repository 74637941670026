<template>
    <span class="inline-flex rounded-full px-2 text-xs font-semibold leading-5" :class="color ?? 'bg-gray-100 text-gray-800'">
        {{ text ?? status ?? $t('status.unknown') }}
    </span>
</template>

<script>
export default {
    props: {
        status: String
    },
    computed: {
        text() {
            return {
                completed: this.$t('status.completed'),
                active: this.$t('status.active'),
                inactive: this.$t('status.inactive'),
                announced: this.$t('status.announced'),
                accepted: this.$t('status.accepted'),
                denied: this.$t('status.denied'),
                pending: this.$t('status.pending'),
                'on-hold' : this.$t('status.on-hold'),
                processing: this.$t('status.processing'),
                cancelled: this.$t('status.cancelled'),
                'for inspection': this.$t('status.for inspection'),
                online: this.$t('status.online'),
                offline: this.$t('status.offline'),
                new: this.$t('status.new'),
                approved: this.$t('status.approved'),
                paid: this.$t('status.paid'),
                rejected: this.$t('status.rejected')
            }[this.status.toLowerCase()]
        },
        color() {
            return {
                completed: 'bg-green-100 text-green-800',
                active: 'bg-green-100 text-green-800',
                inactive: 'bg-red-100 text-red-800',
                announced: 'bg-indigo-100 text-indigo-800',
                accepted: 'bg-green-100 text-green-800',
                denied: 'bg-red-100 text-red-800',
                pending: 'bg-gray-100 text-gray-800',
                'on-hold': 'bg-gray-300 text-gray-800',
                processing: 'bg-indigo-100 text-indigo-800',
                cancelled: 'bg-red-100 text-red-800',
                'for inspection': 'bg-orange-100 text-orange-800',
                online: 'bg-green-100 text-green-800',
                offline: 'bg-red-100 text-red-800',
                new: 'bg-gray-100 text-gray-800',
                approved:'bg-indigo-100 text-indigo-800',
                paid:'bg-green-100 text-green-800',
                rejected:'bg-red-100 text-red-800'
            }[this.status.toLowerCase()]
        }
    }
}
</script>
