<template>
    <div>
        <div class="relative rounded-md shadow-sm">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span class="text-gray-500 sm:text-sm">€</span>
            </div>
            <input id="price" ref="inputRef" type="text" name="price" class="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm" placeholder="0.00" aria-describedby="price-currency" />
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span id="price-currency" class="text-gray-500 sm:text-sm">EUR</span>
            </div>
        </div>
    </div>
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'

export default {
    name: 'CurrencyInput',
    props: {
        modelValue: Number,
        includeTax: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const { inputRef } = useCurrencyInput({
            currency: 'EUR',
            locale: 'nl-NL',
            currencyDisplay: 'hidden'
        })

        return { inputRef }
    }
}
</script>
