<template>
    <div>
        <TransitionRoot as="template" :show="sidebarOpen">
            <Dialog as="div" class="fixed inset-0 z-40 flex md:hidden" @close="sidebarOpen = false">
                <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </TransitionChild>
                <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
                    <div class="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800 pt-5 pb-4">
                        <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                            <div class="absolute top-0 right-0 -mr-12 pt-2">
                                <button type="button" class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                                    <span class="sr-only">Close sidebar</span>
                                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                                </button>
                            </div>
                        </TransitionChild>
                        <div class="px-4">
                            <Link :href="route('dashboard')" class="flex w-full justify-center" @click="removeLocks">
                                <h1 class="text-3xl font-semibold text-white">Backoffice</h1>
                            </Link>
                            <div class="mt-2">
                                <select v-model="activeStoreId" class="block w-full w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" @change="changeActiveStore">
                                    <option :value="null">{{ $t('dashboard.no_shop_chosen') }}</option>
                                    <option v-for="store in $page.props.layout.stores" :key="store.id" :value="can('access all stores') ? store.id : store.store_id">
                                        {{ store.name }}
                                    </option>
                                </select>
                            </div>

                            <Link v-if="can('view default report') && $page.props.layout.active_store" :href="route('report.index')" class="focus:shadow-outline-gray mt-4 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-gray-700 focus:border-gray-900 focus:outline-none active:bg-gray-900" @click="removeLocks">Rapporten</Link>
                            <Link v-if="can('view all incoming shipments') && $page.props.layout.active_store" :href="route('incoming-shipments.index')" class="focus:shadow-outline-gray mt-4 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-gray-700 focus:border-gray-900 focus:outline-none active:bg-gray-900" @click="removeLocks">Inkomende zendingen</Link>

                            <div class="mt-2 flex flex-1 items-center space-x-2">
                                <span class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-green-100">Backoffice</span>
                                <span v-if="$page.props.layout.active_store" :class="[$page.props.layout.active_store.status === 'Online' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100', 'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium']" >
                                    {{ $page.props.layout.active_store.name }}
                                </span>
                            </div>
                        </div>
                        <div v-if="$page.props.layout.active_store" class="mt-5 h-0 flex-1 overflow-y-auto">
                            <nav class="space-y-1">
                                <Link v-if="can('view all orders')" :href="route('orders.index')" :class="[route().current('orders.*') && route().params.type == undefined ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']" @click="removeLocks">
                                    {{ $t('dashboard.all_orders') }}
                                    <span class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-green-100">
                                        {{ $page.props.layout.statistics['all_orders'] }}
                                    </span>
                                </Link>

                                <template v-if="can('view all orders')">
                                    <Link
                                        v-for="shippingMethod in $page.props.layout.active_store.shipping_methods"
                                        :key="shippingMethod.id"
                                        :href="
                                            route('orders.index', {
                                                type: shippingMethod.bagisto_name
                                            })
                                        "
                                        :class="[route().current('orders.*') && route().params.type == shippingMethod.bagisto_name ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']"
                                        @click="removeLocks"
                                    >
                                        {{ shippingMethod.name }}
                                        <span class="inline-flex items-center rounded-full bg-blue-500 px-2.5 py-0.5 text-xs font-medium text-blue-100">
                                            {{ $page.props.layout.statistics[shippingMethod.bagisto_name] }}
                                        </span>
                                    </Link>
                                </template>

                                <!--                                <Link-->
                                <!--                                    v-if="can('view all orders')"-->
                                <!--                                    :href="-->
                                <!--                                        route('orders.index', {-->
                                <!--                                            type: 'pending'-->
                                <!--                                        })-->
                                <!--                                    "-->
                                <!--                                    :class="[route().current('orders.*') && route().params.type == 'for_inspection' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']"-->
                                <!--                                >-->
                                <!--                                    Wachten op betaling -->
                                <!--                                    <span class="inline-flex items-center rounded-full bg-blue-500 px-2.5 py-0.5 text-xs font-medium text-blue-100">-->
                                <!--                                        {{ $page.props.layout.statistics['pending'] }}-->
                                <!--                                    </span>-->
                                <!--                                </Link>-->

                                <Link
                                    v-if="can('view all orders')"
                                    :href="
                                        route('orders.index', {
                                            type: 'for_inspection'
                                        })
                                    "
                                    :class="[route().current('orders.*') && route().params.type == 'for_inspection' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']"
                                    @click="removeLocks"
                                >
                                    {{ $t('dashboard.to_control') }}
                                    <span class="inline-flex items-center rounded-full bg-orange-500 px-2.5 py-0.5 text-xs font-medium text-orange-100">
                                        {{ $page.props.layout.statistics['for_inspection'] }}
                                    </span>
                                </Link>

                                <Link
                                    v-if="can('view all orders')"
                                    :href="
                                        route('orders.index', {
                                            type: 'completed'
                                        })
                                    "
                                    :class="[route().current('orders.*') && route().params.type == 'completed' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']"
                                    @click="removeLocks"
                                >
                                    {{ $t('dashboard.processed') }}
                                    <span class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-green-100">
                                        {{ $page.props.layout.statistics['completed'] }}
                                    </span>
                                </Link>
                                <Link
                                    v-if="can('view all orders')"
                                    :href="
                                        route('orders.index', {
                                            type: 'cancelled'
                                        })
                                    "
                                    :class="[route().current('orders.*') && route().params.type == 'cancelled' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']"
                                    @click="removeLocks"
                                >
                                    {{ $t('dashboard.canceled') }}
                                    <span class="inline-flex items-center rounded-full bg-red-500 px-2.5 py-0.5 text-xs font-medium text-red-100">
                                        {{ $page.props.layout.statistics['canceled'] }}
                                    </span>
                                </Link>

                                <span v-if="can('view all credit payment')" class="block w-full bg-gray-900 px-2 py-1 text-sm text-white"> Crediting</span>
                                <Link v-if="can('view all credit payment')" :href="route('credit-payment.index')" :class="[route().current('credit-payment.index') && route().params.type == undefined ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']" @click="removeLocks">
                                    {{ $t('dashboard.all_credit_payments') }}
                                    <span class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-green-100">
                                        {{ $page.props.layout.statistics['all_credit_payments'] }}
                                    </span>
                                </Link>

                                <span v-if="can('view all retours')" class="block w-full bg-gray-900 px-2 py-1 text-sm text-white">Retours</span>
                                <Link v-if="can('view all retours')" :href="route('retours.index')" :class="[route().current('retours.*') && route().params.type == undefined ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']" @click="removeLocks">
                                    {{ $t('dashboard.all_returns') }}
                                    <span class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-green-100">
                                        {{ $page.props.layout.statistics['all_retours'] }}
                                    </span>
                                </Link>

                                <Link
                                    v-if="can('view all retours')"
                                    :href="
                                        route('retours.index', {
                                            type: 'announced'
                                        })
                                    "
                                    :class="[route().current('retours.*') && route().params.type == 'announced' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']"
                                    @click="removeLocks"
                                >
                                    {{ $t('dashboard.logged_on') }}
                                    <span class="inline-flex items-center rounded-full bg-indigo-500 px-2.5 py-0.5 text-xs font-medium text-indigo-100">
                                        {{ $page.props.layout.statistics['announced_retours'] }}
                                    </span>
                                </Link>
                                <Link
                                    v-if="can('view all retours')"
                                    :href="
                                        route('retours.index', {
                                            type: 'completed'
                                        })
                                    "
                                    :class="[route().current('retours.*') && route().params.type == 'announced' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']"
                                    @click="removeLocks"
                                >
                                    {{ $t('dashboard.processed') }}
                                    <span class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-green-100">
                                        {{ $page.props.layout.statistics['completed_retours'] }}
                                    </span>
                                </Link>

                                <template v-for="paymentMethod in $page.props.layout.active_store.payment_methods" :key="paymentMethod.id">
                                    <span v-if="can('view all orders')" class="block w-full bg-gray-900 px-2 py-1 text-sm text-white">
                                        {{ paymentMethod.name }}
                                    </span>
                                    <Link
                                        v-if="can('view all orders')"
                                        :href="
                                            route('orders.index', {
                                                type: paymentMethod.bagisto_name + '_open'
                                            })
                                        "
                                        :class="[route().current('orders.*') && route().params.type == paymentMethod.bagisto_name + '_open' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']"
                                        @click="removeLocks"
                                    >
                                        {{ $t('dashboard.open') }}
                                        <span class="inline-flex items-center rounded-full bg-indigo-500 px-2.5 py-0.5 text-xs font-medium text-indigo-100">
                                            {{ $page.props.layout.statistics[paymentMethod.bagisto_name + '_open'] }}
                                        </span>
                                    </Link>
                                    <Link
                                        v-if="can('view all orders')"
                                        :href="
                                            route('orders.index', {
                                                type: paymentMethod.bagisto_name + '_handled'
                                            })
                                        "
                                        :class="[route().current('orders.*') && route().params.type == paymentMethod.bagisto_name + '_handled' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']"
                                        @click="removeLocks"
                                    >
                                        {{ $t('dashboard.processed') }}
                                        <span class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-green-100">
                                            {{ $page.props.layout.statistics[paymentMethod.bagisto_name + '_handled'] }}
                                        </span>
                                    </Link>
                                </template>

                                <!--                                <span v-if="can('view all retours')" class="block w-full bg-gray-900 px-2 py-1 text-sm text-white">Klanten</span>-->
                                <!--                                <Link v-if="can('view all retours')" :href="route('retours.index')" :class="[route().current('retours.*') && route().params.type == undefined ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']">-->
                                <!--                                    Alle retours-->
                                <!--                                    <span class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-green-100">-->
                                <!--                                        {{ $page.props.layout.statistics['customers'] }}-->
                                <!--                                    </span>-->
                                <!--                                </Link>-->
                            </nav>
                        </div>
                    </div>
                </TransitionChild>
                <div class="w-14 shrink-0" aria-hidden="true">
                    <!-- Dummy element to force sidebar to shrink to fit close icon -->
                </div>
            </Dialog>
        </TransitionRoot>

        <!-- Static sidebar for desktop -->
        <div class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex min-h-0 flex-1 flex-col bg-gray-800">
                <div class="bg-gray-900 px-4 py-4">
                    <Link :href="route('dashboard')" class="flex w-full justify-center">
                        <h1 class="text-3xl font-semibold text-white">Backoffice</h1>
                    </Link>

                    <div class="mt-2">
                        <select v-model="activeStoreId" class="block w-full w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" @change="changeActiveStore">
                            <option :value="null">{{ $t('dashboard.no_shop_chosen') }}</option>
                            <option v-for="store in $page.props.layout.stores" :key="store.id" :value="can('access all stores') ? store.id : store.store_id">
                                {{ store.name }}
                            </option>
                        </select>

                        <Link v-if="can('view default report') && $page.props.layout.active_store" :href="route('report.index')" class="focus:shadow-outline-gray mt-4 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-gray-700 focus:border-gray-900 focus:outline-none active:bg-gray-900" @click="removeLocks">Rapporten</Link>
                        <Link v-if="can('view all incoming shipments') && $page.props.layout.active_store" :href="route('incoming-shipments.index')" class="focus:shadow-outline-gray mt-4 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-gray-700 focus:border-gray-900 focus:outline-none active:bg-gray-900" @click="removeLocks">Inkomende zendingen</Link>
                    </div>
                </div>
                <div class="flex flex-1 flex-col overflow-y-auto">
                    <nav v-if="$page.props.layout.active_store" class="flex-1 space-y-1 pt-1">
                        <span  v-if="can('view all orders') || can('view inspection orders')" class="block w-full bg-gray-900 px-2 py-1 text-sm text-white">{{ $t('dashboard.orders') }}</span>

                        <Link v-if="can('view all orders')" :href="route('orders.index')" :class="[route().current('orders.*') && route().params.type == undefined ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']" @click="removeLocks">
                            {{ $t('menu.all_orders') }}
                            <span class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-green-100">
                                {{ $page.props.layout.statistics['all_orders'] }}
                            </span>
                        </Link>

                        <template v-if="can('view all orders')">
                            <Link
                                v-for="shippingMethod in $page.props.layout.active_store.shipping_methods"
                                :key="shippingMethod.id"
                                :href="
                                    route('orders.index', {
                                        type: shippingMethod.bagisto_name
                                    })
                                "
                                :class="[route().current('orders.*') && route().params.type == shippingMethod.bagisto_name ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']"
                                @click="removeLocks"
                            >
                                {{ shippingMethod.name }}
                                <span class="inline-flex items-center rounded-full bg-blue-500 px-2.5 py-0.5 text-xs font-medium text-blue-100">
                                    {{ $page.props.layout.statistics[shippingMethod.bagisto_name] }}
                                </span>
                            </Link>
                        </template>

                        <!--                        <Link v-if="can('view all orders')" :href="route('orders.index', { type: 'pending' })" :class="[route().current('orders.*') && route().params.type == 'for_inspection' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']">-->
                        <!--                            Wachten op betaling-->
                        <!--                            <span class="inline-flex items-center rounded-full bg-blue-500 px-2.5 py-0.5 text-xs font-medium text-blue-100">-->
                        <!--                                {{ $page.props.layout.statistics['pending'] }}-->
                        <!--                            </span>-->
                        <!--                        </Link>-->

                        <Link
                            v-if="can('view all orders') || can('view inspection orders')"
                            :href="
                                route('orders.index', {
                                    type: 'for_inspection'
                                })
                            "
                            :class="[route().current('orders.*') && route().params.type == 'for_inspection' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']"
                            @click="removeLocks"
                        >
                            Ter controle
                            <span class="inline-flex items-center rounded-full bg-orange-500 px-2.5 py-0.5 text-xs font-medium text-orange-100">
                                {{ $page.props.layout.statistics['for_inspection'] }}
                            </span>
                        </Link>

                        <Link v-if="can('view all orders')" :href="route('orders.index', { type: 'completed' })" :class="[route().current('orders.*') && route().params.type == 'completed' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']" @click="removeLocks">
                            {{ $t('dashboard.processed') }}
                            <span class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-green-100">
                                {{ $page.props.layout.statistics['completed'] }}
                            </span>
                        </Link>
                        <Link v-if="can('view all orders')" :href="route('orders.index', { type: 'cancelled' })" :class="[route().current('orders.*') && route().params.type == 'cancelled' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']" @click="removeLocks">
                            {{ $t('dashboard.canceled') }}
                            <span class="inline-flex items-center rounded-full bg-red-500 px-2.5 py-0.5 text-xs font-medium text-red-100">
                                {{ $page.props.layout.statistics['cancelled'] }}
                            </span>
                        </Link>

                        <span v-if="can('pack order')" class="block w-full bg-gray-900 px-2 py-1 text-sm text-white">{{ $t('dashboard.packing_up') }}</span>
                        <template v-if="can('pack order')">
                            <Link
                                v-for="shippingMethod in $page.props.layout.active_store.shipping_methods"
                                :key="shippingMethod.id"
                                :href="
                                    route('pack.index', {
                                        type: shippingMethod.bagisto_name
                                    })
                                "
                                :class="[route().current('orders.*') && route().params.type == shippingMethod.bagisto_name ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']"
                                @click="removeLocks"
                            >
                                {{ shippingMethod.name }}
                                <span class="inline-flex items-center rounded-full bg-blue-500 px-2.5 py-0.5 text-xs font-medium text-blue-100">
                                    {{ $page.props.layout.statistics[shippingMethod.bagisto_name] }}
                                </span>
                            </Link>



                            <Link
                                v-if="can('view limited completed orders')"
                                :href="
                                route('orders.limitedCompletedOrders', {})
                            "
                                :class="[route().current('orders.*') && route().params.type == undefined ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']"
                                @click="removeLocks"
                            >
                                {{ $t('dashboard.processed') }}
                                <span class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-green-100">
                                ?
                                </span>
                            </Link>
                        </template>

                        <span v-if="can('view all credit payment')" class="block w-full bg-gray-900 px-2 py-1 text-sm text-white">{{ $t('dashboard.crediting') }}</span>

                        <Link v-if="can('view all credit payment')" :href="route('credit-payment.index')" :class="[route().current('credit-payment.index') && route().params.type == undefined ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']" @click="removeLocks">
                            {{ $t('dashboard.all_credit_payments') }}
                            <span class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-green-100">
                                {{ $page.props.layout.statistics['all_credit_payments'] }}
                            </span>
                        </Link>
                        <span v-if="can('view all retours')" class="block w-full bg-gray-900 px-2 py-1 text-sm text-white">Retours</span>
                        <Link v-if="can('view all retours')" :href="route('retours.index')" :class="[route().current('retours.*') && route().params.type == undefined ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']" @click="removeLocks">
                            {{ $t('dashboard.all_returns') }}
                            <span class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-green-100">
                                {{ $page.props.layout.statistics['all_retours'] }}
                            </span>
                        </Link>
                        <Link v-if="can('view all retours')" :href="route('retours.index', { type: 'announced' })" :class="[route().current('retours.*') && route().params.type == 'announced' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']" @click="removeLocks">
                            {{ $t('dashboard.logged_on') }}
                            <span class="inline-flex items-center rounded-full bg-indigo-500 px-2.5 py-0.5 text-xs font-medium text-indigo-100">
                                {{ $page.props.layout.statistics['announced_retours'] }}
                            </span>
                        </Link>
                        <Link v-if="can('view all retours')" :href="route('retours.index', { type: 'completed' })" :class="[route().current('retours.*') && route().params.type == 'completed' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']" @click="removeLocks">
                            {{ $t('dashboard.processed') }}
                            <span class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-green-100">
                                {{ $page.props.layout.statistics['completed_retours'] }}
                            </span>
                        </Link>

                        <template v-for="paymentMethod in $page.props.layout.active_store.payment_methods" :key="paymentMethod.id">
                            <span v-if="can('view all orders')" class="block w-full bg-gray-900 px-2 py-1 text-sm text-white">
                                {{ paymentMethod.name }}
                            </span>
                            <Link
                                v-if="can('view all orders')"
                                :href="
                                    route('orders.index', {
                                        type: paymentMethod.bagisto_name + '_open'
                                    })
                                "
                                :class="[route().current('orders.*') && route().params.type == paymentMethod.bagisto_name + '_open' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']"
                                @click="removeLocks"
                            >
                                {{ $t('dashboard.open') }}
                                <span class="inline-flex items-center rounded-full bg-indigo-500 px-2.5 py-0.5 text-xs font-medium text-indigo-100">
                                    {{ $page.props.layout.statistics[paymentMethod.bagisto_name + '_open'] }}
                                </span>
                            </Link>
                            <Link
                                v-if="can('view all orders')"
                                :href="
                                    route('orders.index', {
                                        type: paymentMethod.bagisto_name + '_handled'
                                    })
                                "
                                :class="[route().current('orders.*') && route().params.type == paymentMethod.bagisto_name + '_handled' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']"
                                @click="removeLocks"
                            >
                                {{ $t('dashboard.processed') }}
                                <span class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-green-100">
                                    {{ $page.props.layout.statistics[paymentMethod.bagisto_name + '_handled'] }}
                                </span>
                            </Link>
                            <span v-if="can('view all retours')" class="block w-full bg-gray-900 px-2 py-1 text-sm text-white">{{ $t('dashboard.tickets') }}</span>

                            <Link v-if="can('view all retours')" :href="route('retours.index')" :class="[route().current('retours.*') && route().params.type == undefined ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']" @click="removeLocks">
                                {{ $t('dashboard.all_returns') }}
                                <span class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-green-100">
                                {{ $page.props.layout.statistics['all_retours'] }}
                            </span>
                            </Link>
                        </template>

                        <span v-if="can('view customers')" class="block w-full bg-gray-900 px-2 py-1 text-sm text-white">{{ $t('dashboard.customers') }}</span>
                        <Link v-if="can('view customers')" :href="route('customers.index')" :class="[route().current('customers.*') && route().params.type == undefined ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']" @click="removeLocks">
                            {{ $t('dashboard.customers') }}
                        </Link>

                        <span v-if="can('view open transactions') || can('view connected transactions')" class="block w-full bg-gray-900 px-2 py-1 text-sm text-white">Transacties</span>
                        <Link v-if="can('view open transactions')" :href="route('transactions.notconnected')" :class="[route().current('transactions.notconnected') && route().params.type == undefined ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']" @click="removeLocks">
                            {{ $t('dashboard.inspection_needed') }}
                            <span class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-green-100">
                                {{ $page.props.layout.statistics['inspection_transactions'] }}
                            </span>
                        </Link>

                        <Link v-if="can('view connected transactions')" :href="route('transactions.connected')" :class="[route().current('transactions.connected') && route().params.type == undefined ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']" @click="removeLocks">
                            {{ $t('dashboard.linked') }}
                        </Link>

                        <Link v-if="can('view open transactions') && can('view connected transactions')" :href="route('transactions.index')" :class="[route().current('transactions.index') && route().params.type == undefined ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']" @click="removeLocks">
                            {{ $t('dashboard.all') }}
                        </Link>


                        <span v-if="can('view ticket')" class="block w-full bg-gray-900 px-2 py-1 text-sm text-white">{{ $t('dashboard.tickets') }}</span>
                        <Link
                            v-if="can('view ticket')"
                            :href="route('tickets.index')"
                            :class="[route().current('tickets.*') && route().params.type == undefined ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']"
                            @click="removeLocks">
                            {{ $t('dashboard.all_tickets') }}
                        </Link>

                        <span v-if="can('view coupons')" class="block w-full bg-gray-900 px-2 py-1 text-sm text-white">{{ $t('dashboard.coupons')
                            }}</span>
                        <Link v-if="can('view coupons')" :href="route('coupons.index')"
                            :class="[route().current('coupons.*') && route().params.type == undefined ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center justify-between rounded-md px-4 py-2 text-sm font-medium']">
                        {{ $t('dashboard.all_coupons') }}
                        </Link>
                    </nav>
                </div>
            </div>
        </div>
        <div class="flex flex-col md:pl-64">
            <Banner />
            <div class="sticky top-0 z-10 flex h-16 shrink-0 bg-white shadow">
                <button type="button" class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden" @click="sidebarOpen = true">
                    <span class="sr-only">Open sidebar</span>
                    <Bars3BottomLeftIcon class="h-6 w-6" aria-hidden="true" />
                </button>
                <div class="flex flex-1 justify-end px-4 md:justify-between">
                    <div class="hidden flex-1 items-center space-x-2 md:flex">
                        <span v-if="$page.props.layout.active_store" :class="[$page.props.layout.active_store.status === 'Online' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100', 'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium']">{{ $page.props.layout.active_store.name }}</span>
                        <span v-if="$page.props.layout.active_store" class="bg-green-500 text-green-100 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium">{{ $t('dashboard.transaction_synchronisation') }}: {{ dayjs($page.props.layout.statistics.last_transaction_synced_at).format('DD-MM-YYYY HH:mm') }}</span>
                        <span v-if="$page.props.layout.active_store" class="bg-green-500 text-green-100 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium">{{ $t('dashboard.order_synchronisation') }}: {{ dayjs($page.props.layout.statistics.latest_order_synced_at).format('DD-MM-YYYY HH:mm') }}</span>
                    </div>
                    <div class="flex items-center md:ml-6">
                        <Menu as="div" class="relative ml-3">
                            <div>
                                <MenuButton class="flex max-w-xs items-center bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <span class="sr-only">Open user menu</span>
                                    <span class="ml-2 text-sm">
                                        {{ $page.props.auth.user.name }}
                                    </span>
                                </MenuButton>
                            </div>
                            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                <MenuItems class="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <MenuItem v-if="can('view all roles')">
                                        <Link :href="route('roles.index')" :class="[route().current('roles.*') ? 'bg-gray-100' : 'hover:bg-gray-100', 'block px-4 py-2 text-sm text-gray-700']">{{ $t('menu.roll') }}</Link>
                                    </MenuItem>
                                    <MenuItem v-if="can('view api auth')">
                                        <Link :href="route('api-auth.index')" :class="[route().current('api-auth.*') ? 'bg-gray-100' : 'hover:bg-gray-100', 'block px-4 py-2 text-sm text-gray-700']">{{ $t('dashboard.api') }}</Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link :href="route('profile.edit')" :class="[route().current('profile.*') ? 'bg-gray-100' : 'hover:bg-gray-100', 'block px-4 py-2 text-sm text-gray-700']">{{ $t('dashboard.my_account') }}</Link>
                                    </MenuItem>

                                    <div class="w-full pt-8 0 px-4 py-1 text-sm uppercase tracking-wide">{{ $t('dashboard.settings') }}</div>
                                    <hr />

                                    <MenuItem v-if="can('view all roles')">
                                        <Link :href="route('roles.index')" :class="[route().current('roles.*') ? 'bg-gray-100' : 'hover:bg-gray-100', 'block px-4 py-2 text-sm text-gray-700']">{{ $t('dashboard.roles') }}</Link>
                                    </MenuItem>
                                    <MenuItem v-if="can('view all users')">
                                        <Link :href="route('users.index')" :class="[route().current('users.*') ? 'bg-gray-100' : 'hover:bg-gray-100', 'block px-4 py-2 text-sm text-gray-700']">{{ $t('dashboard.users') }}</Link>
                                    </MenuItem>
                                    <MenuItem v-if="can('view all stores')">
                                        <Link :href="route('stores.index')" :class="[route().current('stores.*') ? 'bg-gray-100' : 'hover:bg-gray-100', 'block px-4 py-2 text-sm text-gray-700']">{{ $t('dashboard.shops') }}</Link>
                                    </MenuItem>
                                    <MenuItem v-if="can('view system check')">
                                        <Link :href="route('system-check.index')" :class="[route().current('system-check.') ? 'bg-gray-100' : 'hover:bg-gray-100', 'block px-4 py-2 text-sm text-gray-700']">{{ $t('dashboard.system_check') }}</Link>
                                    </MenuItem>

                                    <hr />

                                    <MenuItem>
                                        <Link method="post" :href="route('logout')" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">{{ $t('dashboard.logout') }}</Link>
                                    </MenuItem>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>
                </div>
            </div>

            <main class="flex-1">
                <div class="py-6">
                    <div class="mx-auto px-4 sm:px-6 md:px-8">
                        <slot name="header" />
                    </div>

                    <div class="mx-auto px-4 py-4 sm:px-6 md:px-8">
                        <slot />
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { Link, usePage } from '@inertiajs/vue3'
import { Dialog, DialogOverlay, Menu, MenuButton, MenuItem, MenuItems, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { Bars3BottomLeftIcon, XMarkIcon } from '@heroicons/vue/24/outline'

import Banner from '@/Components/Banner.vue'
import axios from "axios";

export default {
    components: {
        Dialog,
        DialogOverlay,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        TransitionChild,
        TransitionRoot,
        Bars3BottomLeftIcon,
        XMarkIcon,
        Banner,
        Link
    },
    setup() {
        const sidebarOpen = ref(false)

        const activeStoreId =  usePage().props.auth?.user.active_store_id

        return {
            activeStoreId,
            sidebarOpen
        }
    },
    methods: {
        changeActiveStore() {
            this.$inertia.put(route('profile.update-active-store'), {
                store: this.activeStoreId
            })

            removeLocks();
        },
        removeLocks() {
            axios.post(route('user.removelocks', {}));
        },
        logout() {
            this.$inertia.post(route('logout'))
        }
    }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
