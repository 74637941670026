<template>
    <p v-if="error" class="text-sm text-red-500">{{ error }}</p>
</template>
<script>
export default {
    props: {
        name: String
    },
    computed: {
        error() {
            return this.$page.props.errors[this.name]
        }
    }
}
</script>
