<template>

    <Head :title="$t('coupons.edit')" />

    <AuthenticatedLayout>
        <template #header>
            <h1 class="text-2xl font-semibold text-gray-900">{{ $t('coupons.edit') }}</h1>
        </template>

        <CouponForm v-model="form" :products="products" @submit="updateCoupon()" />
    </AuthenticatedLayout>
</template>

<script setup>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head, useForm } from '@inertiajs/vue3'
import CouponForm from './Components/Form.vue';
import { onMounted } from 'vue';

const props = defineProps({
    coupon: {
        type: Object,
    },
    products: {
        type: Object,
    }
});

const form = useForm(props.coupon);

const updateCoupon = () => {
    form.post(route('coupons.update', { coupon: form }), form);
}
</script>