<template>
    <Head :title="'Ticket - #' + ticket.id"/>

    <AuthenticatedLayout>
        <template #header>
            <div class="flex flex-wrap items-center justify-between">
                <h1 class="text-2xl font-semibold text-gray-900">Ticket #{{ ticket.id }}</h1>
                <div class="space-x-2">
                    <NavButton v-if="can('view ticket')" :href="route('tickets.index')">Terug naar overzicht</NavButton>
                    <Button v-if="ticket.status != 'closed'" @click="placeReaction=ticket">Reactie plaatsen</Button>
                </div>
            </div>
        </template>
        <div class="grid grid-cols-12 gap-8">
            <div class="col-span-9">
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="py-4 sm:py-5 sm:px-6">
                                <h3 class="text-lg font-semibold">Ticket</h3>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-6 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">Titel:</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-5 sm:mt-0">{{ ticket.title }}</dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-6 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">Content:</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-5 sm:mt-0" v-html="ticket.message"></dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div class="overflow-hidden bg-white shadow sm:rounded-lg mt-8">
                    <div class="px-4 py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-300">
                            <div class="py-4 sm:py-5 sm:px-6">
                                <h3 class="text-lg font-semibold">Reactie's ({{ ticket.messages.length }})</h3>
                            </div>

                            <div>
                                <template v-for="reaction in ticket.messages" :key="reaction.id" >
                                    <div class="py-4 sm:grid sm:grid-cols-6 sm:gap-3 sm:py-5 sm:px-6">
                                        <dt class="text-sm font-medium text-gray-500">Door:</dt>
                                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-5 sm:mt-0 font-bold">{{ reaction.user.name}}</dd>
                                        <dt class="text-sm font-medium text-gray-500">Datum:</dt>
                                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-5 sm:mt-0">{{ dayjs(reaction.created_at).format('LL HH:mm') }}</dd>
                                    </div>

                                    <div class="py-4 sm:grid sm:grid-cols-6 sm:gap-4 sm:py-5 sm:px-6 border-b">
                                        <dt class="text-sm font-medium text-gray-500">Content:</dt>
                                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-5 sm:mt-0" v-html="reaction.message"></dd>
                                    </div>
                                </template>

                                <div v-if="ticket.messages.length == 0">
                                    <span class="block py-4 sm:py-5 sm:px-6">Nog geen reacties geplaatst.</span>
                                </div>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <div class="col-span-3">
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="py-4 sm:py-5 sm:px-6">
                                <h3 class="text-lg font-semibold">Ticket informatie</h3>
                            </div>
                            <div class="py-2 sm:grid sm:grid-cols-3 sm:gap-3 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">ID:</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div>#{{ ticket.id }}</div>
                                </dd>
                                <dt class="text-sm font-medium text-gray-500">Aangemaakt:</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div>{{ dayjs(ticket.updated_at).format('LL HH:mm') }}</div>
                                </dd>
                                <dt class="text-sm font-medium text-gray-500">Status:</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div><TicketStatusBadge :status="ticket.status"></TicketStatusBadge></div>
                                </dd>
                                <dt class="text-sm font-medium text-gray-500">Prioriteit:</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div><TicketStatusBadge :status="ticket.priority"></TicketStatusBadge></div>
                                </dd>
                                <dt class="text-sm font-medium text-gray-500">Categorie:</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div>{{ ticket.categories[0].name }}</div>
                                </dd>
                                <dt class="text-sm font-medium text-gray-500">Door:</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div>{{ ticket.user.name }}</div>
                                </dd>
                                <dt class="text-sm font-medium text-gray-500">Voor:</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div v-if="ticket.assigned_to_user">{{ ticket.assigned_to_user.name }}</div>
                                    <div v-else>N.V.T</div>
                                </dd>

                                <dt class="text-sm font-medium text-gray-500">Afdeling:</dt>
                                <dd class="sm:col-span-2 sm:mt-0">
                                    <div v-if="ticket.department">{{ ticket.department.name }}</div>
                                    <div v-else>N.V.T</div>
                                </dd>

                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <NavButton v-if="can('edit all tickets') || ticket.user_id == $page.props.auth.user.id" :href="route('tickets.edit', {ticket : ticket.id})">Ticket bewerken</NavButton>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div class="overflow-hidden bg-white shadow sm:rounded-lg mt-8">
                    <div class="px-4 py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="py-4 sm:py-5 sm:px-6">
                                <h3 class="text-lg font-semibold">Snel acties</h3>
                            </div>
                            <div v-if="ticket.status == 'closed' && (can('reopen all tickets') || ticket.user_id == $page.props.auth.user.id || ticket.assigned_to == $page.props.auth.user.id || ticket.department_id == $page.props.auth.user.department.id)">
                                <div class="py-2 sm:py-2 sm:px-6">
                                    <Button @click="confirmReOpen = true">Heropen ticket</Button>
                                </div>
                            </div>
                            <div v-if="ticket.status != 'closed' && (can('close all tickets') || ticket.user_id == $page.props.auth.user.id || ticket.assigned_to == $page.props.auth.user.id || ticket.department_id == $page.props.auth.user.department.id)">
                                <div class="py-2 sm:py-2 sm:px-6">
                                    <Button @click="confirmingClose = true">Ticket is opgelost</Button>
                                </div>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>

        <ConfirmationModal v-if="(can('close all tickets') || ticket.user_id == $page.props.auth.user.id || ticket.assigned_to == $page.props.auth.user.id || ticket.department_id == $page.props.auth.user.department.id)" :show="confirmingClose" @close="confirmingClose = false">
            <template #title>
                Ticket sluiten?
            </template>

            <template #content>Weet je zeker dat je dit ticket wilt sluiten?</template>

            <template #footer>
                <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="closeTicket(); confirmingClose = false">Ja ticket sluiten</button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="confirmingClose = false">Annuleren</button>
            </template>
        </ConfirmationModal>

        <ConfirmationModal v-if="(can('reopen all tickets') || ticket.user_id == $page.props.auth.user.id || ticket.assigned_to == $page.props.auth.user.id || ticket.department_id == $page.props.auth.user.department.id)" :show="confirmReOpen" @close="confirmReOpen = false">
            <template #title>
                Ticket opnieuw openen?
            </template>

            <template #content>Weet je zeker dat je dit ticket opnieuw wilt openen?</template>

            <template #footer>
                <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="reOpenTicket(); confirmReOpen = false">Ja heropen ticket</button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="confirmReOpen = false">Annuleren</button>
            </template>
        </ConfirmationModal>

        <create-reaction-modal :ticket="placeReaction" @close="placeReaction = false" />
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Button from '@/Components/Button.vue'
import NavButton from '@/Components/NavButton.vue'
import TicketStatusBadge from "@/Components/TicketStatusBadge.vue";
import CreateReactionModal from "@/Pages/Tickets/CreateReactionModal.vue";
import ConfirmationModal from "@/Components/ConfirmationModal.vue";

export default {
    components: {
        ConfirmationModal,
        CreateReactionModal,
        TicketStatusBadge,
        NavButton,
        Button,
        AuthenticatedLayout,
        Head,
    },
    props: {
        users: Object,
        ticket: Object
    },
    data() {
        return {
            confirmingClose: false,
            confirmReOpen: false,
            placeReaction: false,

            closeForm: this.$inertia.form({}),
            reOpenForm: this.$inertia.form({}),
            form: this.$inertia.form({})
        }
    },
    methods: {
        closeTicket() {
            this.form.post(
                route('tickets.solved', {
                    ticket: this.ticket.id
                })
            )
        },

        reOpenTicket() {
            this.form.post(
                route('tickets.reopen', {
                    ticket: this.ticket.id
                })
            )
        }
    }
}
</script>
