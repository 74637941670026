<template>
    <ReportLayout :name="$t('reports.sales_per_unit')" :type="type" :from="from" :to="to" :period="period">
        <div class="flex flex-col">
            <Multiselect v-model="filterBy" :options="uniqueProducts" mode="tags" class="mb-2"
                         :placeholder="$t('Filter by product')" v-on:change="getNewFilter" searchable />
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                            <tr>
                                <th scope="col"
                                    class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    {{ $t('reports.product') }}</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {{ $t('reports.numbers_ordered') }}</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {{ $t('reports.total_sold') }}</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {{ $t('reports.total_units') }}</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {{ $t('reports.silver_units') }}</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {{ $t('reports.gold_units') }}</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {{ $t('reports.purple_units') }}</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {{ $t('reports.blue_units') }}</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {{ $t('reports.black_units') }}</th>
                            </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="(item, idx) in filteredProducts" :key="idx">
                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                    {{ item.product_name }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                    {{ formatNumber(item.order_count) }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                    {{ formatNumber(item.total_product_sales) }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                    {{ formatNumber(item.total_unit_count) }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                    {{ formatNumber(item.silver_unit_count) }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                    {{ formatNumber(item.gold_unit_count) }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                    {{ formatNumber(item.purple_unit_count) }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                    {{ formatNumber(item.blue_unit_count) }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                    {{ formatNumber(item.black_unit_count) }}
                                </td>
                            </tr>
                            <tr>

                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray font-bold sm:pl-6">
                                    {{ $t('reports.total') }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-bold text-gray">
                                    {{ $t('reports.unique-orders') }}
                                    {{ formatNumber(totalData.unique_orders) }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-bold text-gray">
                                    {{ formatNumber(totalData.total_product_sales) }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-bold text-gray">
                                    {{ formatNumber(totalData.total_unit_count) }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-bold text-gray">
                                    {{ formatNumber(totalData.silver_unit_count) }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-bold text-gray">
                                    {{ formatNumber(totalData.gold_unit_count) }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-bold text-gray">
                                    {{ formatNumber(totalData.purple_unit_count) }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-bold text-gray">
                                    {{ formatNumber(totalData.blue_unit_count) }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-bold text-gray">
                                    {{ formatNumber(totalData.black_unit_count) }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </ReportLayout>
</template>

<script setup>
import ReportLayout from '@/Pages/Report/Layout.vue'
import { computed, onMounted, ref, watch } from 'vue';
import Multiselect from '@vueform/multiselect'
import { router } from '@inertiajs/vue3'

const props = defineProps({
    type: String,
    from: String,
    to: String,
    period: String,
    reportData: Object,
    totalData: Object,
    filters: String,
});

const getNewFilter = (newFilters) => {
    if (newFilters.toString() === filterBy.value.toString()) return;
    const params = {
        type: props.type,
        from: props.from,
        to: props.to,
        period: props.period,
        filters: newFilters,
    }
    router.get('/report', params);
}

const uniqueProducts = computed(() => {
    const items = props.reportData.map(item => item.product_name);
    return Array.from(new Set(items));
});

const filterBy = ref([]);

const filteredProducts = computed(() => {
    if (!filterBy.value.length) return props.reportData;
    return props.reportData.filter(data => filterBy.value.includes(data.product_name));
});

onMounted(() => {
    filterBy.value = props.filters ? props.filters.split('&') : [];
});
</script>

<style>
input[type="text"].multiselect-tags-search {
    padding-top: 0;
}

input[type="text"]:focus.multiselect-tags-search {
    border: none;
    outline: 0;
    box-shadow: none;
}
</style>
