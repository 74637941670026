<template>
    <Head :title="$t('shipments.incoming_shipment')" />

    <AuthenticatedLayout>
        <template #header>
            <h1 class="text-2xl font-semibold text-gray-900">{{ $t('shipments.incoming_shipment') }}</h1>
        </template>

        <div>
            <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                <div class="px-4 py-5 sm:p-0">
                    <dl class="sm:divide-y sm:divide-gray-200">
                        <div v-if="incomingShipment.reference" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">{{ $t('shipments.reference') }}</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {{ incomingShipment.reference }}
                            </dd>
                        </div>
                        <div v-if="incomingShipment.suppliedBy" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">{{ $t('shipments.supplier') }}</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <div class="text-sm font-medium text-gray-900">
                                    {{ incomingShipment.suppliedBy.name }}
                                </div>
                                <div class="text-sm text-gray-500">
                                    {{ incomingShipment.suppliedBy.email }}
                                </div>
                            </dd>
                        </div>
                        <div v-if="incomingShipment.checkedBy" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">{{ $t('shipments.checked_by') }}</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <div class="text-sm font-medium text-gray-900">
                                    {{ incomingShipment.checkedBy.name }}
                                </div>
                                <div class="text-sm text-gray-500">
                                    {{ incomingShipment.checkedBy.email }}
                                </div>
                            </dd>
                        </div>

                        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">{{ $t('shipments.status') }}</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <status-badge :status="incomingShipment.status"></status-badge>
                            </dd>
                        </div>
                        <div v-if="incomingShipment.notes" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">{{ $t('shipments.notes') }}</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <code class="rounded-lg border bg-gray-100 px-2 py-1 text-xs">
                                    {{ incomingShipment.notes }}
                                </code>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div class="mt-6 flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('shipments.reference') }}</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('shipments.status') }}</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('shipments.supplier') }}</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('shipments.checked_by') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr v-for="(product, index) in incomingShipment.products" :key="product.id">
                                        <td class="py-6 px-6 text-sm">
                                            <div class="flex items-center">
                                                <img v-if="product.product.product.image" :src="product.product.product.image" :alt="product.product.product.name" class="mr-6 h-16 w-16 rounded object-cover object-center" />
                                                <div>
                                                    <div class="font-medium text-gray-900">
                                                        {{ product.product.product.name }}
                                                    </div>
                                                    <div class="mt-1">
                                                        {{ product.short_description_stripped }}
                                                    </div>
                                                    <div v-if="product.cost_price" class="mt-1">
                                                        {{ $t('shipments.price_costs') }}
                                                        {{ formatPrice(product.product.product.cost_price) }}
                                                    </div>
                                                    <div v-if="product.product.numbers_packed" class="mt-1">
                                                        {{ $t('shipments.in_package') }}
                                                        {{ product.product.numbers_packed }}
                                                        stuks
                                                    </div>
                                                    <div v-if="product.product.barcode" class="mt-1">
                                                        {{ $t('shipments.barcode') }}
                                                        {{ product.product.barcode }}
                                                    </div>
                                                    <ValidationError :name="'products.' + index + '.id'" />
                                                    <ValidationError :name="'products.' + index + '.actually_quantity'" />
                                                </div>
                                            </div>
                                        </td>

                                        <td class="whitespace-nowrap px-6 py-4 text-gray-900">
                                            {{ product.intern_reference }}
                                        </td>

                                        <td class="whitespace-nowrap px-6 py-4 text-gray-900">
                                            {{ product.expected_quantity }}
                                        </td>

                                        <td class="whitespace-nowrap px-6 py-4 text-gray-900">
                                            {{ product.actually_quantity ?? 0 }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-6 flex items-center justify-center space-x-4">
                <NavButton
                    v-if="can('edit incoming shipment') && incomingShipment.status !== 'Completed'"
                    :href="
                        route('incoming-shipments.edit', {
                            incoming_shipment: incomingShipment.id
                        })
                    "
                >
                    {{ $t('shipments.edit') }}
                </NavButton>
                <NavButton
                    v-if="can('check incoming shipment') && incomingShipment.status !== 'Completed'"
                    :href="
                        route('incoming-shipments.check', {
                            incoming_shipment: incomingShipment.id
                        })
                    "
                >
                    {{ $t('shipments.check') }}
                </NavButton>
            </div>
        </div>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import NavButton from '@/Components/NavButton.vue'
import ValidationError from '@/Components/ValidationError.vue'
import StatusBadge from '@/Components/StatusBadge.vue'

export default {
    components: {
        StatusBadge,
        ValidationError,
        NavButton,
        AuthenticatedLayout,
        Head
    },
    props: {
        incomingShipment: Object
    }
}
</script>
