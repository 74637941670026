<template>
    <Head :title="indexTitle" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex items-center justify-between">
                <h1 class="text-2xl font-semibold text-gray-900">
                    {{ indexTitle }}
                </h1>
                <div>
                    <div class="flex rounded-md shadow-sm">
                        <div class="relative flex flex-grow items-stretch focus-within:z-10">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </div>
                            <input id="search" v-model="searchQuery" type="text" name="search" class="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Zoeken..." />
                        </div>
                        <button type="button" class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500" @click="search">Zoeken</button>
                    </div>
                </div>
            </div>
        </template>

        <pagination class="mb-2" :links="transactions" />

        <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-4 lg:px-8">
                    <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">ID</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Datum ontvangen</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Order ID</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Overgemaakt bedrag</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Bestelbedrag</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Verschil</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Status</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Raw</th>
                                <th scope="col" class="relative px-4 py-2">
                                    <span class="sr-only">Koppelen</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <template v-for="transaction in transactions.data" :key="transaction.id">
                                    <tr class="bg-gray-100">
                                        <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                            {{ transaction.id }}
                                        </td>
                                        <td class="whitespace-nowrap px-4 py-2">
                                            <div v-if="transaction.received_at" class="text-xs font-medium text-gray-900">
                                                {{ dayjs(transaction.created_at).format('LL') }}
                                            </div>
                                            <div v-if="transaction.received_at" class="text-xs text-gray-500">
                                                {{ dayjs(transaction.created_at).format('LT') }}
                                            </div>
                                        </td>
                                        <td class="whitespace-nowrap px-4 py-2">
                                            <span v-if="transaction.order">
                                                <a :href="route('orders.show', {order: transaction.order_id})" class="hover:underline" target="_blank">{{ transaction.order.extern_id }}</a>
                                            </span>
                                        </td>
                                        <td class="whitespace-nowrap px-4 py-2">
                                            {{ formatPrice(transaction.amount/100) }}
                                        </td>
                                        <td class="whitespace-nowrap px-4 py-2">
                                            <span v-if="transaction.order">
                                                {{ formatPrice(transaction.order.total/100) }}
                                            </span>
                                        </td>
                                        <td class="whitespace-nowrap px-4 py-2">
                                            <span v-if="transaction.order">{{ formatPrice(transaction.amount_left/100) }}</span>
                                        </td>
                                        <td class="whitespace-nowrap px-4 py-2">
                                            {{ transaction.status }}
                                        </td>
                                        <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                            {{ transaction.raw_content }}
                                        </td>
                                        <td class="whitespace-nowrap px-4 py-2">
                                            <button v-if="can('update status order') && !transaction.order_id && transaction.manual_check_needed" class="block text-right text-indigo-600 hover:text-indigo-900" @click="updateTransactionStatus = transaction">Koppelen</button>
                                            <button v-if="can('delete transaction') && transaction.manual_check_needed" class="block text-right text-red-600 hover:text-red-900" @click="openDeleteTransaction = transaction">Verwijderen</button>
                                        </td>
                                    </tr>

                                    <template v-for="childTransaction in transaction.parent_transactions" :key="childTransaction.id">
                                        <tr>
                                            <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                                -
                                            </td>
                                            <td class="whitespace-nowrap px-4 py-2">
                                                <div class="text-xs font-medium text-gray-900">
                                                    {{ dayjs(childTransaction.created_at).format('LL') }}
                                                </div>
                                                <div class="text-xs text-gray-500">
                                                    {{ dayjs(childTransaction.created_at).format('LT') }}
                                                </div>
                                            </td>
                                            <td class="whitespace-nowrap px-4 py-2">

                                            </td>
                                            <td class="whitespace-nowrap px-4 py-2">
                                                {{ formatPrice(childTransaction.amount/100) }}
                                            </td>
                                            <td class="whitespace-nowrap px-4 py-2">
                                            <span v-if="transaction.order">

                                            </span>
                                            </td>
                                            <td class="whitespace-nowrap px-4 py-2">

                                            </td>
                                            <td class="whitespace-nowrap px-4 py-2">

                                            </td>
                                            <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                                {{ childTransaction.raw_content }}
                                            </td>
                                            <td>

                                            </td>
                                        </tr>
                                    </template>
                                </template>
                                <tr v-if="transactions.data.length == 0">
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900" colspan="9">Geen resultaten gevonden</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <pagination class="mt-2" :links="transactions" />

        <connect-modal v-if="can('connect transaction')" :transaction="updateTransactionStatus" @close="updateTransactionStatus = false" />
<!--        <update-status-modal v-if="can('update status order')" :order="updateOrderStatus" @close="updateOrderStatus = false" />-->

        <ConfirmationModal :show="openDeleteTransaction != false" @close="openDeleteTransaction = false">
            <template #title>Transactie verwijderen? - {{ openDeleteTransaction.id }}</template>

            <template #content>
                Weet je zeker dat je deze transactie wilt verwijderen?
                <p v-show="openDeleteTransaction.raw_content">
                    {{ openDeleteTransaction.raw_content }}
                </p>
            </template>

            <template #footer>
                <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="deleteTransaction(openDeleteTransaction.id)">Verwijderen</button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="openDeleteTransaction = false">Annuleren</button>
            </template>
        </ConfirmationModal>

    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Pagination from '@/Components/Pagination.vue'
import ConnectModal from '@/Pages/Transactions/ConnectModal.vue'
import ConfirmationModal from '@/Components/ConfirmationModal.vue';

export default {
    components: {
        ConfirmationModal,
        ConnectModal,
        Pagination,
        AuthenticatedLayout,
        Head
    },
    props: {
        transactions: Object,
        indexTitle: String,
        oldSearchQuery: String,
        currentType: String
    },
    data() {
        return {
            updateTransactionStatus: false,
            openDeleteTransaction: false,
            searcoQuery: this.oldSearchQuery,
            form: this.$inertia.form({
            }),
        }
    },

    methods: {
        deleteTransaction(id) {
            this.form.post(this.route('transactions.delete', { transaction: id }), {
                preserveScroll: false,
                onSuccess: () => {
                    this.openDeleteTransaction = false;
                    this.deleteRow(id);
                }
            })
        },
        deleteRow(id) {
            const index = this.transactions.data.findIndex(transaction => transaction.id === id);
            if (index !== -1) {
                this.transactions.data.splice(index, 1);
            }
        },
        search() {
            this.$inertia.get(
                route(route().current()),
                { query: this.searchQuery, type: this.currentType },
                {
                    preserveState: true
                }
            )
        }
    }
}
</script>
