<template>
    <Head :title="$t('retour.retour') + ' - ' + retour.id" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex flex-wrap items-center justify-between">
                <h1 class="text-2xl font-semibold text-gray-900">{{ $t('retour.retour') }} - {{ retour.id }}</h1>
                <div class="space-x-2">
                    <Button v-if="can('delete retour')" @click="showDeleteRetour = true">{{ $t('retour.delete') }}</Button>
                    <Button v-if="can('complete retour')" @click="showCompleteRetour = true">{{ $t('retour.complete') }}</Button>
                </div>
            </div>
        </template>

        <div class="grid grid-cols-12 gap-8">
            <div v-if="retour" class="col-span-12 md:col-span-6">
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="py-4 sm:py-5 sm:px-6">
                                <h3 class="text-lg font-semibold">{{ $t('retour.retour_info') }}</h3>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('retour.sub_total_after_retour') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice(retour.subtotal) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('retour.vat_after_retour') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice(retour.tax) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('retour.total_amount_after_retour') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice(retour.total) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('retour.pay_customer') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice(retour.pay_to_customer) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('retour.status') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <status-badge :status="retour.status"></status-badge>
                                </dd>
                            </div>
                            <div v-if="retour.reason" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('retour.reason_retour') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <p class="rounded-lg border bg-gray-100 px-2 py-1 text-xs">
                                        {{ retour.reason }}
                                    </p>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

            <div v-if="retour" class="col-span-12 md:col-span-6">
                <div v-if="retour.items.length >= 1" class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                            <h3 class="text-lg font-semibold">{{ $t('retour.retour_products') }}</h3>
                        </div>

                        <div class="flex flex-col">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div class="overflow-hidden shadow sm:rounded-lg">
                                        <table class="min-w-full">
                                            <tbody>
                                                <tr v-for="retourItem in retour.items" :key="retourItem.id" class="border-b border-gray-200 bg-white">
                                                    <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                                        {{ retourItem.quantity }}
                                                        x
                                                    </td>
                                                    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                                        <div>
                                                            {{ retourItem.variation.product.name }}
                                                        </div>
                                                        <div>
                                                            {{ $t('retour.barcode') }}
                                                            {{ retourItem.variation.barcode }}
                                                        </div>
                                                        <div>
                                                            {{ retourItem.variation.numbers_packed }}
                                                            {{ $t('retour.pieces_packaged') }}
                                                        </div>
                                                    </td>
                                                    <td class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                                        <div>
                                                            {{ formatPrice(retourItem.total) }}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="retour.extraCosts.length >= 1" class="mt-4 overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                            <h3 class="text-lg font-semibold">{{ $t('retour.retour_costs') }}</h3>
                        </div>

                        <div class="flex flex-col">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div class="overflow-hidden shadow sm:rounded-lg">
                                        <table class="min-w-full">
                                            <tbody>
                                                <tr v-for="retourExtraCost in retour.extraCosts" :key="retourExtraCost.id" class="border-b border-gray-200 bg-white">
                                                    <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                                        {{ retourExtraCost.name }}
                                                    </td>
                                                    <td class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                                        <div>
                                                            {{ formatPrice(retourExtraCost.price) }}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-span-12 md:col-span-6">
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="py-4 sm:py-5 sm:px-6">
                                <h3 class="text-lg font-semibold">{{ $t('retour.order_info') }}</h3>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">ID</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ retour.order.extern_id }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('retour.ordered_at') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ dayjs(retour.order.ordered_at).format('lll') }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('retour.sub_total') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice(retour.order.order_subtotal) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('retour.vat') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice(retour.order.order_tax) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('retour.total_amount') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice(retour.order.total) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('retour.status') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <status-badge :status="retour.order.status"></status-badge>
                                </dd>
                            </div>
                            <div v-if="retour.order.notes" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('retour.notes') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <p class="rounded-lg border bg-gray-100 px-2 py-1 text-xs">
                                        {{ retour.order.notes }}
                                    </p>
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('retour.action') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <NavButton
                                        v-if="can('view order')"
                                        :href="
                                            route('orders.show', {
                                                order: retour.order.id
                                            })
                                        "
                                    >
                                       {{ $t('retour.details') }}
                                    </NavButton>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

            <ConfirmationModal :show="showDeleteRetour" @close="showDeleteRetour = false">
                <template #title>{{ $t('retour.delete') }}</template>

                <template #content>{{ $t('retour.delete_confirm') }}</template>

                <template #footer>
                    <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="deleteRetour()">{{ $t('retour.delete') }}</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="showDeleteRetour = false">{{ $t('retour.cancel') }}</button>
                </template>
            </ConfirmationModal>

            <ConfirmationModal :show="showCompleteRetour" @close="showCompleteRetour = false">
                <template #title>{{ $t('retour.complete') }}</template>

                <template #icon>
                    <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                </template>

                <template #content>{{ $t('retour.complete_confirm') }}</template>

                <template #footer>
                    <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="completeRetour()">{{ $t('retour.complete') }}</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="showCompleteRetour = false">{{ $t('retour.cancel') }}</button>
                </template>
            </ConfirmationModal>
        </div>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Button from '@/Components/Button.vue'
import NavButton from '@/Components/NavButton.vue'
import ConfirmationModal from '@/Components/ConfirmationModal.vue'
import { CheckIcon } from '@heroicons/vue/24/outline'
import StatusBadge from '@/Components/StatusBadge.vue'

export default {
    components: {
        StatusBadge,
        ConfirmationModal,
        NavButton,
        Button,
        AuthenticatedLayout,
        Head,
        CheckIcon
    },
    props: {
        retour: Object
    },
    data() {
        return {
            showDeleteRetour: false,
            showCompleteRetour: false,
            form: this.$inertia.form({})
        }
    },
    methods: {
        deleteRetour() {
            this.form.delete(
                route('retours.destroy', {
                    retour: this.retour.id
                })
            )
            this.showDeleteRetour = false
        },
        completeRetour() {
            this.form.put(
                route('retours.complete', {
                    retour: this.retour.id
                })
            )
            this.showCompleteRetour = false
        }
    }
}
</script>
