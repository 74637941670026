<template>
    <Head :title="$t('customers.customer') + ' ' + customer.id" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex flex-wrap items-center justify-between">
                <h1 class="text-2xl font-semibold text-gray-900">{{ $t('customers.customer')}} {{ customer.id }}</h1>
                <div v-if="currentTab === 'general'" class="space-x-2">

                </div>
                <div v-if="currentTab === 'tickets'" class="space-x-2">
                    <NavButton v-if="can('create ticket')" :href="route('tickets.create', { customer: customer.id })">{{ $t('customers.create_ticket') }}</NavButton>
                </div>

                <div v-if="currentTab === 'notes'" class="space-x-2">
                    <Button v-if="can('place customer note')" class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-gray-700 focus:border-gray-900 focus:outline-none active:bg-gray-900" @click="placeNote = customer">{{ $t('customers.add_note') }}</Button>
                </div>
            </div>

            <div class="mt-6">
                <div class="sm:hidden">
                    <label for="tabs" class="sr-only">Select a tab</label>
                    <select id="tabs" v-model="currentTab" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                        <option v-for="tab in tabs" :key="tab.name" :selected="tab.action === currentTab">
                            {{ tab.name }}
                        </option>
                    </select>
                </div>
                <div class="hidden sm:block">
                    <nav class="relative z-0 flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
                        <button v-for="(tab, tabIdx) in tabs" :key="tab.name" :class="[tab.action === currentTab ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', tabIdx === 0 ? 'rounded-l-lg' : '', tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10']" :aria-current="tab.current ? 'page' : undefined" @click="currentTab = tab.action">
                            <span>{{ tab.name }}</span>
                            <span aria-hidden="true" :class="[tab.action === currentTab ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
                        </button>
                    </nav>
                </div>
            </div>
        </template>

        <div v-if="currentTab === 'orders'">
            <div class="flex items-center justify-between">
                <div></div>

                <div class="flex rounded-md shadow-sm">
                    <div class="relative flex flex-grow items-stretch focus-within:z-10">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </div>
                        <input id="search" v-model="searchQuery" type="text" name="search" class="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="" />
                    </div>
                    <button type="button" class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500" @click="search">{{ $t('customers.search')  }}</button>
                </div>
            </div>


            <div class="flex flex-col mt-4">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-4 lg:px-8">
                        <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.id') }}</th>
                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.order_date') }}</th>
                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.invoice') }}</th>
                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.deliver') }}</th>
                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.contact') }}</th>
                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.total_amount') }}</th>
                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.status') }}</th>
                                    <th scope="col" class="relative px-4 py-2">
                                        <span class="sr-only">{{ $t('customers.view') }}</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
<!--                                :class="order.tickets.length > 0 ? 'bg-yellow-100' : ''"-->
                                <tr v-for="order in orders.data" :key="order.id" :class="['even:bg-gray-100', order.tickets.length > 0 ? 'bg-yellow-100' : '']">
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        {{ order.extern_id }}
                                    </td>
                                    <td class="whitespace-nowrap px-4 py-2">
                                        <div class="text-xs font-medium text-gray-900">
                                            {{ dayjs(order.ordered_at).format('LL') }}
                                        </div>
                                        <div class="text-xs text-gray-500">
                                            {{ dayjs(order.ordered_at).format('LT') }}
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-4 py-2">
                                        <div class="text-xs font-medium text-gray-900">
                                            <div v-if="order.billing_company_name">
                                                {{ order.billing_company_name }}
                                            </div>
                                            <div>
                                                {{ order.billing_firstname }}
                                                {{ order.billing_lastname}}
                                            </div>
                                        </div>
                                        <div class="text-xs text-gray-500">
                                            <div>
                                                {{ order.billing_address }}
                                            </div>
                                            <div>
                                                {{ order.billing_postcode }}
                                                {{ order.billing_city }}
                                                {{ order.billing_country }}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-4 py-2">
                                        <div class="text-xs font-medium text-gray-900">
                                            <div v-if="order.shipping_company_name">
                                                {{ order.shipping_company_name }}
                                            </div>
                                            <div>
                                                {{ order.shipping_firstname }}
                                                {{ order.shipping_lastname}}
                                            </div>
                                        </div>
                                        <div class="text-xs text-gray-500">
                                            <div>
                                                {{ order.shipping_address }}
                                            </div>
                                            <div>
                                                {{ order.shipping_postcode }}
                                                {{ order.shipping_city }}
                                                {{ order.shipping_country }}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-4 py-2">
                                        <div v-if="order.email" class="text-xs font-medium text-gray-900">
                                            {{ order.email }}
                                        </div>
                                        <div v-if="order.billing_phone" class="text-xs text-gray-500">
                                            {{ order.billing_phone }}
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        <div class="text-xs font-medium text-gray-900">
                                            {{ formatPrice(order.total/100) }}
                                        </div>
                                        <div class="text-xs text-gray-500">{{ order.items_count }} items</div>
                                    </td>

                                    <td class="whitespace-nowrap px-4 py-2">
                                        <status-badge :status="order.status"></status-badge>
                                    </td>
                                    <td class="flex flex-col space-y-2 whitespace-nowrap px-4 py-2 text-right text-xs font-medium">
                                        <Link
                                            v-if="can('pack order') && order.packed_items_count == 0"
                                            :href="
                                                route('orders.pack', {
                                                    order: order.id
                                                })
                                            "
                                            class="text-indigo-600 hover:text-indigo-900"
                                        >
                                            {{ $t('customers.pack') }}
                                        </Link>
                                        <Link
                                            v-if="can('create retour') && order.packed_items_count >= 1"
                                            :href="
                                                route('orders.pack', {
                                                    order: order.id
                                                })
                                            "
                                            class="text-indigo-600 hover:text-indigo-900"
                                        >
                                            {{ $t('customers.add_return') }}
                                        </Link>
                                        <Link
                                            v-if="can('view order')"
                                            :href="
                                                route('orders.show', {
                                                    order: order.id
                                                })
                                            "
                                            class="text-indigo-600 hover:text-indigo-900"
                                        >
                                            {{ $t('customers.details') }}
                                        </Link>
                                        <button v-if="can('update status order')" class="block text-right text-indigo-600 hover:text-indigo-900" @click="updateOrderStatus = order">{{ $t('customers.change_status') }}</button>
                                    </td>
                                </tr>
                                <tr v-if="orders.data.length == 0">
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900" colspan="8">{{ $t('customers.no_results_found') }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <pagination class="mt-2" :links="orders" />
        </div>

        <div v-if="currentTab === 'general'" class="grid grid-cols-12 gap-8">
            <div class="col-span-12 md:col-span-6">
                <div class="overflow-visible bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="py-4 sm:py-5 sm:px-6">
                                <h3 class="text-lg font-semibold">{{ $t('customers.customer_data') }}</h3>
                            </div>

                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('customers.customer') }}</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div class="text-sm font-medium text-gray-900"></div>
                                    <div>{{ customer.firstname }} {{ customer.lastname }}</div>
                                    <div class="text-sm text-gray-500">
                                        <div>{{ customer.email }}</div>
                                        <div>{{ customer.phone }}</div>
                                        <div v-if="customer.date_of_birth">DOB: {{ dayjs(customer.date_of_birth).format('LL') }}</div>
                                    </div>
                                </dd>
                            </div>

                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-bold text-gray-500">Website</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0"></dd>
                                <dt class="text-sm font-medium text-gray-500">ID</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div>{{ customer.extern_id ? customer.extern_id : 'nvt'  }}</div>
                                </dd>
                                <dt class="text-sm font-medium text-gray-500">{{ $t('customers.customer_since') }}</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div>{{ dayjs(customer.created_at).format('LL') }}</div>
                                </dd>
                                <dt class="text-sm font-medium text-gray-500">{{ $t('customers.receive_newsletter') }}</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div>{{ customer.subscribed_to_news_letter ? $t('customers.yes') : $t('customers.no') }}</div>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <div class="col-span-12 md:col-span-6">
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="py-4 sm:py-5 sm:px-6">
                                <h3 class="text-lg font-semibold">{{ $t('customers.statistics') }}</h3>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('customers.orders') }}</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div class="text-sm font-medium text-gray-900"></div>
                                    <div>{{ customer.customerStats.totalOrders}} {{ $t('customers.placed_orders') }}</div>
                                    <div>{{ formatPrice(customer.customerStats.totalOrdersSpend/100) }} {{ $t('customers.ordered_amount') }}</div>
                                    <div>{{ formatPrice(customer.customerStats.averageOrdervalue/100) }} {{ $t('customers.average_amount') }}</div>
                                </dd>
                            </div>

                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('customers.tickets') }}</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div class="text-sm font-medium text-gray-900"></div>
                                    <div v-for="stats in customer.customerStats.tickets" :key="stats.title">
                                        ({{ stats.count }}) {{ stats.title }}
                                    </div>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="can('view ticket') && currentTab === 'tickets'" class="grid grid-cols-12 gap-8">
            <div class="col-span-12">
                <div class="overflow-visible bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                            <h3 class="text-lg font-semibold">{{ $t('customers.tickets') }}</h3>
                        </div>

                        <div class="flex flex-col">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div class="overflow-visible shadow sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                            <tr>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.id') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.created') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.prio') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.status') }}</th>

                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.who_for') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.title') }}</th>
                                                <th scope="col" class="relative px-4 py-2"><span class="sr-only">{{ $t('customers.view') }}</span></th>
                                            </tr>
                                            </thead>
                                            <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="ticket in customer.tickets" :key="ticket.id" class="even:bg-gray-100">
                                                <td class="whitespace-nowrap px-4 py-2 text-x font-medium text-gray-900">{{ ticket.id }}</td>
                                                <td class="whitespace-nowrap px-4 py-2">
                                                    <div class="text-m font-medium text-gray-900">
                                                        {{ dayjs(ticket.created_at).format('LL') }}
                                                    </div>
                                                    <div class="text-sm text-gray-500">
                                                        {{ dayjs(ticket.created_at).format('LT') }}
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-4 py-2 text-m font-medium text-gray-900"><TicketStatusBadge :status="ticket.priority"></TicketStatusBadge></td>
                                                <td class="whitespace-nowrap px-4 py-2 text-m font-medium text-gray-900"><TicketStatusBadge :status="ticket.status"></TicketStatusBadge></td>
                                                <td class="whitespace-nowrap px-4 py-2">
                                                    <div class="text-m font-medium text-gray-900">
                                                        A: {{ ticket.user.name }}
                                                    </div>
                                                    <div class="text-m font-medium text-gray-900">
                                                        V {{ ticket.assigned_to_user.name }}
                                                    </div>
                                                </td>

                                                <td class="px-4 py-2 w-full max-w-xs xl:max-w-lg">
                                                    <div class="relative group w-full">
                                                        <p class="w-full truncate">{{ ticket.title }}</p>
                                                        <span class="hidden group-hover:block ml-4 mt-10 w-screen max-w-lg absolute top-0 border shadow-lg p-6 bg-white rounded-md z-50 text-gray-900">
                                                            <article>
                                                                <header>
                                                                    <div>
                                                                        <span class="text-gray-700">{{ $t('customers.content') }}</span>
                                                                    </div>
                                                                </header>
                                                                <section class="mt-2">
                                                                    <h1 class="text-sm font-semibold mt-3">{{ ticket.title }}</h1>
                                                                    <div class="mt-3" v-html="ticket.message"></div>
                                                                </section>
                                                            </article>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-4 py-2 text-x font-medium">
                                                    <a class="text-indigo-600 hover:text-indigo-900" :href="route('tickets.show', { ticket: ticket.id })" target="_BLANK">{{ $t('customers.view') }}</a>
                                                </td>
                                            </tr>
                                            <tr v-if="customer.tickets.length == 0">
                                                <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900" colspan="6">{{ $t('customers.no_results_found') }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="can('view customer transactions') && currentTab === 'transactions'" class="grid grid-cols-12 gap-8">
            <div class="col-span-12">
                <div class="overflow-visible bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                            <h3 class="text-lg font-semibold">{{ $t('customers.transactions') }}</h3>
                        </div>

                        <div class="flex flex-col">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div class="overflow-visible shadow sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                            <tr>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.id') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.receive_date') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.order_id') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.transferred_amount') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.order_amount') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.difference') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.status') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.raw') }}</th>
                                                <th scope="col" class="relative px-4 py-2">
                                                    <span class="sr-only">Koppelen</span>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody class="divide-y divide-gray-200 bg-white">
                                            <template v-for="transaction in customer.transactions" :key="transaction.id">
                                                <tr class="bg-gray-100">
                                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                                        {{ transaction.id }}
                                                    </td>
                                                    <td class="whitespace-nowrap px-4 py-2">
                                                        <div v-if="transaction.received_at" class="text-xs font-medium text-gray-900">
                                                            {{ dayjs(transaction.received_at).format('LL') }}
                                                        </div>
                                                        <div v-if="transaction.received_at" class="text-xs text-gray-500">
                                                            {{ dayjs(transaction.received_at).format('LT') }}
                                                        </div>
                                                    </td>
                                                    <td class="whitespace-nowrap px-4 py-2">
                                                        <span v-if="transaction.order">
                                                            <a :href="route('orders.show', {order: transaction.order_id})" class="hover:underline" target="_blank">{{ transaction.order.extern_id }}</a>
                                                        </span>
                                                    </td>
                                                    <td class="whitespace-nowrap px-4 py-2">
                                                        {{ formatPrice(transaction.amount/100) }}
                                                    </td>
                                                    <td class="whitespace-nowrap px-4 py-2">
                                                        <span v-if="transaction.order">
                                                            {{ formatPrice(transaction.order.total/100) }}
                                                        </span>
                                                    </td>
                                                    <td class="whitespace-nowrap px-4 py-2">
                                                        <span v-if="transaction.order">{{ formatPrice(transaction.amount_left/100) }}</span>
                                                    </td>
                                                    <td class="whitespace-nowrap px-4 py-2">
                                                        {{ transaction.status }}
                                                    </td>
                                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                                        {{ transaction.raw_content }}
                                                    </td>
                                                    <td class="whitespace-nowrap px-4 py-2">
                                                        <button v-if="can('update status order') && !transaction.order_id" class="block text-right text-indigo-600 hover:text-indigo-900" @click="updateTransactionStatus = transaction">{{ $t('customers.link') }}</button>
                                                    </td>
                                                </tr>

                                                <template v-for="childTransaction in transaction.parent_transactions" :key="childTransaction.id">
                                                    <tr>
                                                        <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                                            -
                                                        </td>
                                                        <td class="whitespace-nowrap px-4 py-2">
                                                            <div class="text-xs font-medium text-gray-900">
                                                                {{ dayjs(childTransaction.received_at).format('LL') }}
                                                            </div>
                                                            <div class="text-xs text-gray-500">
                                                                {{ dayjs(childTransaction.received_at).format('LT') }}
                                                            </div>
                                                        </td>
                                                        <td class="whitespace-nowrap px-4 py-2">

                                                        </td>
                                                        <td class="whitespace-nowrap px-4 py-2">
                                                            {{ formatPrice(childTransaction.amount/100) }}
                                                        </td>
                                                        <td class="whitespace-nowrap px-4 py-2">
                                                        <span v-if="transaction.order">

                                                        </span>
                                                        </td>
                                                        <td class="whitespace-nowrap px-4 py-2">

                                                        </td>
                                                        <td class="whitespace-nowrap px-4 py-2">

                                                        </td>
                                                        <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                                            {{ childTransaction.raw_content }}
                                                        </td>
                                                        <td>

                                                        </td>
                                                    </tr>
                                                </template>
                                            </template>
                                            <tr v-if="customer.transactions.length == 0">
                                                <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900" colspan="7">{{ $t('customers.no_results_found') }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="can('view customer notes') && currentTab === 'notes'" class="grid grid-cols-12 gap-8">
            <div class="col-span-12">
                <div class="overflow-visible bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                            <h3 class="text-lg font-semibold">Notities</h3>
                        </div>

                        <div class="flex flex-col">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div class="overflow-visible shadow sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                            <tr>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.created') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.by') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.body') }}</th>
                                            </tr>
                                            </thead>
                                            <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="note in customer.notes" :key="note.id" class="even:bg-gray-100">
                                                <td class="whitespace-nowrap px-4 py-2">
                                                    <div class="text-m font-medium text-gray-900">
                                                        {{ dayjs(note.created_at).format('LL') }}
                                                    </div>
                                                    <div class="text-sm text-gray-500">
                                                        {{ dayjs(note.created_at).format('LT') }}
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-4 py-2">
                                                    <div class="text-m font-medium text-gray-900">{{ note.user.name }}</div>
                                                </td>

                                                <td class="px-4 py-2 w-full max-w-xs xl:max-w-lg">
                                                    <div class="relative group w-full" v-html="note.message"></div>
                                                </td>
                                            </tr>
                                            <tr v-if="customer.notes.length == 0">
                                                <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900" colspan="3">{{ $t('customers.no_results_found') }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <update-status-modal v-if="can('update status order')" :order="updateOrderStatus" @close="updateOrderStatus = false" />
        <create-note-modal v-if="can('create shipment')" :customer="placeNote" @close="placeNote = false" />
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head, Link } from '@inertiajs/vue3'
import Pagination from '@/Components/Pagination.vue'
import NavButton from '@/Components/NavButton.vue'
import TicketStatusBadge from "@/Components/TicketStatusBadge.vue"
import {ref} from "vue";
import StatusBadge from "@/Components/StatusBadge.vue";
import UpdateStatusModal from "@/Pages/Orders/UpdateStatusModal.vue";
import CreateNoteModal from "@/Pages/Customers/CreateNoteModal.vue";

export default {
    components: {
        CreateNoteModal,
        UpdateStatusModal,
        StatusBadge,
        NavButton,
        Pagination,
        AuthenticatedLayout,
        Head,
        Link,
        TicketStatusBadge,
    },
    props: {
        customer: Object,
        orders: Object,
        oldSearchQuery: String,
    },
    data() {
        return {
            updateOrderStatus: false,
            placeNote: false,
            tabs: [
                { name: this.$t('customers.customer_details'), action: 'general' },
                { name: this.$t('customers.orders') + ' ('+ this.customer.customerStats.totalOrders +')', action: 'orders' },
                { name: this.$t('customers.returns'), action: 'returns' },
                { name: this.$t('customers.transactions') +' ('+ this.customer.customerStats.totalTransactions +')', action: 'transactions' },
                { name: this.$t('customers.notes') +' ('+ this.customer.customerStats.totalNotes +')', action: 'notes' },
                { name: this.$t('customers.tickets') +' ('+ this.customer.tickets.length +')', action: 'tickets' }
            ],
            currentTab: ref('general')
        }
    },
    methods: {
        search() {
            this.$inertia.get(
                route(route().current(), {customer: this.customer.id}),
                { query: this.searchQuery},
                {
                    preserveState: true
                }
            )
        }
    }
}
</script>
