<template>

    <Head :title="$t('coupons.all_coupons')" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex flex-wrap justify-between">
                <div>
                    <h1 class="text-2xl font-semibold text-gray-900">{{ $t('coupons.coupons') }}</h1>
                </div>
                <div>
                    <NavButton v-if="can('create coupons')" :href="route('coupons.create')">{{ $t('coupons.create') }}
                    </NavButton>
                </div>
            </div>
        </template>

        <Pagination class="mb-2" :links="coupons" />

        <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-4 lg:px-8">
                    <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col"
                                        class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        {{ $t('coupons.id') }}</th>
                                    <th scope="col"
                                        class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        {{ $t('coupons.code') }}</th>
                                    <th scope="col"
                                        class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        {{ $t('coupons.active') }}</th>
                                    <th scope="col"
                                        class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        {{ $t('coupons.description') }}</th>
                                    <th scope="col"
                                        class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        {{ $t('coupons.uses') }}</th>
                                    <th scope="col"
                                        class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        {{ $t('coupons.created') }}</th>
                                    <th scope="col"
                                        class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        {{ $t('coupons.updated') }}</th>
                                    <th scope="col"
                                        class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500" />
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="coupon in coupons.data" :key="coupon.id">
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        {{ coupon.id }}
                                    </td>
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        {{ coupon.code }}
                                    </td>
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        <StatusBadge :status="coupon.date_expires === null || dayjs().isBefore(dayjs(coupon.date_expires)) ? 'active' : 'inactive'"></StatusBadge>
                                    </td>
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        {{ coupon.description }}
                                    </td>
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        {{ removeZeroNumbers(coupon.usage_count) }}
                                    </td>
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        <div class="text-xs font-medium text-gray-900">
                                            {{ dayjs(coupon.created_at).format('LL') }}
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        {{ dayjs(coupon.updated_at).format('LL') }}
                                    </td>
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        <Link :href="route('coupons.show', { coupon: coupon.id })"
                                            class="text-indigo-600 hover:text-indigo-900">{{ $t('coupons.show') }}
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <Pagination class="mt-2" :links="coupons" />
    </AuthenticatedLayout>
</template>

<script setup>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import Pagination from '@/Components/Pagination.vue'
import { Head, Link } from '@inertiajs/vue3'
import NavButton from '@/Components/NavButton.vue';
import StatusBadge from '@/Components/StatusBadge.vue';

const props = defineProps({
    coupons: {
        type: Object,
    }
})

const removeZeroNumbers = (num) => {
    return num === 0 ? '' : num;
}
</script>