<template>
    <Head :title="name" />
    <AuthenticatedLayout>
        <template #header>
            <GenerateReportModal :type="type" :from="from" :to="to" :period="period" :show="showGenerateReportModal" @close="showGenerateReportModal = false"></GenerateReportModal>

            <div class="flex flex-wrap items-center justify-between">
                <div class="text-2xl font-semibold text-gray-900">{{ name }}
                    <div class="text-base text-gray-500">{{ dayjs(from).format('DD-MM-YYYY') }} — {{ dayjs(to).format('DD-MM-YYYY') }}</div>
                </div>

                <div class="space-x-2">
                    <Button type="button" @click="showGenerateReportModal = true">{{ $t('reports.generate_report') }}</Button>
                    <Button type="button" @click="downloadPdf">PDF</Button>
                </div>
            </div>
        </template>

        <slot />
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Button from '@/Components/Button.vue'
import GenerateReportModal from '@/Components/GenerateReportModal.vue'
import dayjs from "dayjs";

export default {
    components: {
        GenerateReportModal,
        Button,
        AuthenticatedLayout,
        Head
    },
    props: {
        name: String,
        type: String,
        from: String,
        to: String,
        period: String,
        reportData: Object
    },
    data() {
        return {
            showGenerateReportModal: false,
            downloadForm: {
                file_type: null,
                type: this.type,
                from: this.from,
                to: this.to,
                period: this.period
            }
        }
    },
    methods: {
        dayjs,
        downloadXlsx() {
            this.downloadForm.file_type = 'xlsx'
            window.location = this.route('report.index', this.downloadForm)
        },
        downloadPdf() {
            this.downloadForm.file_type = 'pdf'
            window.location = this.route('report.index', this.downloadForm)
        }
    }
}
</script>
