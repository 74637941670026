<template>
    <ReportLayout :name="$t('reports.incoming_shipments')" :type="type" :from="from" :to="to" :period="period">
        <div class="mb-6">
            <div class="sm:hidden">
                <label for="tabs" class="sr-only">Select a tab</label>
                <select id="tabs" v-model="currentTab" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                    <option v-for="tab in tabs" :key="tab.name" :selected="tab.action === currentTab">
                        {{ tab.name }}
                    </option>
                </select>
            </div>
            <div class="hidden sm:block">
                <nav class="relative z-0 flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
                    <button v-for="(tab, tabIdx) in tabs" :key="tab.name" :class="[tab.action === currentTab ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', tabIdx === 0 ? 'rounded-l-lg' : '', tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10']" :aria-current="tab.current ? 'page' : undefined" @click="currentTab = tab.action">
                        <span>{{ tab.name }}</span>
                        <span aria-hidden="true" :class="[tab.action === currentTab ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
                    </button>
                </nav>
            </div>
        </div>

        <div v-for="(data, tabKey) in reportData" v-show="currentTab === tabKey" :key="tabKey">
            <div v-for="(shipments, date) in data" :key="date">
                <h1 class="text-base font-bold">{{ date }}</h1>

                <div class="mt-2 flex flex-col">
                    <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table class="min-w-full divide-y divide-gray-300">
                                    <tbody class="divide-y divide-gray-200 bg-white">
                                        <template v-for="shipment in shipments" :key="shipment.id">
                                            <tr>
                                                <td class="whitespace-nowrap px-2 py-2">
                                                    <div class="text-lg font-bold text-gray-900">
                                                        {{ $t('reports.delivery') }}:
                                                        {{ shipment.name }}
                                                    </div>
                                                    <div class="text-base text-gray-800">
                                                        {{ $t('reports.shipper') }}:
                                                        {{ shipment.supplied_by_name }}
                                                    </div>
                                                    <div v-if="shipment.checked_by_name" class="text-base text-gray-800">
                                                        {{ $t('reports.checked_by') }}:
                                                        {{ shipment.checked_by_name }}
                                                    </div>
                                                </td>
                                                <td class="w-full whitespace-nowrap px-2 py-2 text-sm text-gray-500"></td>
                                            </tr>

                                            <template v-for="(products, productName) in shipment.products" :key="productName">
                                                <tr v-if="products.length >= 1">
                                                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900"></td>
                                                    <td class="w-full whitespace-nowrap px-2 py-2 text-base font-bold text-gray-900">
                                                        <div>
                                                            {{ $t('reports.product') }}:
                                                            {{ products[0]['product_name'] }}
                                                        </div>
                                                    </td>
                                                </tr>

                                                <template v-for="(product, childProductKey) in products" :key="childProductKey">
                                                    <tr>
                                                        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900"></td>
                                                        <td class="w-full whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                                            <div class="text-right text-base text-gray-900">
                                                                {{ $t('reports.numbers_packed') }}:
                                                                {{ product.numbers_packed }}
                                                                stuks
                                                            </div>
                                                            <div class="text-right">
                                                                {{ $t('reports.expected_quantity') }}:
                                                                {{ product.expected_quantity }}
                                                            </div>
                                                            <div v-if="shipment.received" class="text-right">
                                                                {{ $t('reports.actually_quantity') }}:
                                                                {{ product.actually_quantity }}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </template>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ReportLayout>
</template>

<script>
import ReportLayout from './Layout.vue'
import { ref } from 'vue'

export default {
    components: {
        ReportLayout
    },
    props: {
        type: String,
        from: String,
        to: String,
        period: String,
        reportData: Object
    },
    data() {
        const tabs = [
            { name: this.$t('reports.received_shipments'), action: 'received' },
            { name: this.$t('reports.not_received_shipments'), action: 'not_received' }
        ]

        const currentTab = ref('received')

        return {
            tabs,
            currentTab
        }
    }
}
</script>
