<template>
    <Head :title="$t('dashboard.system_check')" /> />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex flex-wrap items-center justify-between">
                <div>
                    <h1 class="block text-2xl font-semibold text-gray-900">{{ $t('dashboard.system_check')}}</h1>
                    <p class="max-w-md text-sm">{{ $t('dashboard.system_check_info') }}</p>
                </div>
                <div class="space-x-2">
                    <Button v-if="can('run system check')" @click="runSystemCheck">{{ $t('dashboard.system_check_button') }}</Button>
                </div>
            </div>
        </template>

        <div class="overflow-hidden bg-white shadow sm:rounded-lg">
            <div class="px-4 py-5 sm:p-0">
                <dl class="sm:divide-y sm:divide-gray-200">
                    <div v-for="(check, name) in checks" :key="name" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                            {{ name }}
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span v-if="check === 'Online'" class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">Online</span>
                            <span v-else-if="check === 'Offline'" class="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">Offline</span>
                            <span v-else>{{ check }}</span>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Button from '@/Components/Button.vue'

export default {
    components: {
        Button,
        AuthenticatedLayout,
        Head
    },
    props: {
        checks: Object
    },

    data() {
        return {
            confirmingStoreDeletion: false,
            form: this.$inertia.form({})
        }
    },

    methods: {
        runSystemCheck() {
            this.form.post(route('system-check.check'))
        }
    }
}
</script>
