<script>
import dayjs from 'dayjs'
import 'dayjs/locale/nl'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.locale('nl')
dayjs.extend(localizedFormat)

export default {
    methods: {
        dayjs,
        formatPrice(price) {
            const formatter = new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR'
            })
            return formatter.format(price)
        },

        formatNumber(number) {
            const formatter = new Intl.NumberFormat('nl-NL', {
                useGrouping: true,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            });
            return formatter.format(number);
        }
    }
}
</script>
