    <template>
        <Head :title="$t('retour.return_shipment') + ' - ' + order.extern_id" />

        <AuthenticatedLayout>
            <template #header>
                <h1 class="text-2xl font-semibold text-gray-900">{{ $t('retour.return_shipment') }} - {{ order.extern_id }}</h1>
            </template>

            <form method="POST" class="overflow-hidden bg-white p-6 shadow-sm sm:rounded-lg" @submit.prevent="createReturnShipment">
                <div class="grid grid-cols-6 gap-8">

                    <div class="col-span-6">
                        <Label for="amount" :value="$t('retour.amount_ex_vat')" />

                        <div class="flex">
                            <MoneyInput id="amount" v-model="form.amount" :min="0.01" :max="order.order_subtotal" class="mt-1 block flex-1" :include-tax="false" />
                        </div>
                        <ValidationError name="amount" />
                    </div>

                    <div class="col-span-6">
                        <Label for="reason" :value="$t('retour.reason')" />
                        <Textarea id="reason" v-model="form.reason" class="mt-1 block w-full" />

                        <ValidationError name="reason" />
                    </div>

                    <div class="col-span-6 space-x-2 text-right">
                        <NavButton :href="route('orders.show', { order: order.id })">{{ $t('retour.back') }}</NavButton>
                        <Button type="submit" :disabled="form.processing">{{ $t('retour.save') }}</Button>
                    </div>
                </div>
            </form>

        </AuthenticatedLayout>
    </template>

    <script>
    import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
    import { Head } from '@inertiajs/vue3'
    import NavButton from '@/Components/NavButton.vue'
    import Label from '@/Components/Label.vue'
    import MoneyInput from '@/Components/MoneyInput.vue'
    import Textarea from '@/Components/Textarea.vue'
    import ValidationError from '@/Components/ValidationError.vue'
    import Button from '@/Components/Button.vue'
    export default {
        components: {
            MoneyInput,
            Textarea,
            NavButton,
            Button,
            ValidationError,
            Label,
            AuthenticatedLayout,
            Head
        },
        props: {
            order: Object,
        },
        data() {
            return {
                form: this.$inertia.form({
                    amount: null,
                    reason: '',
                })
            }
        },
        methods: {
            createReturnShipment() {
                this.form.post(route('return-shipment.store', { order: this.order.id }), {
                    preserveScroll: true
                })
            },
        }
    }

    </script>
