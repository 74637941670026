<template>
    <Head :title="$t('retours.order_retour') + ' - ' + order.extern_id" />

    <AuthenticatedLayout>
        <template #header>
            <h1 class="text-2xl font-semibold text-gray-900">{{  $t('retours.order_retour') }} - {{ order.extern_id }}</h1>
        </template>

        <form method="POST" class="overflow-hidden bg-white p-6 shadow-sm sm:rounded-lg" @submit.prevent="saveRetour">
            <div class="grid grid-cols-6 gap-8">
                <div class="col-span-6">
                    <Label for="reason" value="Reden" />
                    <Textarea id="reason" v-model="form.reason" class="mt-1 block w-full" />
                    <ValidationError name="reason" />
                </div>

                <div class="col-span-6">
                    <div v-for="(retourItem, index) in form.retourItems" :key="index" class="grid grid-cols-10 gap-4 py-2">
                        <div class="col-span-1 flex items-center">
                            <Input :id="'retour_item-' + index + '-name'" v-model="retourItem.quantity" :max="retourItem.sent_quantity" type="number" class="mt-1 block w-full" />
                            <ValidationError :name="'retourItems.' + index + '.quantity'" />
                        </div>

                        <div class="col-span-3 flex items-center text-sm">
                            <div>
                                <div class="font-bold text-indigo-500">
                                    {{ retourItem.name }}
                                </div>
                                <div>Barcode: {{ retourItem.barcode }}</div>
                                <div>
                                    {{ $t('retour.numbers_sent') }}:
                                    {{ retourItem.sent_quantity }}
                                </div>
                            </div>
                        </div>

                        <div class="col-span-3 flex items-center text-sm">
                            <div>
                                <div>
                                    {{ $t('retour.single_price') }}:
                                    {{ formatPrice(retourItem.single_price) }}
                                </div>
                                <div>
                                    {{ $t('retour.bundle_total_price') }}:
                                    {{ formatPrice(retourItem.price) }}
                                </div>
                                <div>
                                    {{ $t('retour.pieces_in_bundle') }}:
                                    {{ retourItem.numbers_packed }} {{ $t('retour.pieces') }}
                                </div>
                            </div>
                        </div>

                        <div class="col-span-3 flex items-center justify-end text-right text-sm">
                            <div class="font-bold">
                                {{ $t('retour.retour_to_customer') }}:
                                {{ formatPrice(retourItem.price * retourItem.quantity) }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-span-6">
                    <h1 class="mb-1 text-xl font-bold text-gray-900">{{ $t('retour.costs') }}</h1>

                    <div class="mb-4 max-w-md text-sm">
                        {{ $t('retour.description_extra_costs') }}
                        <span v-if="form.extraCosts.length == 0">{{ $t('retour.no_costs') }}</span>
                    </div>

                    <div v-for="(extraCost, index) in form.extraCosts" :key="index" class="grid grid-cols-10 gap-4 py-2">
                        <div class="col-span-5">
                            <Label :for="'extra_cost-' + index + '-name'" :value="$t('retour.name')" />
                            <Input :id="'extra_cost-' + index + '-name'" v-model="extraCost.name" type="text" class="mt-1 block w-full" />
                            <ValidationError :name="'extraCosts.' + index + '.name'" />
                        </div>

                        <div class="col-span-5">
                            <Label :for="'extra_cost-' + index + '-price'" :value="$t('retour.price')" />

                            <div class="flex">
                                <Input :id="'extra_cost-' + index + '-price'" v-model="extraCost.price" type="number" class="mt-1 block flex-1" />
                                <Button class="ml-1" type="button" @click="deleteExtraCost(index)">X</Button>
                            </div>
                            <ValidationError :name="'extraCosts.' + index + '.price'" />
                        </div>
                    </div>

                    <Button type="button" @click="addExtraCost">{{ $t('retour.add_costs') }}</Button>
                </div>

                <div class="col-span-3">
                    <dl class="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
                        <div class="flex justify-between">
                            <dt>{{ $t('retour.original_sub_total') }}</dt>
                            <dd class="text-gray-900">
                                {{ formatPrice(order.subtotal) }}
                            </dd>
                        </div>

                        <div class="flex justify-between">
                            <dt>{{ $t('retour.original_vat') }} ({{ taxPercent }}%)</dt>
                            <dd class="text-gray-900">
                                {{ formatPrice(order.tax) }}
                            </dd>
                        </div>

                        <div class="flex justify-between">
                            <dt>{{ $t('retour.original_total') }}</dt>
                            <dd class="text-gray-900">
                                {{ formatPrice(order.total) }}
                            </dd>
                        </div>
                    </dl>
                </div>

                <div class="col-span-3">
                    <dl class="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
                        <div class="flex justify-between">
                            <dt>{{ $t('retour.new_sub_total') }}</dt>
                            <dd class="text-gray-900">
                                {{ formatPrice(subtotal) }}
                            </dd>
                        </div>

                        <div class="flex justify-between">
                            <dt>{{ $t('retour.new_vat') }} ({{ taxPercent }}%)</dt>
                            <dd class="text-gray-900">
                                {{ formatPrice(tax) }}
                            </dd>
                        </div>

                        <div class="flex justify-between">
                            <dt>{{ $t('retour.new_total') }}</dt>
                            <dd class="text-gray-900">
                                {{ formatPrice(total) }}
                            </dd>
                        </div>

                        <div class="flex justify-between">
                            <dt>{{ $t('retour.payback_to_client') }}</dt>
                            <dd class="text-gray-900">
                                {{ formatPrice(payToCustomer) }}
                            </dd>
                        </div>
                    </dl>
                </div>

                <div class="col-span-6 space-x-2 text-right">
                    <NavButton :href="route('orders.show', { order: order.id })">{{ $t('retour.back') }}</NavButton>
                    <Button type="submit" :disabled="form.processing">{{ $t('retour.save') }}</Button>
                </div>
            </div>
        </form>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import Button from '@/Components/Button.vue'
import ValidationError from '@/Components/ValidationError.vue'
import NavButton from '@/Components/NavButton.vue'
import Textarea from '@/Components/Textarea.vue'

export default {
    components: {
        Textarea,
        NavButton,
        Button,
        ValidationError,
        Input,
        Label,
        AuthenticatedLayout,
        Head
    },
    props: {
        order: Object,
        retourItems: Object
    },
    data() {
        return {
            form: this.$inertia.form({
                extraCosts: [],
                retourItems: []
            })
        }
    },

    computed: {
        taxPercent: function () {
            return Math.round(((this.order.total - this.order.subtotal) / this.order.subtotal) * 100)
        },
        subtotal: function () {
            return this.total / (this.taxPercent / 100 + 1)
        },
        tax: function () {
            return this.total - this.total / (this.taxPercent / 100 + 1)
        },
        total: function () {
            return this.order.total - this.calculateTotals()
        },
        payToCustomer: function () {
            return this.calculateTotals()
        }
    },

    created() {
        this.form.retourItems = this.retourItems
    },

    methods: {
        calculateTotals() {
            let total = 0
            const vm = this
            _.forEach(vm.form.retourItems, function (item) {
                total += parseFloat(item.quantity * item.price)
            })
            _.forEach(vm.form.extraCosts, function (item) {
                total += parseFloat(item.price ?? 0)
            })
            return total
        },

        saveRetour() {
            this.form.post(route('retours.store', { order: this.order.id }), {
                preserveScroll: true
            })
        },

        addExtraCost() {
            this.form.extraCosts.push({
                name: '',
                price: 5
            })
        },
        deleteExtraCost(index) {
            this.form.extraCosts.splice(index, 1)
        }
    }
}
</script>
