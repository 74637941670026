<template>
    <Head title="Gebruiker aanmaken" />

    <AuthenticatedLayout>
        <template #header>
            <h1 class="text-2xl font-semibold text-gray-900">Gebruiker aanmaken</h1>
        </template>

        <form method="POST" class="overflow-hidden bg-white p-6 shadow-sm sm:rounded-lg" @submit.prevent="createUser">
            <div class="grid grid-cols-6 gap-8">
                <div class="col-span-3">
                    <Label for="name" value="Naam" />
                    <Input id="name" v-model="form.name" type="text" class="mt-1 block w-full" required />
                    <ValidationError name="name" />
                </div>
                <div class="col-span-3">
                    <Label for="email" value="E-mailadres" />
                    <Input id="email" v-model="form.email" type="text" class="mt-1 block w-full" required />
                    <ValidationError name="email" />
                </div>
                <div class="col-span-3">
                    <Label for="password" value="Wachtwoord" />
                    <Input id="password" v-model="form.password" type="password" class="mt-1 block w-full" required />
                    <ValidationError name="password" />
                </div>
                <div class="col-span-3">
                    <Label for="password_confirmation" value="Bevestig Wachtwoord" />
                    <Input id="password_confirmation" v-model="form.password_confirmation" type="password" class="mt-1 block w-full" required />
                    <ValidationError name="password_confirmation" />
                </div>

                <div class="col-span-2">
                    <Label for="role" value="Rol" />
                    <select id="role" v-model="form.role" type="text" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                        <option v-for="role in roles" :key="role">
                            {{ role }}
                        </option>
                    </select>
                    <ValidationError name="role" />
                </div>

                <div class="col-span-2">
                    <Label for="department" value="Afdeling" />
                    <select id="department" v-model="form.department" type="text" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                        <option value="">Maak een keuze</option>
                        <option v-for="department in departments" :key="department.id" :value="department.id">
                            {{ department.name }}
                        </option>
                    </select>
                    <ValidationError name="department" />
                </div>

                <div class="col-span-2">
                    <Label for="status" value="Status" />
                    <select id="status" v-model="form.status" type="text" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                        <option value="1">Actief</option>
                        <option value="0">Inactief</option>
                    </select>
                    <ValidationError name="status" />
                </div>

                <div class="col-span-6 space-x-2 text-right">
                    <NavButton :href="route('users.index')">Terug</NavButton>
                    <Button type="submit" :disabled="form.processing">Opslaan</Button>
                </div>
            </div>
        </form>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import Button from '@/Components/Button.vue'
import ValidationError from '@/Components/ValidationError.vue'
import NavButton from '@/Components/NavButton.vue'

export default {
    components: {
        NavButton,
        Button,
        ValidationError,
        Input,
        Label,
        AuthenticatedLayout,
        Head
    },
    props: {
        roles: Array,
        departments: Array,
    },
    data() {
        return {
            form: this.$inertia.form({
                name: '',
                email: '',
                role: this.roles[0],
                department: '',
                password: '',
                password_confirmation: '',
                status: 1,
            })
        }
    },
    methods: {
        createUser() {
            this.form.post(route('users.store'), {
                preserveScroll: true
            })
        }
    }
}
</script>
