<template>
    <Head :title="$t('shipments.incoming_shipments_check')" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex items-center justify-between">
                <h1 class="text-2xl font-semibold text-gray-900">{{ $t('shipments.incoming_shipments') }}</h1>
                <div class="flex items-center space-x-2">
<!--                    <div class="flex rounded-md shadow-sm">-->
<!--                        <div class="relative flex flex-grow items-stretch focus-within:z-10">-->
<!--                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">-->
<!--                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">-->
<!--                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />-->
<!--                                </svg>-->
<!--                            </div>-->
<!--                            <input id="search" v-model="searchQuery" type="text" name="search" class="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="" />-->
<!--                        </div>-->
<!--                        <button type="button" class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500" @click="search">Zoeken</button>-->
<!--                    </div>-->

                    <NavButton v-if="can('create incoming shipment')" :href="route('incoming-shipments.create')">{{ $t('shipments.report_incoming_shipment') }}</NavButton>
                </div>
            </div>
        </template>

        <pagination class="mb-2" :links="incomingShipments" />

        <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('shipments.reference') }}</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('shipments.status') }}</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('shipments.supplier') }}</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('shipments.checked_by') }}</th>
                                    <th scope="col" class="relative px-6 py-3">
                                        <span class="sr-only">{{ $t('shipments.view') }}</span>
                                        <span class="sr-only">{{ $t('shipments.edit') }}</span>
                                        <span class="sr-only">{{ $t('shipments.delete') }}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="incomingShipment in incomingShipments.data" :key="incomingShipment.id">
                                    <td class="whitespace-nowrap px-6 py-4">
                                        <div class="text-sm font-medium text-gray-900">
                                            {{ incomingShipment.reference }}
                                        </div>
                                        <div class="text-sm text-gray-500">
                                            {{ incomingShipment.products_count }}
                                            {{ $t('shipments.different_products') }}
                                        </div>
                                    </td>

                                    <td class="whitespace-nowrap px-6 py-4">
                                        <status-badge :status="incomingShipment.status"></status-badge>
                                    </td>

                                    <td class="whitespace-nowrap px-6 py-4">
                                        <div v-if="incomingShipment.supplied_by" class="text-sm font-medium text-gray-900">
                                            {{ incomingShipment.supplied_by.name }}
                                        </div>
                                        <div v-if="incomingShipment.supplied_by" class="text-sm text-gray-500">
                                            {{ incomingShipment.supplied_by.email }}
                                        </div>
                                    </td>

                                    <td class="whitespace-nowrap px-6 py-4">
                                        <div v-if="incomingShipment.checked_by" class="text-sm font-medium text-gray-900">
                                            {{ incomingShipment.checked_by.name }}
                                        </div>
                                        <div v-if="incomingShipment.checked_by" class="text-sm text-gray-500">
                                            {{ incomingShipment.checked_by.email }}
                                        </div>
                                    </td>

                                    <td class="space-x-4 whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
                                        <Link
                                            v-if="can('check incoming shipment') && incomingShipment.status !== 'Completed'"
                                            :href="
                                                route('incoming-shipments.check', {
                                                    incoming_shipment: incomingShipment.id
                                                })
                                            "
                                            class="text-indigo-600 hover:text-indigo-900"
                                        >
                                            {{ $t('shipments.check') }}
                                        </Link>

                                        <Link
                                            v-if="can('view incoming shipment')"
                                            :href="
                                                route('incoming-shipments.show', {
                                                    incoming_shipment: incomingShipment.id
                                                })
                                            "
                                            class="text-indigo-600 hover:text-indigo-900"
                                        >
                                            {{ $t('shipments.view') }}
                                        </Link>
                                        <Link
                                            v-if="can('edit incoming shipment') && incomingShipment.status !== 'Completed'"
                                            :href="
                                                route('incoming-shipments.edit', {
                                                    incoming_shipment: incomingShipment.id
                                                })
                                            "
                                            class="text-indigo-600 hover:text-indigo-900"
                                        >
                                            {{ $t('shipments.edit') }}
                                        </Link>
                                        <button v-if="can('cancel incoming shipment') && incomingShipment.status !== 'Completed'" class="text-indigo-600 hover:text-indigo-900" @click="confirmingIncomingShipmentCancelation = incomingShipment">
                                            {{ $t('shipments.cancel') }}
                                        </button>
                                    </td>
                                </tr>
                                <tr v-if="incomingShipments.data.length == 0">
                                    <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900" colspan="5">{{ $t('shipments.no_results_found') }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <pagination class="mt-2" :links="incomingShipments" />

        <ConfirmationModal v-if="can('cancel incoming shipment')" :show="confirmingIncomingShipmentCancelation != null" @close="confirmingIncomingShipmentCancelation = null">
            <template #title>{{ $t('shipments.cancel') }}</template>

            <template #content>{{ $t('shipments.confirm_cancel') }}</template>

            <template #footer>
                <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="cancelIncomingShipment">{{ $t('shipments.yes') }}</button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="confirmingIncomingShipmentCancelation = null">{{ $t('shipments.no') }}</button>
            </template>
        </ConfirmationModal>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head, Link } from '@inertiajs/vue3'
import NavButton from '@/Components/NavButton.vue'
import ConfirmationModal from '@/Components/ConfirmationModal.vue'
import StatusBadge from '@/Components/StatusBadge.vue'
import Pagination from '@/Components/Pagination.vue'

export default {
    components: {
        Pagination,
        StatusBadge,
        ConfirmationModal,
        NavButton,
        AuthenticatedLayout,
        Head,
        Link
    },
    props: {
        incomingShipments: Array,
        oldSearchQuery: String
    },
    data() {
        return {
            confirmingIncomingShipmentCancelation: null,
            form: this.$inertia.form({}),
            searchQuery: this.oldSearchQuery
        }
    },
    methods: {
        cancelIncomingShipment() {
            this.form.delete(
                route('incoming-shipments.cancel', {
                    incoming_shipment: this.confirmingIncomingShipmentCancelation.id
                })
            )
            this.confirmingIncomingShipmentCancelation = null
        },
        search() {
            this.$inertia.get(
                route(route().current()),
                { query: this.searchQuery },
                {
                    preserveState: true
                }
            )
        }
    }
}
</script>
