<template>
    <Head :title="'Winkel - ' + store.name" />
    <AuthenticatedLayout>
        <template #header>
            <div class="flex flex-wrap items-center justify-between">
                <h1 class="text-2xl font-semibold text-gray-900">{{ $t('stores.store') }} - {{ store.name }}</h1>
                <div class="space-x-2">
                    <NavButton v-if="can('view all store roles')" :href="route('stores.roles.index', { store: store.id })">{{ $t('menu.roll') }}</NavButton>
                    <NavButton v-if="can('edit store')" :href="route('stores.edit', { store: store.id })">{{ $t('stores.edit') }}</NavButton>
                    <Button v-if="can('delete store')" @click="confirmingStoreDeletion = true">{{ $t('stores.delete') }}</Button>
                    <Button v-if="can('sync store')" @click="syncStore">{{ $t('stores.sync') }}</Button>
                </div>
            </div>
        </template>
        <div class="grid grid-cols-12 gap-8">
            <div class="col-span-12">
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('stores.name') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ store.name }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('stores.shop_type')}}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ store.shop_type }}
                                </dd>
                            </div><div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('stores.site_url')}}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <a :href="store.site_url" class="hover:text-indigo-900">
                                        {{ store.site_url }}
                                    </a>
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('stores.status')}}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <status-badge :status="store.status"></status-badge>

                                    <span class="ml-2">
                                        Laatst controleerd:
                                        {{ dayjs(store.last_health_check).format('lll') }}
                                    </span>
                                </dd>
                            </div>
                            <div v-if="store.api_error" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('stores.api_error_message')}}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <code class="rounded-lg border bg-gray-100 px-2 py-1 text-xs">
                                        {{ store.api_error }}
                                    </code>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

            <div class="col-span-12">
                <div class="mb-2 flex items-center justify-between">
                    <h1 class="text-xl font-bold text-gray-900">{{ $t('stores.profit_sharing_percentage')}}</h1>
                    <div>
                        <NavButton v-if="can('edit store')" :href="route('stores.edit', { store: store.id })">{{ $t('stores.add_shareholder')}}</NavButton>
                    </div>
                </div>
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <table class="w-full text-gray-500">
                        <thead class="text-left text-sm text-gray-500">
                            <tr>
                                <th scope="col" class="py-6 px-6 font-normal">{{ $t('stores.user')}}</th>
                                <th scope="col" class="py-6 px-6 font-normal">{{ $t('stores.type_of_profit_sharing') }}</th>
                                <th scope="col" class="py-6 px-6 font-normal">{{ $t('stores.number') }}</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 border-b border-gray-200 text-sm sm:border-t">
                            <tr v-for="user in currentUsersProfitSharing" :key="user.email">
                                <td class="py-6 px-6">
                                    <div>
                                        <div class="font-medium text-gray-900">
                                            {{ user.name }}
                                        </div>
                                        <div>{{ user.email }}</div>
                                    </div>
                                </td>
                                <td class="py-6 px-6">
                                    {{ user.profit_sharing_type }}
                                </td>
                                <td class="py-6 px-6">
                                    {{ user.profit_sharing_value }}
                                </td>
                            </tr>
                            <tr v-if="currentUsersProfitSharing.length === 0">
                                <td class="py-2 px-6">{{ $t('stores.no_results_found') }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="col-span-12">
                <div class="mb-2 flex items-center justify-between">
                    <h1 class="text-xl font-bold text-gray-900">{{ $t('stores.users') }}</h1>
                    <div>
                        <NavButton v-if="can('edit store')" :href="route('stores.edit', { store: store.id })">{{ $t('stores.add_user') }}</NavButton>
                    </div>
                </div>
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <table class="w-full text-gray-500">
                        <thead class="text-left text-sm text-gray-500">
                            <tr>
                                <th scope="col" class="py-6 px-6 font-normal">{{ $t('stores.user') }}</th>
                                <th scope="col" class="py-6 px-6 font-normal">{{ $t('stores.role') }}</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 border-b border-gray-200 text-sm sm:border-t">
                            <tr v-for="user in currentUsers" :key="user.email">
                                <td class="py-6 px-6">
                                    <div>
                                        <div class="font-medium text-gray-900">
                                            {{ user.name }}
                                        </div>
                                        <div>{{ user.email }}</div>
                                    </div>
                                </td>
                                <td class="py-6 px-6">
                                    {{ user.role }}
                                </td>
                            </tr>
                            <tr v-if="currentUsers.length === 0">
                                <td class="py-2 px-6">{{ $t('stores.no_results_found') }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="col-span-12">
                <div class="mb-2 flex items-center justify-between">
                    <h1 class="text-xl font-bold text-gray-900">{{ $t('stores.products') }}</h1>
                    <div></div>
                </div>
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <table class="w-full text-gray-500">
                        <thead class="sr-only text-left text-sm text-gray-500 sm:not-sr-only">
                            <tr>
                                <th scope="col" class="px-6 py-3 font-normal sm:w-2/5 lg:w-1/3">{{ $t('stores.product') }}</th>
<!--                                <th scope="col" class="hidden w-1/5 px-6 py-3 font-normal sm:table-cell">Prijs</th>-->
                                <th scope="col" class="hidden px-6 py-3 font-normal sm:table-cell">{{ $t('stores.description') }}</th>
                                <th scope="col" class="px-6 py-3 font-normal text-right">{{ $t('stores.actions') }}</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 border-b border-gray-200 text-sm sm:border-t">
                            <tr v-for="product in store.products" :key="product.id">
                                <td class="py-6 px-6">
                                    <div class="flex items-center">
                                        <img v-if="product.image" :src="'/storage/' + product.image" :alt="product.name" class="mr-6 h-16 w-16 rounded object-cover object-center" />
                                        <div>
                                            <div class="font-medium text-gray-900">
                                                {{ product.name }}
                                            </div>
<!--                                            <div class="mt-1 sm:hidden">-->
<!--                                                Verkoopprijs:-->
<!--                                                {{ formatPrice(product.price) }}-->
<!--                                            </div>-->
<!--                                            <div v-if="product.cost_price" class="mt-1 sm:hidden">-->
<!--                                                Kostprijs:-->
<!--                                                {{ formatPrice(product.cost_price) }}-->
<!--                                            </div>-->
<!--                                            <div v-if="product.users.length >= 1" class="mt-4">-->
<!--                                                <div class="font-bold">Aandeelhouders:</div>-->

<!--                                                <ol>-->
<!--                                                    <li v-for="user in product.users" :key="user.email">-->
<!--                                                        <span :aria-label="user.email" data-balloon-pos="down">-->
<!--                                                            {{ user.name }}-->
<!--                                                        </span>-->
<!--                                                        - -->
<!--                                                        <span v-if="user.profit_sharing_type == 'Fixed'">-->
<!--                                                            {{ formatPrice(user.profit_sharing_value) }}-->
<!--                                                            per stuk-->
<!--                                                        </span>-->
<!--                                                        <span v-else>{{ user.profit_sharing_value }}% per stuk</span>-->
<!--                                                    </li>-->
<!--                                                </ol>-->
<!--                                            </div>-->
                                        </div>
                                    </div>
                                </td>
<!--                                <td class="hidden py-6 px-6 sm:table-cell">-->
<!--                                    <div>-->
<!--                                        Vanaf:-->
<!--                                        {{ formatPrice(product.price) }}-->
<!--                                    </div>-->
<!--&lt;!&ndash;                                    <div v-if="product.cost_price">&ndash;&gt;-->
<!--&lt;!&ndash;                                        Kostprijs vanaf:&ndash;&gt;-->
<!--&lt;!&ndash;                                        {{ formatPrice(product.cost_price) }}&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                                </td>-->
                                <td class="hidden py-6 px-6 sm:table-cell">
                                    {{ product.short_description_stripped }}
                                </td>
                                <td class="py-6 px-6 text-right">
                                    <div>
                                        <Link
                                            v-if="can('view product')"
                                            :href="
                                                route('products.show', {
                                                    product: product.id,
                                                    store: store.id
                                                })
                                            "
                                            class="text-indigo-600 hover:text-indigo-900"
                                        >
                                            {{ $t('stores.show') }}
                                        </Link>
                                    </div>
                                    <div>
                                        <Link
                                            v-if="can('edit product')"
                                            :href="
                                                route('products.edit', {
                                                    product: product.id,
                                                    store: store.id
                                                })
                                            "
                                            class="text-indigo-600 hover:text-indigo-900"
                                        >
                                            {{ $t('stores.edit') }}
                                        </Link>
                                    </div>
<!--                                    <div>-->
<!--                                        <button v-if="can('delete product')" class="text-indigo-600 hover:text-indigo-900" @click="confirmingProductDeletion = product">Verwijderen</button>-->
<!--                                    </div>-->
                                </td>
                            </tr>
                            <tr v-if="store.products.length === 0">
                                <td class="py-2 px-6">Geen resultaten gevonden</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <ConfirmationModal v-if="can('delete store')" :show="confirmingStoreDeletion" @close="confirmingStoreDeletion = false">
            <template #title>Winkel verwijderen - {{ store.name }}</template>
            <template #content>Weet je zeker dat je deze winkel wilt verwijderen? Als je deze winkel verwijderd worden alle gegevens, inclusief bestellingen, van ons systeem verwijderd!</template>
            <template #footer>
                <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="deleteStore">Winkel verwijderen</button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="confirmingStoreDeletion = false">Annuleren</button>
            </template>
        </ConfirmationModal>

        <ConfirmationModal v-if="can('delete product')" :show="confirmingProductDeletion != null" @close="confirmingProductDeletion = null">
            <template #title>
                Product verwijderen
                <span v-if="confirmingProductDeletion != null">- {{ confirmingProductDeletion.name }}</span>
            </template>

            <template #content>Weet je zeker dat je dit product wilt verwijderen? Verwijder dit product alleen als deze ook is verwijderd op de Bagisto installatie.</template>

            <template #footer>
                <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="deleteProduct">Product verwijderen</button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="confirmingProductDeletion = null">Annuleren</button>
            </template>
        </ConfirmationModal>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head, Link } from '@inertiajs/vue3'
import Button from '@/Components/Button.vue'
import NavButton from '@/Components/NavButton.vue'
import ConfirmationModal from '@/Components/ConfirmationModal.vue'
import StatusBadge from '@/Components/StatusBadge.vue'

export default {
    components: {
        StatusBadge,
        ConfirmationModal,
        NavButton,
        Button,
        AuthenticatedLayout,
        Head,
        Link
    },
    props: {
        store: Object,
        currentUsers: Array,
        currentUsersProfitSharing: Array,
    },
    data() {
        return {
            confirmingStoreDeletion: false,
            form: this.$inertia.form({})
        }
    },
    methods: {
        deleteStore() {
            this.form.delete(
                route('stores.destroy', {
                    store: this.store.id
                })
            )
        },
        deleteProduct() {
            this.form.delete(
                route('products.destroy', {
                    store: this.store.id,
                    product: this.confirmingProductDeletion.id
                })
            )
            this.confirmingProductDeletion = null
        },
        syncStore() {
            this.form.post(
                route('stores.sync', {
                    store: this.store.id
                })
            )
        }
    }
}
</script>
