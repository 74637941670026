<template>
    <Head title="Dashboard" />

    <AuthenticatedLayout>
        <template #header></template>

        <div class="py-32 md:py-64">
            <div class="text-center">
                <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
                <h3 class="mt-2 text-lg font-semibold text-gray-900">{{ $t('dashboard.no_shop_chosen') }}</h3>

                <p class="mx-auto mt-1 max-w-md text-base text-gray-500">{{ $t('dashboard.shop_help') }}</p>
            </div>
        </div>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'

export default {
    components: {
        AuthenticatedLayout,
        Head
    }
}
</script>
