<template>
    <Head :title="$t('orders.order_pack') +' - ' + order.number" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex items-center justify-between">
                <h1 class="text-2xl font-semibold text-gray-900">{{ $t('orders.order_pack')}} - {{ order.number }}</h1>

                <div class="mt-6 flex items-center justify-center space-x-4">
                    <NavButton v-if="can('view order')" :href="route('orders.show', { order: order.id })">{{ $t('orders.to_order') }}</NavButton>

                    <button class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-red-600 focus:border-red-900 focus:outline-none active:bg-red-900" @click="openDenyOrder = true">{{ $t('orders.to_order') }}</button>
                    <button v-if="can('create shipment')" class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-green-600 focus:border-green-900 focus:outline-none active:bg-green-900" @click="openPackModal = true">{{ $t('orders.pack_and_send') }}</button>
                </div>
            </div>
        </template>
        <div>
            <div class="grid grid-cols-12 gap-8">
                <div class="col-span-12 md:col-span-4">
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:p-0">
                            <dl class="sm:divide-y sm:divide-gray-200">
                                <div class="py-4 sm:py-5 sm:px-6">
                                    <h3 class="text-lg font-semibold">{{ $t('orders.order_info') }}</h3>
                                </div>
                                <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">{{ $t('orders.id') }}</dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ order.id }}</dd>
                                </div>
                                <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">{{ $t('orders.external_id') }}</dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ order.extern_id }}</dd>
                                </div>
                                <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">{{ $t('orders.ordered_on') }}</dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ dayjs(order.ordered_at).format('lll') }}</dd>
                                </div>

                                <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">{{ $t('orders.shipping_method') }}</dt>
                                    <dd class="text-m font-bold text-gray-900 sm:col-span-2" style="margin-top:-2px">{{ order.shippingMethod.name }}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>

                <div class="col-span-12 md:col-span-4">
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:p-0">
                            <dl class="sm:divide-y sm:divide-gray-200">
                                <div class="py-4 sm:py-5 sm:px-6">
                                    <h3 class="text-lg font-semibold">{{ $t('orders.deliver_info') }}</h3>
                                </div>
                                <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">{{ $t('orders.deliver_address') }}</dt>
                                    <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                        <div class="text-sm font-medium text-gray-900">
                                            <div v-if="order.shipping_company_name" class="font-bold">
                                                {{ order.shipping_company_name }}
                                            </div>
                                            <div>
                                                {{ order.shipping_firstname }}
                                                {{ order.shipping_lastname }}
                                            </div>
                                        </div>
                                        <div class="text-sm text-gray-500">
                                            <div>
                                                {{ order.shipping_address }}
                                            </div>

                                            <div>
                                                {{ order.shipping_postcode }}
                                                {{ order.shipping_city }}
                                                {{ order.shipping_country }}
                                            </div>
                                        </div>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>


                <div class="col-span-12 md:col-span-4">
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:p-0">
                            <dl>
                                <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                                    <h3 class="text-lg font-semibold">{{ $t('orders.shipments') }}</h3>
                                </div>

                                <div class="flex flex-col">
                                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                            <div class="overflow-hidden">
                                                <table class="min-w-full">
                                                    <tbody>
                                                    <tr v-for="shipment in order.shipments" :key="shipment.id" class="border-b border-gray-200 bg-white">
                                                        <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                                            <span v-if="!shipment.error">
                                                                {{ shipment.barcode ?? 'Geen barcode' }}
                                                            </span>
                                                            <span v-if="shipment.error" class="text-red-500">
                                                                {{ $t('orders.shipment_error') }}
                                                            </span>
                                                        </td>


                                                        <td class="flex items-center justify-end space-x-1 whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                                            <a
                                                                v-if="can('download shipment') && shipment.label_file"
                                                                :href="
                                                                    route('shipment.download', {
                                                                        order: order.id,
                                                                        shipment: shipment.id,
                                                                        type: 'label'
                                                                    })
                                                                "
                                                                class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-800 p-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-gray-700 focus:border-gray-900 focus:outline-none active:bg-gray-900"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                    <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
                                                                </svg>
                                                            </a>

                                                            <a
                                                                v-if="can('download shipment') && shipment.packing_file"
                                                                :href="
                                                                    route('shipment.download', {
                                                                        order: order.id,
                                                                        shipment: shipment.id,
                                                                        type: 'packing'
                                                                    })
                                                                "
                                                                class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-800 p-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-gray-700 focus:border-gray-900 focus:outline-none active:bg-gray-900"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                                                                </svg>
                                                            </a>
                                                            <a
                                                                v-if="can('cancel shipment')"
                                                                :href="
                                                                    route('shipment.cancel', {
                                                                        order: order.id,
                                                                        shipment: shipment.id
                                                                    })
                                                                "
                                                                class="focus:shadow-outline-red inline-flex items-center rounded-md border border-transparent bg-red-500 p-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-red-700 focus:border-red-900 focus:outline-none active:bg-red-900"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                </svg>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="py-4 sm:py-5 sm:px-6">
                                    <div>
                                    <span v-if="order.shipments.length == 0" class="text-sm text-gray-500">
                                        {{ $t('orders.no_shipments') }}
                                    </span>
                                    </div>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>

                <div class="col-span-12">
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:p-0">
                            <dl class="sm:divide-y sm:divide-gray-200">
                                <div class="py-4 sm:py-5 sm:px-6">
                                    <h3 class="text-lg font-semibold">{{ $t('orders.products') }}</h3>
                                </div>
                                <div class="w-full md:w-auto">
                                    <table class="w-full divide-y divide-gray-300">
                                        <thead class="bg-gray-50">
                                        <tr class="divide-x divide-gray-200">
                                            <th scope="col" class="w-1/4 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                                            <th scope="col" class="w-1/2 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6">{{ $t('orders.product') }}</th>
                                            <th scope="col" class="w-1/4 px-4 py-3.5 text-left text-sm font-semibold text-gray-900">{{ $t('orders.need') }}</th>
                                        </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-200">
                                        <tr v-for="item in order.items" :key="item.id" class="divide-x divide-gray-200 even:bg-gray-100">
                                            <td class="w-1/4 whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                                                <img v-if="item.product.image" :src="item.product.image" :alt="item.name" class="mr-6 h-30 w-50 rounded object-cover object-center" />
                                            </td>
                                            <td class="w-1/2 whitespace-nowrap py-4 pl-4 pr-4 text-right text-xl font-bold text-gray-900 sm:pl-6">{{ item.name }}</td>
                                            <td class="w-1/4 whitespace-nowrap p-4 text-xl font-bold text-gray-500">{{ item.quantity }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmationModal v-if="can('create shipment')" :show="openPackModal" @close="openPackModal = false">
                <template #title>{{ $t('orders.products_present') }}</template>
                <template #icon>
                    <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <ClipboardIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                </template>

                <template #content>
                    <div class="w-full md:w-auto">
                        <table class="w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                            <tr class="divide-x divide-gray-200">
                                <th scope="col" class="w-1/2 py-3.5 pl-4 pr-4 text-right text-sm font-bold   text-gray-900 sm:pl-6">{{ $t('orders.product') }}</th>
                                <th scope="col" class="w-1/4 px-4 py-3.5 text-left text-sm font-bold text-gray-900">{{ $t('orders.need') }}</th>
                            </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="item in order.items" :key="item.id" class="divide-x divide-gray-200 even:bg-gray-100">
                                <td class="w-1/2 whitespace-nowrap py-4 pl-4 pr-4 text-right text-xl font-bold text-gray-900 sm:pl-6">{{ item.name }}</td>
                                <td class="w-1/4 whitespace-nowrap p-4 text-xl text-left font-bold text-gray-900">{{ item.quantity }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </template>

                <template #footer>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-red-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="openPackModal = false; openItemsModal = true; packAndShipForm.all_items = false">{{ $t('orders.no') }}</button>
                    <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-green-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="openPackModal = false; openShipmentModal = true; packAndShipForm.all_items = true">{{ $t('orders.yes') }}</button>
                </template>
            </ConfirmationModal>

            <ConfirmationModal v-if="can('create shipment')" :show="openItemsModal" @close="openItemsModal = false">
                <template #title>{{ $t('orders.what_cant_shipped') }}</template>
                <template #icon>
                    <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <ClipboardIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                </template>

                <template #content>
                    <div class="w-full md:w-auto">
                        <table class="w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                            <tr class="divide-x divide-gray-200">
                                <th scope="col" class="w-5/12 py-3.5 pl-4 pr-4 text-right text-sm font-bold text-gray-900 sm:pl-6">{{ $t('orders.product') }}</th>
                                <th scope="col" class="w-1/4 px-4 py-3.5 text-left text-sm font-bold text-gray-900">{{ $t('orders.need') }}</th>
                                <th scope="col" class="w-1/4 px-4 py-3.5 text-left text-sm font-bold text-gray-900">{{ $t('orders.deliverable') }}</th>
                            </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(item, index) in order.items" :key="item.id" class="divide-x divide-gray-200 even:bg-gray-100">
                                    <td class="w-5/12 whitespace-nowrap py-4 pl-4 pr-4 text-right text-m font-bold text-gray-900 sm:pl-6">{{ item.name }}</td>
                                    <td class="w-1/4 whitespace-nowrap p-4 text-m text-left font-bold text-gray-900">{{ item.quantity }}</td>
                                    <td class="w-1/4 whitespace-nowrap p-4 text-m text-left font-bold text-gray-900">
                                        <Input
                                            id="packed_items"
                                            v-model="item.packed_items"
                                            type="text"
                                            :max="item.quantity"
                                            :class="[    'mt-1 block w-full',    validatePackedItems(item) && typeof $page.props.errors['items.' + index + '.packed_items'] == 'undefined' ? '' : 'border-red-500']"
                                            required
                                            @input="validatePackedItems(item) || toast.error($t('orders.cant_deliver_items'))"
                                        />

                                        <ValidationError :name="'items.' + index + '.packed_items'" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>

                <template #footer>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="openItemsModal = false; openPackModal = true">{{ $t('orders.cancel') }}</button>
                    <button
                        type="button"
                        :disabled="!isFormValid"
                        class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-green-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                        @click="openShipmentModal = true; openItemsModal = false;"
                    >{{ $t('orders.next') }}</button>
                </template>
            </ConfirmationModal>

            <ConfirmationModal v-if="can('create shipment')" :show="openShipmentModal" @close="openShipmentModal = false">
                <template #title>{{ $t('orders.order_pack_and_ship') }} - {{ order.extern_id }}</template>

                <template #icon>
                    <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                </template>

                <template #content>
                    <div class="grid grid-cols-12 gap-4 text-left">
                        <div class="col-span-12">{{ $t('orders.sure_order_pack_and_ship') }}</div>
                        <div class="col-span-12">
                            <Label for="company_name" :value="$t('orders.company_name')" />
                            <Input id="company_name" v-model="packAndShipForm.company_name" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="company_name" />
                        </div>
                        <div class="col-span-6">
                            <Label for="firstname">{{ $t('orders.first_name') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="firstname" v-model="packAndShipForm.firstname" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="firstname" />
                        </div>
                        <div class="col-span-6">
                            <Label for="lastname">{{ $t('orders.last_name') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="lastname" v-model="packAndShipForm.lastname" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="lastname" />
                        </div>
                        <div class="col-span-6">
                            <Label for="email">{{ $t('orders.email') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="email" v-model="packAndShipForm.email" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="email" />
                        </div>
                        <div class="col-span-6">
                            <Label for="phone">{{ $t('orders.tel') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="phone" v-model="packAndShipForm.phone" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="phone" />
                        </div>
                        <div class="col-span-7">
                            <Label for="street_name">{{ $t('orders.street') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="street_name" v-model="packAndShipForm.street_name" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="street_name" />
                        </div>
                        <div class="col-span-3">
                            <Label for="house_number">{{ $t('orders.house_no') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="house_number" v-model="packAndShipForm.house_number" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="house_number" />
                        </div>
                        <div class="col-span-2">
                            <Label for="house_number_ext">{{ $t('orders.suffix') }}</label>
                            <Input id="house_number_ext" v-model="packAndShipForm.house_number_ext" type="text" class="mt-1 block w-full" />
                            <ValidationError name="house_number_ext" />
                        </div>
                        <div class="col-span-4">
                            <Label for="postcode">{{ $t('orders.postal') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="postcode" v-model="packAndShipForm.postcode" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="postcode" />
                        </div>

                        <div class="col-span-4">
                            <Label for="city">{{ $t('orders.city') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="city" v-model="packAndShipForm.city" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="city" />
                        </div>

                        <div class="col-span-4">
                            <Label for="country">{{ $t('orders.country') }} <span class="text-red-500 text-xs text-bold">*</span></label>
                            <Input id="country" v-model="packAndShipForm.country" type="text" class="mt-1 block w-full" required disabled/>
                            <ValidationError name="country" />
                        </div>
                    </div>
                </template>

                <template #footer>
                    <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="packOrderAndShip()">{{ $t('orders.order_pack') }}</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="openShipmentModal = false; openPackModal = true">{{ $t('orders.back') }}</button>
                </template>
            </ConfirmationModal>

            <ConfirmationModal :show="openDenyOrder" @close="openDenyOrder = false">
                <template #title>{{ $t('orders.order_to_check') }} - {{ order.extern_id }}</template>

                <template #content>
                    {{ $t('orders.sure_order_to_check') }}

                    <div class="mt-4 text-left">
                        <Label for="packing_denied_message" :value="$t('orders.reason')" />
                        <Textarea id="packing_denied_message" v-model="denyForm.packing_denied_message" class="mt-1 block w-full" />
                        <ValidationError name="packing_denied_message" />
                    </div>
                </template>

                <template #footer>
                    <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="denyOrder()">{{ $t('orders.yes') }}</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="openDenyOrder = false">{{ $t('orders.no') }}</button>
                </template>
            </ConfirmationModal>
        </div>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import NavButton from '@/Components/NavButton.vue'
import { useToast } from 'vue-toastification'
import Input from '@/Components/Input.vue'
import ConfirmationModal from '@/Components/ConfirmationModal.vue'
import { CheckIcon, ClipboardIcon } from '@heroicons/vue/24/outline'
import ValidationError from '@/Components/ValidationError.vue'
import Label from '@/Components/Label.vue'

export default {
    components: {
        Label,
        ValidationError,
        NavButton,
        AuthenticatedLayout,
        Head,
        Input,
        ConfirmationModal,
        CheckIcon,
        ClipboardIcon
    },
    props: {
        order: Object,
        packItems: Object
    },
    data() {
        return {
            toast: useToast(),

            packItemsFinal: [],

            openPackOrderAndShip: false,
            openPackOrder: false,
            openDenyOrder: false,
            createShipment: false,

            openPackModal: false,
            openItemsModal: false,
            openShipmentModal: false,

            denyForm: this.$inertia.form({
                packing_denied_message: ''
            }),

            packForm: this.$inertia.form({
                items: null
            }),

            packAndShipForm: this.$inertia.form({
                items: null,
                all_items: true,
                order_number: '',
                company_name: null,
                firstname: null,
                lastname: null,
                email: null,
                phone: null,
                street_name: null,
                house_number: null,
                house_number_ext: null,
                postcode: null,
                city: null,
                country: null,
                carrier: 'PostNL',
                carrier_code: 3085,
                weight: 100,
                height: 10,
                volume: 10,
                width: 10,
                length: 18,

                type: null,
            }),
        }
    },

    computed: {
        isFormValid() {
            return this.order.items.every((item) => this.validatePackedItems(item));
        },
    },

    created() {

        this.packItemsFinal = this.order.items

        this.packAndShipForm.order_number = this.order? this.order.number : null
        this.packAndShipForm.company_name = this.order? this.order.company_name : null
        this.packAndShipForm.firstname = this.order ? this.order.shipping_firstname : null
        this.packAndShipForm.lastname = this.order ? this.order.shipping_lastname : null
        this.packAndShipForm.email = this.order ? this.order.email : null
        this.packAndShipForm.phone = this.order ? this.order.shipping_address.phone : null
        this.packAndShipForm.street_name = this.order ? this.order.splitted_shipping_address.street_name : null
        this.packAndShipForm.house_number = this.order ? this.order.splitted_shipping_address.house_number : null
        this.packAndShipForm.house_number_ext = this.order ? this.order.splitted_shipping_address.house_number_ext : null
        this.packAndShipForm.postcode = this.order ? this.order.shipping_postcode : null
        this.packAndShipForm.city = this.order ? this.order.shipping_city : null
        this.packAndShipForm.country = this.order ? this.order.shipping_country : null
        this.packAndShipForm.type = this.order ? this.order.shippingMethod.bagisto_name : null
    },

    methods: {

        packOrderAndShip() {
            this.packAndShipForm.items = this.order.items
            this.openShipmentModal = false

            this.packAndShipForm.put(
                route('orders.save-pack-and-ship', {
                    order: this.order.id
                }),
                {
                    onSuccess: (response) => {
                       console.log(response);
                    },

                    onError: (errors) => {
                        if (Object.keys(errors).filter((s) => s.includes('items')).length >= 1) {
                            this.openItemsModal = true
                        } else {
                            this.openShipmentModal = true;
                        }
                    }
                }
            )
        },

        denyOrder() {
            this.denyForm.put(
                route('orders.deny-pack', {
                    order: this.order.id
                }),
                {
                    onError: () => {
                        this.openDenyOrder = false
                    }
                }
            )
        },

        imageSrc(imageName) {
            return `/storage/images/${imageName}`;
        },

        validatePackedItems(item) {
            return item.packed_items <= item.quantity;
        },
    }
}
</script>
