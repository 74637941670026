<template>
    <Head :title="'Bestelling - ' + order.extern_id" />
    <AuthenticatedLayout>
        <template #header>
            <div class="flex flex-wrap items-center justify-between">
                <h1 class="text-2xl font-semibold text-gray-900">{{ $t('orders.order') }} - {{ order.extern_id }}</h1>
                <div v-if="currentTab === 'general'" class="space-x-2">
                    <Button v-if="can('update status order')" @click="updateOrderStatus = order">{{ $t('orders.change_status') }}</Button>
                    <NavButton v-if="can('edit order') && (order.status != 'completed')" :href="route('orders.edit', { order: order.id })">{{ $t('orders.edit_order') }}</NavButton>
                    <Button v-if="can('register payment') && order.status  == 'on-hold'" @click="registerPayment = order">{{ $t('orders.mark_paid') }}</Button>

                    <NavButton v-if="can('create credit payment') && !order.creditPayment && ((order.status == 'completed') || (order.status == 'processing') || (order.status == 'for inspection'))" :href="route('credit-payment.create', { order: order.id })">{{ $t('orders.create_credit_payment') }}</NavButton>
                    <NavButton v-if="can('update credit payment') && order.creditPayment && order.creditPayment.status === 'new' && ((order.status == 'completed') || (order.status == 'processing') || (order.status == 'for inspection'))" :href="route('credit-payment.edit', { order: order.id })">{{ $t('orders.edit_credit_payment') }}</NavButton>
                </div>
                <div v-if="currentTab === 'tickets'" class="space-x-2">
                    <NavButton v-if="can('create ticket')" :href="route('tickets.create', { order: order.id })">{{ $t('orders.create_ticket') }}</NavButton>
                </div>
                <div v-if="currentTab === 'notes'" class="space-x-2">
                    <Button v-if="can('place order note')" class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-gray-700 focus:border-gray-900 focus:outline-none active:bg-gray-900" @click="placeNote = order">{{ $t('orders.noted_add') }}</Button>
                </div>
            </div>

            <div class="mt-6">
                <div class="sm:hidden">
                    <label for="tabs" class="sr-only">Select tab</label>
                    <select id="tabs" v-model="currentTab" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                        <option value="general">
                            {{ $t('orders.order_details') }}
                        </option>

                        <option value="shipments">
                            {{ $t('orders.shipments') }}
                        </option>

                        <option v-if="can('view logs')" value="log">
                            {{ $t('orders.history') }}
                        </option>

                        <option v-if="can('view ticket')" value="tickets">
                            {{ $t('orders.tickets') }}
                        </option>
                    </select>
                </div>

                <div class="hidden sm:block">
                    <nav class="relative z-0 flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
                        <button :class="['general' === currentTab ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10 rounded-l-lg']"  @click="currentTab = 'general'">
                            {{ $t('orders.order_details') }}
                            <span aria-hidden="true" :class="['general' === currentTab ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
                        </button>

                        <button :class="['general' === currentTab ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10']"  @click="currentTab = 'shipments'">
                            {{ $t('orders.shipments') }} ({{ order.shipments.length }})
                            <span aria-hidden="true" :class="['shipments' === currentTab ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
                        </button>

                        <button v-if="can('view order notes')" :class="['general' === currentTab ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10 rounded-r-lg']"  @click="currentTab = 'notes'">
                            {{ $t('orders.notes') }} ({{ order.adminNotes.length }})
                            <span aria-hidden="true" :class="['ordernotes' === currentTab ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
                        </button>

                        <button v-if="can('view ticket')" :class="['general' === currentTab ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10 rounded-r-lg']"  @click="currentTab = 'tickets'">
                            {{ $t('orders.tickets') }} ({{ order.tickets.length }})
                            <span aria-hidden="true" :class="['tickets' === currentTab ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
                        </button>

                        <button v-if="can('view logs')" :class="['general' === currentTab ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10 rounded-r-lg']"  @click="currentTab = 'logs'">
                            {{ $t('orders.history') }}
                            <span aria-hidden="true" :class="['logs' === currentTab ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
                        </button>
                    </nav>
                </div>
            </div>
        </template>

        <div v-if="currentTab === 'general'" class="grid grid-cols-12 gap-8">
            <div v-if="order.packing_denied_message" class="col-span-12">
                <div class="overflow-hidden border border-orange-500 bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="py-4 sm:py-5 sm:px-6">
                                <h3 class="text-lg font-semibold">{{ $t('orders.order_pack_rejected') }}</h3>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-6 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.reason') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-5 sm:mt-0">
                                    {{ order.packing_denied_message }}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

            <div v-if="order.retour" class="col-span-12 md:col-span-6">
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="py-4 sm:py-5 sm:px-6">
                                <h3 class="text-lg font-semibold">{{ $t('orders.return_info') }}</h3>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.subtotal_after_return') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice(order.retour.subtotal) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.vat_after_return') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice(order.retour.tax) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.total_after_Return') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice(order.retour.total) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.pay_customer') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice(order.retour.pay_to_customer) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.status') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <status-badge :status="order.retour.status"></status-badge>
                                </dd>
                            </div>
                            <div v-if="order.retour.reason" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.reason_of_return') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <p class="rounded-lg border bg-gray-100 px-2 py-1 text-xs">
                                        {{ order.retour.reason }}
                                    </p>
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.action') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <NavButton
                                        v-if="can('view retour')"
                                        :href="
                                            route('retours.show', {
                                                retour: order.retour.id
                                            })
                                        "
                                    >
                                        {{ $t('orders.details') }}
                                    </NavButton>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

            <div v-if="order.retour" class="col-span-12 md:col-span-6">
                <div v-if="order.retour.items.length >= 1" class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                            <h3 class="text-lg font-semibold">{{ $t('orders.return_products') }}</h3>
                        </div>

                        <div class="flex flex-col">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div class="overflow-hidden shadow sm:rounded-lg">
                                        <table class="min-w-full">
                                            <tbody>
                                                <tr v-for="retourItem in order.retour.items" :key="retourItem.id" class="border-b border-gray-200 bg-white">
                                                    <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                                        {{ retourItem.quantity }}
                                                        x
                                                    </td>
                                                    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                                        <div>
                                                            {{ retourItem.variation.product.name }}
                                                        </div>
                                                        <div>
                                                            {{ $t('orders.barcode') }}
                                                            {{ retourItem.variation.barcode }}
                                                        </div>
                                                        <div>
                                                            {{ retourItem.variation.numbers_packed }}
                                                            {{ $t('orders.pieces_packed') }}
                                                        </div>
                                                    </td>
                                                    <td class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                                        <div>
                                                            {{ formatPrice(retourItem.total) }}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="order.retour.extra_costs.length >= 1" class="mt-4 overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                            <h3 class="text-lg font-semibold">{{ $t('orders.return_costs') }}</h3>
                        </div>

                        <div class="flex flex-col">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div class="overflow-hidden shadow sm:rounded-lg">
                                        <table class="min-w-full">
                                            <tbody>
                                                <tr v-for="retourExtraCost in order.retour.extra_costs" :key="retourExtraCost.id" class="border-b border-gray-200 bg-white">
                                                    <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                                        {{ retourExtraCost.name }}
                                                    </td>
                                                    <td class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                                        <div>
                                                            {{ formatPrice(retourExtraCost.price) }}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="order.sub_delivery_needed" class="col-span-12">
                <div class="border border-gray-200 py-4 sm:py-5 sm:px-6 bg-red-400">
                    <h3 class="text-lg font-bold text-white">{{ $t('orders.products_not_delivered') }}</h3>
                </div>
            </div>
            <div class="col-span-12 md:col-span-6">
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="py-4 sm:py-5 sm:px-6">
                                <h3 class="text-lg font-semibold">{{ $t('orders.order_info') }}</h3>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.id') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ order.extern_id }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.order_no') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ order.number }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.order_date') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ dayjs(order.ordered_at).format('lll') }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.subtotal') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice(order.order_subtotal/100) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.shipment') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice((order.shipping_subtotal)/100) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.vat') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice((order.order_tax+order.shipping_tax)/100) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.total_amount') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice(order.total/100) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.status') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <status-badge :status="order.status"></status-badge>
                                </dd>
                            </div>
                            <div v-if="order.shippingMethod && order.shippingMethod.name" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.shipping_method') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ order.shippingMethod.name }}
                                </dd>
                            </div>
                            <div v-if="order.notes" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.notes') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <p class="rounded-lg border bg-gray-100 px-2 py-1 text-xs">
                                        {{ order.notes }}
                                    </p>
                                </dd>
                            </div>
                            <div v-if="order.api_error" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">API {{ $t('orders.errors') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <code class="rounded-lg border bg-gray-100 px-2 py-1 text-xs">
                                        {{ order.api_error }}
                                    </code>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <div class="col-span-12 md:col-span-6">
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="py-4 sm:py-5 sm:px-6">
                                <h3 class="text-lg font-semibold">{{ $t('orders.customer_information') }}</h3>
                            </div>

                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.customer') }}</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div v-if="!order.customer">{{ $t('orders.guest') }}</div>
                                    <div v-else class="text-sm font-medium text-gray-900">
                                        <div>
                                            {{ order.customer.firstname }}
                                            {{ order.customer.lastname }}
                                        </div>
                                        <div class="text-sm text-gray-500">
                                            <div>
                                                {{ order.customer.email }}
                                            </div>
                                        </div>
                                        <div v-if="order.phone" class="text-xs text-gray-500">
                                            {{ order.phone }}
                                        </div>
                                    </div>
                                </dd>
                            </div>

                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.invoice_address') }}</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div class="text-sm font-medium text-gray-900">
                                        <div v-if="order.billing_company_name" class="font-bold">
                                            {{ order.billing_company_name }}
                                        </div>
                                        <div>
                                            {{ order.billing_firstname }}
                                            {{ order.billing_lastname }}
                                        </div>
                                    </div>
                                    <div class="text-sm text-gray-500">
                                        <div>
                                            {{ order.billing_address }}
                                        </div>

                                        <div>
                                            {{ order.billing_postcode }}
                                            {{ order.billing_city }}
                                            {{ order.billing_country }}
                                        </div>
                                    </div>
                                    <div v-if="order.email" class="mt-2 text-sm font-medium text-gray-900">
                                        {{ order.email }}
                                    </div>
                                    <div v-if="order.billing_address.phone" class="text-sm text-gray-500">
                                        {{ order.billing_address.phone }}
                                    </div>
                                </dd>
                            </div>

                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.delivery_address') }}</dt>
                                <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div class="text-sm font-medium text-gray-900">
                                        <div v-if="order.shipping_company_name" class="font-bold">
                                            {{ order.shipping_company_name }}
                                        </div>
                                        <div>
                                            {{ order.shipping_firstname }}
                                            {{ order.shipping_lastname }}
                                        </div>
                                    </div>
                                    <div class="text-sm text-gray-500">
                                        <div>
                                            {{ order.shipping_address }}
                                        </div>

                                        <div>
                                            {{ order.shipping_postcode }}
                                            {{ order.shipping_city }}
                                            {{ order.shipping_country }}
                                        </div>
                                    </div>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div class="mt-6 overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <dl>
                            <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                                <h3 class="text-lg font-semibold">{{ $t('orders.shipments') }}</h3>
                            </div>

                            <div class="flex flex-col">
                                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <div class="overflow-hidden">
                                            <table class="min-w-full">
                                                <tbody>
                                                    <tr v-for="shipment in order.shipments" :key="shipment.id" class="border-b border-gray-200 bg-white">
                                                        <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                                            <span v-if="!shipment.error">
                                                                <span v-if="shipment.barcode" class="underline">
                                                                    <a target="_blank" :href="shipment.tracking_url">{{ shipment.barcode }}</a>
                                                                </span>
                                                                <span v-else>
                                                                    {{ shipment.barcode ?? 'Geen barcode' }}
                                                                </span>
                                                            </span>
                                                            <span v-if="shipment.error" class="text-red-500">
                                                                {{ shipment.error }}
                                                            </span>
                                                        </td>
                                                        <td class="flex items-center justify-end space-x-1 whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                                            <a
                                                                v-if="can('download shipment') && shipment.label_file"
                                                                :href="
                                                                    route('shipment.download', {
                                                                        order: order.id,
                                                                        shipment: shipment.id,
                                                                        type: 'label'
                                                                    })
                                                                "
                                                                class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-800 p-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-gray-700 focus:border-gray-900 focus:outline-none active:bg-gray-900"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                    <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
                                                                </svg>
                                                            </a>
                                                            <a
                                                                v-if="can('download shipment') && shipment.packing_file"
                                                                :href="
                                                                    route('shipment.download', {
                                                                        order: order.id,
                                                                        shipment: shipment.id,
                                                                        type: 'packing'
                                                                    })
                                                                "
                                                                class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-800 p-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-gray-700 focus:border-gray-900 focus:outline-none active:bg-gray-900"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                                                                </svg>
                                                            </a>
                                                            <a
                                                                v-if="can('cancel shipment')"
                                                                :href="
                                                                    route('shipment.cancel', {
                                                                        order: order.id,
                                                                        shipment: shipment.id
                                                                    })
                                                                "
                                                                class="focus:shadow-outline-red inline-flex items-center rounded-md border border-transparent bg-red-500 p-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-red-700 focus:border-red-900 focus:outline-none active:bg-red-900"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                </svg>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="py-4 sm:py-5 sm:px-6 flex gap-3 items-center">
                                <Button v-if="can('create shipment') && order.status == 'processing'" @click="createShipment = order">Zending aanmaken</Button>
                                <span v-if="order.shipments.length == 0">
                                    {{  $t('orders.no_shipments_created') }}
                                </span>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

            <div v-if="order.sub_delivery_needed" class="col-span-12">
                <div class="overflow-hidden shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6 bg-red-400">
                            <h3 class="text-lg font-bold text-white">{{  $t('orders.needed_delivery') }}</h3>
                        </div>

                        <div class="flex flex-col">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div class="overflow-hidden shadow sm:rounded-lg">
                                        <table class="min-w-full">
                                            <tbody>
                                                <tr v-for="item in finalProductsForShipment" :key="item.id" class="border-b border-gray-200 bg-white">
                                                    <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 w-24">{{ item.quantity }} x</td>
                                                    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                                        {{ item.name }}
                                                    </td>
                                                    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">

                                                    </td>
                                                    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-span-12 md:col-span-6">
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                            <h3 class="text-lg font-semibold">{{  $t('orders.ordered_products') }}</h3>
                        </div>

                        <div class="flex flex-col">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div class="overflow-hidden shadow sm:rounded-lg">
                                        <table class="min-w-full">
                                            <thead>
                                            <tr class="border-b">
                                                <th class="px-6 py-4 text-left text-sm font-bold w-1/12">{{ $t('orders.quantity') }}</th>
                                                <th class="px-6 py-4 text-left text-sm font-bold w-4/12">{{ $t('orders.product') }}</th>
                                                <th class="px-6 py-4 text-left text-sm font-bold w-2/12">{{ $t('orders.subtotal') }}</th>
                                                <th class="px-6 py-4 text-left text-sm font-bold w-2/12">{{ $t('orders.vat') }}</th>
                                                <th class="px-6 py-4 text-left text-sm font-bold w-2/12">{{ $t('orders.total') }}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="item in order.items" :key="item.id" class="border-b border-gray-200 bg-white">
                                                <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 w-24">{{ item.quantity }} x</td>
                                                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                                    <template v-if="item.product.requires_packaging_attention === 1">
                                                        <span style="color: red;">{{ item.name }}</span>
                                                    </template>
                                                    <template v-if="item.product.requires_packaging_attention === 0">
                                                        {{ item.name }}
                                                    </template>
                                                </td>
                                                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                                    {{ formatPrice(item.subtotal/100) }}
                                                </td>
                                                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                                    {{ formatPrice(item.tax/100) }}
                                                </td>
                                                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                                    <strong>{{ formatPrice((item.total+item.tax)/100) }}</strong>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="order.fees.length > 0" class="overflow-hidden mt-8 bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                            <h3 class="text-lg font-semibold">{{  $t('orders.additional_fees') }}</h3>
                        </div>

                        <div class="flex flex-col">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div class="overflow-hidden shadow sm:rounded-lg">
                                        <table class="min-w-full">
                                            <thead>
                                            <tr class="border-b">
                                                <th class="px-6 py-4 text-left text-sm font-bold w-1/12">{{ $t('orders.quantity') }}</th>
                                                <th class="px-6 py-4 text-left text-sm font-bold w-4/12">{{ $t('orders.name') }}</th>
                                                <th class="px-6 py-4 text-left text-sm font-bold w-2/12">{{ $t('orders.subtotal') }}</th>
                                                <th class="px-6 py-4 text-left text-sm font-bold w-2/12">{{ $t('orders.vat') }}</th>
                                                <th class="px-6 py-4 text-left text-sm font-bold w-2/12">{{ $t('orders.total') }}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="fee in order.fees" :key="fee.id" class="border-b border-gray-200 bg-white">
                                                <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 w-24">1</td>
                                                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                                    {{ fee.name }}
                                                </td>
                                                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                                    {{ formatPrice(fee.subtotal/100) }}
                                                </td>
                                                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                                    {{ formatPrice(fee.tax/100) }}
                                                </td>
                                                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                                    <strong>{{ formatPrice(fee.total/100) }}</strong>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div v-if="order.creditPayment" class="col-span-12 md:col-span-6">
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="py-4 sm:py-5 sm:px-6">
                                <h3 class="text-lg font-semibold">{{ $t('orders.credit_info') }}</h3>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{  $t('orders.vat') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice((order.creditPayment.credit_tax)/100) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.subtotal') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice((order.creditPayment.credit_subtotal)/100) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.total_amount') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {{ formatPrice((order.creditPayment.credit_total)/100) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.reason') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <span v-if="creditPaymentReason !== null">{{ $t('credit-payment.' + creditPaymentReason.name) }}</span><span v-if="creditPayment.reason !== null">: {{ creditPayment.reason}}</span>
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('orders.status') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <status-badge :status="order.creditPayment.status"></status-badge>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

            <div v-if="order.packedItems.length >= 1" class="col-span-12">
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                            <h3 class="text-lg font-semibold">{{ $t('orders.packed_and_shipped') }}</h3>
                        </div>

                        <div class="flex flex-col">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div class="overflow-hidden shadow sm:rounded-lg">
                                        <table class="min-w-full">
                                            <tbody>
                                                <tr v-for="packedItem in order.packedItems" :key="packedItem.id" class="border-b border-gray-200 bg-white">
                                                    <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 w-24">
                                                        {{ packedItem.quantity }}
                                                        x
                                                    </td>
                                                    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                                        <div>
                                                            {{ packedItem.product.product.name }}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="currentTab === 'shipments'" class="grid grid-cols-12 gap-8">
            <div class="col-span-12">
                <div v-for="shipment in order.shipments" :key="shipment.id" class="overflow-hidden shadow sm:rounded-lg mb-4">
                    <div class="px-4 py-5 sm:p-0 bg-white">
                        <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                            <div class="flex flex-wrap items-center justify-between">
                                <div>
                                    <h3 class="text-lg font-semibold underline">
                                        <a target="_blank" :href="shipment.tracking_url">{{ shipment.barcode }}</a>
                                    </h3>
                                    <p>{{ shipment.carrier_status }}</p>
                                </div>
                                <p class="text-sm">{{ dayjs(shipment.created_at).format('LL LT') }}</p>
                            </div>
                        </div>

                        <div class="flex flex-col">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div class="overflow-hidden shadow sm:rounded-lg">
                                        <table class="min-w-full">
                                            <tbody>
                                                <tr v-for="shippedItem in shipment.items" :key="shippedItem.id" class="border-b border-gray-200 bg-white">
                                                    <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 w-24">
                                                        {{ shippedItem.quantity }}
                                                        x
                                                    </td>
                                                    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                                        <div>
                                                            {{ shippedItem.product.product.name }}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="currentTab === 'logs' && can('view logs')" class="grid grid-cols-12 gap-8">
            <div class="col-span-12">
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                            <h3 class="text-lg font-semibold">{{ $t('orders.history') }}</h3>
                        </div>

                        <div class="flex flex-col">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div class="overflow-hidden shadow sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                            <tr>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.id') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.created') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.type') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.changes') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.user') }}</th>
                                            </tr>
                                            </thead>
                                            <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="log in order.logs" :key="log.id" >
                                                <td class="whitespace-nowrap px-4 py-2 text-x font-medium text-gray-900">{{ log.id }}</td>
                                                <td class="whitespace-nowrap px-4 py-2">
                                                    <div class="text-xs font-medium text-gray-900">
                                                        {{ dayjs(log.created_at).format('LL') }}
                                                    </div>
                                                    <div class="text-xs text-gray-500">
                                                        {{ dayjs(log.created_at).format('LT') }}
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-4 py-2 text-x font-medium text-gray-900">
                                                    <template v-if="log.status_change">
                                                        {{ $t('orders.order_status_changed') }}
                                                    </template>
                                                    <template v-else-if="log.payment_method_change">
                                                        {{ $t('orders.payment_method_changed') }}
                                                    </template>
                                                    <template v-else-if="log.shipping_method_change">
                                                        {{ $t('orders.shipping_method_changed') }}
                                                    </template>
                                                    <template v-else-if="log.address_change">
                                                        {{ $t('orders.address_changed') }}
                                                    </template>
                                                    <template v-else>
                                                    </template>
                                                </td>
                                                <td class="whitespace-nowrap px-4 py-2 text-x font-medium text-gray-900">
                                                    <template v-if="log.status_change">
                                                        <table class="border-collapse w-full">
                                                            <thead>
                                                            <tr>
                                                                <th class="py-2 px-4 bg-gray-100 border-b text-left">{{ $t('orders.old') }}</th>
                                                                <th class="py-2 px-4 bg-gray-100 border-b text-left">{{ $t('orders.new') }}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <td class="py-2 px-4 border-b w-1/2">
                                                                    <status-badge :status="log.old_status"></status-badge>
                                                                </td>
                                                                <td class="py-2 px-4 border-b w-1/2">
                                                                    <status-badge :status="log.new_status"></status-badge>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </template>
                                                    <template v-else-if="log.payment_method_change">
                                                        { $t('orders.payment_method_changed') }}
                                                    </template>
                                                    <template v-else-if="log.shipping_method_change">
                                                        {{ $t('orders.shipping_method_changed') }}
                                                    </template>
                                                    <template v-else-if="log.address_change">
                                                        {{ $t('orders.address_changed') }}
                                                    </template>
                                                    <template v-else>

                                                    </template>
                                                </td>
                                                <td class="whitespace-nowrap px-4 py-2 text-x font-medium text-gray-900">
                                                    {{ log.user_name }}
                                                </td>

                                            </tr>

                                            <tr v-if="order.logs.length == 0">
                                                <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900" colspan="6">{{ $t('orders.no_results_found') }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="currentTab === 'tickets'" class="grid grid-cols-12 gap-8">
            <div class="col-span-12">
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                            <h3 class="text-lg font-semibold">{{ $t('orders.tickets') }}</h3>
                        </div>

                        <div class="flex flex-col">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div class="overflow-hidden shadow sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.id') }}</th>
                                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.created') }}</th>
                                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.prio') }}</th>
                                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.status') }}</th>

                                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.who_for') }}</th>
                                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.title') }}</th>
                                                    <th scope="col" class="relative px-4 py-2"><span class="sr-only">{{ $t('orders.view') }}</span></th>
                                                </tr>
                                            </thead>
                                            <tbody class="divide-y divide-gray-200 bg-white">
                                                <tr v-for="ticket in order.tickets" :key="ticket.id" >
                                                    <td class="whitespace-nowrap px-4 py-2 text-x font-medium text-gray-900">{{ ticket.id }}</td>
                                                    <td class="whitespace-nowrap px-4 py-2">
                                                        <div class="text-xs font-medium text-gray-900">
                                                            {{ dayjs(ticket.created_at).format('LL') }}
                                                        </div>
                                                        <div class="text-xs text-gray-500">
                                                            {{ dayjs(ticket.created_at).format('LT') }}
                                                        </div>
                                                    </td>
                                                    <td class="whitespace-nowrap px-4 py-2 text-x font-medium text-gray-900">{{ ticket.priority }}</td>
                                                    <td class="whitespace-nowrap px-4 py-2 text-x font-medium text-gray-900"><TicketStatusBadge :status="ticket.status"></TicketStatusBadge></td>
                                                    <td class="whitespace-nowrap px-4 py-2">
                                                        <div class="text-x font-medium text-gray-900">
                                                            A: {{ ticket.user.name }}
                                                        </div>
                                                        <div class="text-x font-medium text-gray-900">
                                                            V:
                                                            <span v-if="ticket.assigned_to_user">{{ ticket.assigned_to_user.name }}</span>
                                                            <span v-if="ticket.assigned_to_user && ticket.department"> / </span>
                                                            <span v-if="ticket.department">{{ ticket.department.name }}</span>
                                                        </div>
                                                    </td>
                                                    <td class="whitespace-nowrap px-4 py-2 text-m font-medium text-gray-900">{{ ticket.title }}</td>
                                                    <td class="whitespace-nowrap px-4 py-2 text-x font-medium">
                                                        <a class="text-indigo-600 hover:text-indigo-900" :href="route('tickets.show', { ticket: ticket.id })" target="_BLANK">{{ $t('orders.view') }}</a>
                                                    </td>
                                                </tr>

                                                <tr v-if="order.tickets.length == 0">
                                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900" colspan="6">{{ $t('orders.no_results_found') }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="can('view order notes') && currentTab === 'notes'" class="grid grid-cols-12 gap-8">
            <div class="col-span-12">
                <div class="overflow-visible bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-0">
                        <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                            <h3 class="text-lg font-semibold">{{ $t('orders.notes') }}</h3>
                        </div>

                        <div class="flex flex-col">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div class="overflow-visible shadow sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                            <tr>

                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.created') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.by') }}</th>
                                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.body') }}</th>
                                            </tr>
                                            </thead>
                                            <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="note in order.adminNotes" :key="note.id" class="even:bg-gray-100">
                                                <td class="whitespace-nowrap px-4 py-2">
                                                    <div class="text-m font-medium text-gray-900">
                                                        {{ dayjs(note.created_at).format('LL') }}
                                                    </div>
                                                    <div class="text-sm text-gray-500">
                                                        {{ dayjs(note.created_at).format('LT') }}
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-4 py-2">
                                                    <div class="text-m font-medium text-gray-900">{{ note.user.name }}</div>
                                                </td>

                                                <td class="px-4 py-2 w-full max-w-xs xl:max-w-lg">
                                                    <div class="relative group w-full" v-html="note.message">

                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="order.adminNotes.length == 0">
                                                <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900" colspan="3">{{ $t('orders.no_results_found') }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <update-status-modal v-if="can('update status order')" :order="updateOrderStatus" @close="updateOrderStatus = false" />
        <register-payment-model v-if="can('register payment')" :order="registerPayment" @close="registerPayment = false" />
        <create-shipment-modal v-if="can('create shipment')" :order="createShipment" @close="createShipment = false" />
        <create-note-modal v-if="can('place order note')" :order="placeNote" @close="placeNote = false" />
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Button from '@/Components/Button.vue'
import NavButton from '@/Components/NavButton.vue'
import UpdateStatusModal from '@/Pages/Orders/UpdateStatusModal.vue'
import CreateShipmentModal from '@/Pages/Orders/CreateShipmentModal.vue'
import CreateNoteModal from "@/Pages/Orders/CreateNoteModal.vue";
import StatusBadge from '@/Components/StatusBadge.vue'
import TicketStatusBadge from "@/Components/TicketStatusBadge.vue"
import print from 'print-js'
import {ref} from "vue"
import axios from "axios"

export default {
    components: {
        CreateNoteModal,
        StatusBadge,
        CreateShipmentModal,
        UpdateStatusModal,
        NavButton,
        Button,
        AuthenticatedLayout,
        Head,
        TicketStatusBadge,
    },
    props: {
        order: Object,
        creditPayment: Object,
        creditPaymentReason: Object,
        finalProductsForShipment: Object
    },
    data() {
        return {
            updateOrderStatus: false,
            createShipment: false,
            registerPayment: false,
            placeNote: false,

            currentTab: ref('general')
        }
    },
    created() {
        window.addEventListener('beforeunload', this.onBeforeUnload)

        if (this.$page.props.flash?.shipment_created_packing) {
            const shipment = this.$page.props.flash?.shipment_created_packing
            print(shipment.packing_file)
        }
    },
    beforeUnmount() {
        // Remove the event listener when the component is destroyed
        window.removeEventListener('beforeunload', this.onBeforeUnload);
    },
    methods: {
        async onBeforeUnload(event) {
            // Prevent the default behavior
            event.preventDefault();

            // Set a custom message (This may not work on all modern browsers due to security reasons)
            event.returnValue = 'Are you sure you want to leave?';

            await axios.post(route('orders.unlock', {
                order: this.order.id
            }), {});
        }
    }
}
</script>
