<template>
    <Head :title="'Ticket bewerken - ' + ticket.id" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex flex-wrap justify-between">
                <div>
                    <h1 class="text-2xl font-semibold text-gray-900">Ticket bewerken - {{ ticket.id }}</h1>
                </div>
                <div>
                    <NavButton v-if="can('view ticket')" :href="route('tickets.show', {ticket: ticket.id})">Terug naar ticket</NavButton>
                </div>
            </div>
        </template>

        <form method="POST" class="overflow-hidden bg-white p-6 shadow-sm sm:rounded-lg" @submit.prevent="createStore">
            <div class="grid grid-cols-12 gap-6">
                <div class="col-span-9">
                    <div class="grid grid-cols-6 gap-8">
                        <div class="col-span-6">
                            <Label for="title" value="Titel" required/>
                            <Input id="title" v-model="form.title" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="title" />
                        </div>

                        <div class="col-span-6">
                            <Label for="content" value="Inhoud" required/>
                            <vue-editor v-model="form.body"></vue-editor>
                        </div>
                    </div>
                </div>
                <div class="col-span-3 ">
                    <div class="grid col-span-1 gap-4">
                        <div class="">
                            <Label for="customer" value="Klant" required/>
                            <multiselect
                                v-model="form.customer_id"
                                :options="customerOptions"
                                :searchable="true"
                                :loading="isLoading"
                                :internal-search="false"
                                :clear-on-select="true"
                                :close-on-select="true"
                                :hide-selected="true"
                                :preserve-search="true"
                                :max-height="200"
                                :placeholder="'Klant zoeken...'"
                                @search-change="fetchCustomers"
                            />
                            <ValidationError name="customer" />
                        </div>

                        <div>
                            <Label for="order" value="Order" required/>
                            <multiselect
                                v-model="form.order_id"
                                :options="orderOptions"
                                :searchable="true"
                                :loading="isLoading"
                                :internal-search="false"
                                :clear-on-select="true"
                                :close-on-select="true"
                                :hide-selected="true"
                                :preserve-search="true"
                                :max-height="200"
                                :placeholder="'Bestelling zoeken...'"
                                @search-change="fetchOrders"
                            />
                            <ValidationError name="order" />
                        </div>

                        <div>
                            <div class="col-span-6">
                                <Label for="department_id" value="Afdeling" required/>
                                <Select id="department_id" v-model="form.department_id" class="mt-1 block w-full">
                                    <option value="" selected disabled>Maak een keuze</option>
                                    <option v-for="department in departments" :key="department.id" :value="department.id"> {{ department.name}}</option>
                                </Select>
                                <ValidationError name="department_id" />
                            </div>
                        </div>

                        <div>
                            <div class="col-span-6">
                                <Label for="assigned_to" value="Toewijzen aan" required/>
                                <Select id="assigned_to" v-model="form.assigned_to" class="mt-1 block w-full">
                                    <option value="" selected disabled hidden>Maak een keuze</option>
                                    <option v-for="user in users" :key="user.id" :value="user.id"> {{ user.name}}</option>
                                </Select>
                                <ValidationError name="assigned_to" />
                            </div>
                        </div>

                        <div>
                            <div class="col-span-6">
                                <Label for="category" value="Categorie" required/>
                                <Select id="category" v-model="form.category" class="mt-1 block w-full">
                                    <option value="" selected disabled hidden>Maak een keuze</option>
                                    <option v-for="category in ticketCategories" :key="category.id" :value="category.id"> {{ category.name}}</option>
                                </Select>
                                <ValidationError name="category" />
                            </div>
                        </div>

                        <div>
                            <div class="col-span-6">
                                <Label for="priority" value="Prioriteit" required/>
                                <Select id="priority" v-model="form.priority" class="mt-1 block w-full">
                                    <option value="" selected disabled hidden>Maak een keuze</option>
                                    <option value="low">Laag</option>
                                    <option value="normal">Normaal</option>
                                    <option value="high">Hoog</option>
                                </Select>
                                <ValidationError name="priority" />
                            </div>
                        </div>

                        <div class="space-x-2 text-right">
                            <Button type="submit" :disabled="form.processing">Opslaan</Button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import Button from '@/Components/Button.vue'
import ValidationError from '@/Components/ValidationError.vue'
import NavButton from '@/Components/NavButton.vue'
import { VueEditor } from "vue3-editor"
import Multiselect from '@vueform/multiselect'
import Select from "@/Components/Select.vue"

export default {
    components: {
        Select,
        NavButton,
        Button,
        ValidationError,
        Input,
        Label,
        AuthenticatedLayout,
        Head,
        VueEditor,
        Multiselect,
    },
    props: {
        users: Object,
        ticketCategories: Object,
        ticket: Object,
        departments: Object,
    },
    data() {
        return {
            form: this.$inertia.form({
                id: this.ticket.id,
                title: this.ticket.title,
                order_id: this.ticket.order_id,
                customer_id: this.ticket.customer_id,
                body: this.ticket.message,
                priority: this.ticket.priority,
                department_id: this.ticket.department_id,
                assigned_to: this.ticket.assigned_to,
                status: this.ticket.status,
                category: this.ticket.categories[0].id,
            }),

            orderOptions: [{
                value: this.ticket.order ? this.ticket.order_id : null,
                label: this.ticket.order ? this.ticket.order.email + ' ' + this.ticket.order.number + ' ' + this.ticket.order.ordered_at : ''
            }],

            customerOptions: [{
                value: this.ticket.customer ? this.ticket.customer_id : null,
                label: this.ticket.customer ? this.ticket.customer.email + ' ' + this.ticket.customer.firstname + ' ' + this.ticket.customer.lastname + ' ' + this.ticket.customer.id : ''
            }],
        }
    },
    methods: {
        createStore() {
            this.form.put(route('tickets.update', { ticket: this.ticket.id }), {
                preserveScroll: true
            })
        },

        fetchOrders(searchquery) {
            if (searchquery.length === 0) {
                return;
            }
            axios.get(route('tickets.search.orders', {query: searchquery}))
                .then(res => {
                    this.orderOptions = res.data.map(order => ({
                        value: order.id,
                        label: order.email + ' - Order extern # ' + order.extern_id + ' - ' + order.ordered_at,
                    }));
                })
        },

        fetchCustomers(searchquery) {
            if (searchquery.length === 0) {
                return;
            }
            axios.get(route('tickets.search.customers', {query: searchquery}))
                .then(res => {
                    this.customerOptions = res.data.map(customer => ({
                        value: customer.id,
                        label: customer.email + ' (' + customer.firstname + ' ' + customer.lastname + ') #' + customer.id,
                    }));
                })
        },
    }
}
</script>
