<template>
    <form method="POST" class="bg-white p-6 shadow-sm sm:rounded-lg" @submit.prevent="$emit('submit')">
        <div class="grid grid-cols-6 gap-8">
            <div class="flex flex-col col-span-3 gap-4">
                <div class="col-span-6">
                    <Label for="code">{{ $t('coupons.code')
                        }}<span class="text-red-500 text-xs text-bold">*</span></Label>
                    <Input id="code" v-model="modelValue.code" type="text" class="mt-1 block w-full" required />
                    <ValidationError name="code" />
                </div>

                <div class="col-span-6">
                    <Label for="description" :value="$t('coupons.description')" />
                    <Input id="description" v-model="modelValue.description" type="text" class="mt-1 block w-full" />
                    <ValidationError name="description" />
                </div>

                <div class="col-span-6">
                    <Label for="date_expires" :value="$t('coupons.date_expires')" />
                    <Input id="date_expires" v-model="modelValue.date_expires" type="date" class="mt-1 block w-full" />
                    <ValidationError name="date_expires" />
                </div>

                <div class="col-span-6">
                    <Label for="usage_limit" :value="$t('coupons.usage_limit')" />
                    <Input id="usage_limit" v-model="modelValue.usage_limit" type="number" class="mt-1 block w-full" />
                    <ValidationError name="usage_limit" />
                </div>

                <div class="col-span-6">
                    <Label for="usage_limit_per_user" :value="$t('coupons.usage_limit_per_user')" />
                    <Input id="usage_limit_per_user" v-model="modelValue.usage_limit_per_user" type="number"
                        class="mt-1 block w-full" />
                    <ValidationError name="usage_limit_per_user" />
                </div>

                <div class="col-span-6">
                    <Label for="limit_usage_to_x_items" :value="$t('coupons.limit_usage_to_x_items')" />
                    <Input id="limit_usage_to_x_items" v-model="modelValue.limit_usage_to_x_items" type="number"
                        class="mt-1 block w-full" />
                    <ValidationError name="limit_usage_to_x_items" />
                </div>

                <div class="col-span-6">
                    <Label for="included_products" :value="$t('coupons.included_products')" />
                    <Multiselect :options="selectableProducts" mode="tags" v-model="modelValue.included_products"
                        label="name" />
                </div>
                <div class="col-span-6">
                    <Label for="excluded_products" :value="$t('coupons.excluded_products')" />
                    <Multiselect :options="selectableProducts" mode="tags" v-model="modelValue.excluded_products"
                        label="name" />
                </div>
            </div>

            <div class="flex flex-col col-span-3 gap-4">
                <div class="col-span-6">
                    <Label for="amount">{{ $t('coupons.amount')
                        }}<span class="text-red-500 text-xs text-bold">*</span></Label>
                    <Input id="amount" v-model="modelValue.amount" type="number" class="mt-1 block w-full" required />
                    <ValidationError name="amount" />
                </div>

                <div class="col-span-6">
                    <Label for="discount_type" :value="$t('coupons.discount_type')" />
                    <Select id="discount_type" v-model="modelValue.discount_type" class="mt-1 block w-full"
                        :placeholder="$t('coupons.choose_discount_type')">
                        <option v-for="(option, idx) in discountTypeOptions" :key="idx" :value="option"
                            :selected="modelValue.discount_type === option">
                            {{ $t('coupons.' + option) }}
                        </option>
                    </Select>
                    <ValidationError name="discount_type" />
                </div>

                <div class="col-span-6">
                    <Label for="minimum_amount" :value="$t('coupons.minimum_amount')" />
                    <Input id="minimum_amount" v-model="modelValue.minimum_amount" type="number"
                        class="mt-1 block w-full" />
                    <ValidationError name="minimum_amount" />
                </div>
                <div class="col-span-6">
                    <Label for="maximum_amount" :value="$t('coupons.maximum_amount')" />
                    <Input id="maximum_amount" v-model="modelValue.maximum_amount" type="number"
                        class="mt-1 block w-full" />
                    <ValidationError name="maximum_amount" />
                </div>
                <div class="col-span-6">
                    <Label for="email_restrictions" :value="$t('coupons.email_restrictions')" />
                    <Multiselect v-if="emailRestrictions" id="email_restrictions" v-model="emailRestrictions"
                        :options="emailOptions" mode="tags" create-option searchable class="mt-1 block w-full"
                        :placeholder="$t('coupons.email_restrictions_placeholder')" />
                    <ValidationError name="email_restrictions" />
                    <ValidationError v-for="(email, idx) in modelValue.email_restrictions"
                        :name="`email_restrictions.${idx}`" />
                </div>

                <div class="col-span-6">
                    <Label class="flex items-center">
                        <Checkbox v-model:checked="modelValue.individual_use" name="individual_use" />
                        <span class="ml-2 text-sm text-gray-600">{{ $t('coupons.individual_use') }}</span>
                    </Label>
                </div>
                <div class="col-span-6">
                    <Label class="flex items-center">
                        <Checkbox v-model:checked="modelValue.free_shipping" name="free_shipping" />
                        <span class="ml-2 text-sm text-gray-600">{{ $t('coupons.free_shipping') }}</span>
                    </Label>
                </div>
                <div class="col-span-6">
                    <Label class="flex items-center">
                        <Checkbox v-model:checked="modelValue.exclude_sale_items" name="exclude_sale_items" />
                        <span class="ml-2 text-sm text-gray-600">{{ $t('coupons.exclude_sale_items') }}</span>
                    </Label>
                </div>
            </div>
        </div>
        <div class="col-span-6 space-x-2 text-right">
            <NavButton :href="route('coupons.index')">{{ $t('coupons.back') }}</NavButton>
            <Button type="submit">{{ $t('coupons.save') }}</Button>
        </div>
    </form>
</template>

<script setup>
import Select from '@/Components/Select.vue'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import ValidationError from '@/Components/ValidationError.vue'
import Checkbox from '@/Components/Checkbox.vue'
import Multiselect from '@vueform/multiselect'
import { computed, onMounted, ref, watch, watchEffect } from 'vue'
import NavButton from '@/Components/NavButton.vue'
import Button from '@/Components/Button.vue'

const props = defineProps({
    modelValue: {
        type: Object,
    },
    products: {
        type: Object,
    },
    edit: {
        type: Boolean,
        default: false,
    }
});

defineEmits(['submit']);

const discountTypeOptions = [
    'fixed_cart',
    'percent',
    'fixed_product'];

const selectableProducts = computed(() => {
    //TODO fix problems with pre-loaded items not getting filtered until the select is used again
    const keys = Object.keys(props.products);
    const filteredKeys = keys.filter(key => !props.modelValue.included_products.includes(key) && !props.modelValue.excluded_products.includes(key));
    return filteredKeys.reduce((acc, key) => {
        acc[key] = props.products[key];
        return acc;
    }, {});
});

const emailRestrictions = ref(props.modelValue.email_restrictions.map(item => item.email));

const emailOptions = ref([]);

onMounted(() => {
    // Set existing emails as options to prevent losing already entered e-mails
    emailOptions.value = (emailRestrictions.value);
});

watchEffect(() => {
    props.modelValue.email_restrictions = emailRestrictions.value;
});
</script>