<template>
    <Head :title="'Rol - ' + role.name" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex flex-wrap items-center justify-between">
                <div>
                    <h1 class="text-2xl font-semibold text-gray-900">Rol - {{ role.name }}</h1>
                    <h3 class="text-lg text-gray-900">Winkel - {{ store.name }}</h3>
                </div>
                <div class="space-x-2">
                    <NavButton
                        v-if="can('edit store role')"
                        :href="
                            route('stores.roles.edit', {
                                store: store.id,
                                role: role.id
                            })
                        "
                    >
                        Wijzigen
                    </NavButton>
                    <Button v-if="can('delete store role')" @click="confirmingRoleDeletion = true">Verwijderen</Button>
                </div>
            </div>
        </template>

        <div class="overflow-hidden bg-white shadow sm:rounded-lg">
            <div class="px-4 py-5 sm:p-0">
                <dl class="sm:divide-y sm:divide-gray-200">
                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">Naam</dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {{ role.name }}
                        </dd>
                    </div>
                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">Permissies</dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <div v-for="permission in currentPermissions" :key="permission">
                                {{ permission }}
                            </div>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
        <ConfirmationModal v-if="can('delete store role')" :show="confirmingRoleDeletion" @close="confirmingRoleDeletion = false">
            <template #title>Rol verwijderen - {{ role.name }}</template>

            <template #content>Weet je zeker dat je deze rol wilt verwijderen?</template>

            <template #footer>
                <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="deleteRole">Rol verwijderen</button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="confirmingRoleDeletion = false">Annuleren</button>
            </template>
        </ConfirmationModal>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Button from '@/Components/Button.vue'
import NavButton from '@/Components/NavButton.vue'
import ConfirmationModal from '@/Components/ConfirmationModal.vue'

export default {
    components: {
        ConfirmationModal,
        NavButton,
        Button,
        AuthenticatedLayout,
        Head
    },
    props: {
        role: Object,
        store: Object,
        currentPermissions: Object
    },

    data() {
        return {
            confirmingRoleDeletion: false,
            form: this.$inertia.form({})
        }
    },
    methods: {
        deleteRole() {
            this.form.delete(
                route('stores.roles.destroy', {
                    store: this.store.id,
                    role: this.role.id
                })
            )
        }
    }
}
</script>
