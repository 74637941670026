<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="fixed inset-0 z-10 overflow-y-auto" @close="$emit('close')">
            <div class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" @click="close" />
                </TransitionChild>

                <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="inline-table transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
                        <div>
                            <div class="flex">
                                <div v-if="!$slots.icon" class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                    <InformationCircleIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                                </div>
                                <XMarkIcon @click="$emit('close')" class="h-6 w-6" style="margin-left: -21px; cursor: pointer" />
                            </div>
                            <slot name="icon" />
                            <div class="mt-3 text-center sm:mt-5">
                                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                                    <slot name="title" />
                                </DialogTitle>
                                <div class="mt-2">
                                    <p class="text-sm text-gray-500">
                                        <slot name="content" />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <slot name="footer" />
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

import { InformationCircleIcon, XMarkIcon } from '@heroicons/vue/24/solid'

const props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
});
const emit = defineEmits(['close']);

const close = () => {
    emit('close')
};

const closeOnEscape = (e) => {
    if (e.key === 'Escape' && props.show) 
        close();
}

onMounted(() => document.addEventListener('keydown', closeOnEscape))
onUnmounted(() => document.removeEventListener('keydown', closeOnEscape));
</script>
