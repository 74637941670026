<template>
    <Head :title="'Variatie aanmaken'" />

    <AuthenticatedLayout>
        <template #header>
            <div>
                <h1 class="text-2xl font-semibold text-gray-900">Variatie aanmaken</h1>
                <h3 class="text-lg text-gray-900">Product - {{ product.name }}</h3>
                <h3 class="text-lg text-gray-900">Winkel - {{ store.name }}</h3>
            </div>
        </template>

        <form method="POST" class="overflow-hidden bg-white p-6 shadow-sm sm:rounded-lg" @submit.prevent="createStore">
            <div class="grid grid-cols-6 gap-8">
                <div class="col-span-3">
                    <Label for="name" value="Naam" />
                    <Input id="name" v-model="form.name" type="text" class="mt-1 block w-full" />
                    <ValidationError name="name" />
                </div>
                <div class="col-span-3">
                    <Label for="barcode" value="Barcode" />
                    <Input id="barcode" v-model="form.barcode" type="text" class="mt-1 block w-full" />
                    <ValidationError name="barcode" />
                </div>

                <div class="col-span-3">
                    <Label for="numbers_packed" value="Aantallen verpakt" />
                    <Input id="numbers_packed" v-model="form.numbers_packed" type="number" class="mt-1 block w-full" />
                    <ValidationError name="numbers_packed" />
                </div>

                <div class="col-span-3">
                    <Label for="quantity" value="Voorraad" />
                    <Input id="quantity" v-model="form.quantity" type="number" class="mt-1 block w-full" />
                    <ValidationError name="quantity" />
                </div>

                <div class="col-span-3">
                    <Label for="low_stock" value="Lage voorraad bij" />
                    <Input id="low_stock" v-model="form.low_stock" type="number" class="mt-1 block w-full" />
                    <ValidationError name="low_stock" />
                </div>

                <div class="col-span-6 md:col-span-3">
                    <Label for="cost_price" value="Inkoopprijs" />

                    <div class="relative mt-1 rounded-md shadow-sm">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span class="text-gray-500 sm:text-sm">€</span>
                        </div>
                        <input id="cost_price" v-model="form.cost_price" type="number" class="w-full rounded-md border-gray-300 pl-7 pr-12 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="0.00" aria-describedby="price-currency" />
                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span id="price-currency" class="text-gray-500 sm:text-sm">EUR</span>
                        </div>
                    </div>

                    <ValidationError name="cost_price" />
                </div>

                <hr class="col-span-6" />

                <div class="col-span-6">
                    <h1 class="mb-4 text-xl font-bold text-gray-900">Kostenposten</h1>

                    <div v-for="(extraCost, index) in form.extraCosts" :key="index" class="mb-4 grid grid-cols-10 gap-4 py-2">
                        <div class="col-span-5">
                            <Label :for="'extra_cost-' + index + '-name'" value="Naam" />
                            <Input :id="'extra_cost-' + index + '-name'" v-model="extraCost.name" type="text" class="mt-1 block w-full" />
                            <ValidationError :name="'extraCosts.' + index + '.name'" />
                        </div>

                        <div class="col-span-5">
                            <Label :for="'extra_cost-' + index + '-price'" value="Prijs" />

                            <div class="flex">
                                <MoneyInput :id="'extra_cost-' + index + '-price'" v-model="extraCost.price" class="mt-1 block flex-1" />
                                <Button class="ml-1" type="button" @click="deleteExtraCost(index)">X</Button>
                            </div>
                            <ValidationError :name="'extraCosts.' + index + '.price'" />
                        </div>
                    </div>

                    <div v-if="form.extraCosts.length == 0" class="mb-4">Op dit moment zijn er geen kostenposten aangemaakt.</div>

                    <Button type="button" @click="addExtraCost">Kostenpost toevoegen</Button>
                </div>

                <hr class="col-span-6" />

                <div class="col-span-6 space-x-2 text-right">
                    <NavButton
                        :href="
                            route('products.show', {
                                store: store.id,
                                product: product.id
                            })
                        "
                    >
                        Terug
                    </NavButton>
                    <Button type="submit">Opslaan</Button>
                </div>
            </div>
        </form>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import Button from '@/Components/Button.vue'
import ValidationError from '@/Components/ValidationError.vue'
import NavButton from '@/Components/NavButton.vue'
import MoneyInput from '@/Components/MoneyInput.vue'

export default {
    components: {
        MoneyInput,
        NavButton,
        Button,
        ValidationError,
        Input,
        Label,
        AuthenticatedLayout,
        Head
    },
    props: {
        store: Object,
        product: Object
    },
    data() {
        return {
            form: this.$inertia.form({
                name: '',
                barcode: '',
                cost_price: 0,
                low_stock: 0,
                numbers_packed: 1,
                quantity: 0,
                extraCosts: []
            })
        }
    },
    methods: {
        createStore() {
            this.form.post(
                route('products.variations.store', {
                    store: this.store.id,
                    product: this.product.id
                }),
                {
                    preserveScroll: true
                }
            )
        },
        addExtraCost() {
            this.form.extraCosts.push({
                name: '',
                price: 5
            })
        },
        deleteExtraCost(index) {
            this.form.extraCosts.splice(index, 1)
        }
    }
}
</script>
