import '../css/app.css'

//Inertia
import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'

//Uses
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import _ from 'lodash'
import axios from 'axios'
import { i18nVue } from 'laravel-vue-i18n'
import * as Sentry from '@sentry/vue'

window._ = _
window.axios = axios
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

//Mixins
import formattingMixin from './Mixins/Formatting.vue'
import permissionsMixin from './Mixins/Permissions.vue'

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Backoffice'

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
        return pages[`./Pages/${name}.vue`]
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
            environment: import.meta.env.APP_ENV,
            tracesSampleRate: import.meta.env.SENTRY_TRACES_SAMPLE_RATE,
            trackComponents: true,
            logErrors: true,
        });

        app.use(plugin)
            .use(Toast)
            .use(ZiggyVue, Ziggy)
            .use(i18nVue, {
                resolve: async lang => {
                    const langs = import.meta.glob('../../lang/*.json');
                    return await langs[`../../lang/${lang}.json`]();
                }
            })
            .mixin(formattingMixin)
            .mixin(permissionsMixin)
            .mount(el)

        return app
    },
    progress: {
        color: '#4B5563'
    }
})
