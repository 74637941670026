<template>
    <span class="inline-flex rounded-full px-2 text-xs font-semibold leading-5" :class="color ?? 'bg-gray-100 text-gray-800'">
        <slot></slot>
    </span>
</template>

<script>
export default {
    props: {
        status: String
    },
    computed: {
        text() {
            return {
                low: this.$t('status.low'),
                normal: this.$t('status.normal'),
                high: this.$t('status.high')
            }[this.status.toLowerCase()]
        },
        color() {
            return {
                normal: 'bg-blue-500 text-white',
                medium: 'bg-green-100 text-green-800',
                high: 'bg-red-500 text-white'
            }[this.status.toLowerCase()]
        }
    }
}
</script>
