<template>
    <Head title="Inloggen" />

    <ValidationErrors class="mb-4" />

    <div v-if="status" class="mb-4 text-sm font-medium text-green-600">
        {{ status }}
    </div>

    <form @submit.prevent="submit">
        <div>
            <Label for="email" v-bind:value="$t('auth.email')" />
            <Input id="email" v-model="form.email" type="email" class="mt-1 block w-full" required autofocus autocomplete="username" />
        </div>

        <div class="mt-4">
            <Label for="password" v-bind:value="$t('auth.label_password')" />
            <Input id="password" v-model="form.password" type="password" class="mt-1 block w-full" required autocomplete="current-password" />
        </div>

        <div class="mt-4 block">
            <label class="flex items-center">
                <Checkbox v-model:checked="form.remember" name="remember" />
                <span class="ml-2 text-sm text-gray-600">{{ $t('auth.remember_me') }}</span>
            </label>
        </div>

        <div class="mt-4 flex items-center justify-end">
            <Link v-if="canResetPassword" :href="route('password.request')" class="text-sm text-gray-600 underline hover:text-gray-900">{{ $t('auth.link_forgot_password') }}</Link>

            <Button class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">{{ $t('auth.login') }}</Button>
        </div>
    </form>
</template>

<script>
import Button from '@/Components/Button.vue'
import Checkbox from '@/Components/Checkbox.vue'
import GuestLayout from '@/Layouts/Guest.vue'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import ValidationErrors from '@/Components/ValidationErrors.vue'
import { Head, Link } from '@inertiajs/vue3'

export default {
    components: {
        Button,
        Checkbox,
        Input,
        Label,
        ValidationErrors,
        Head,
        Link
    },
    layout: GuestLayout,

    props: {
        canResetPassword: Boolean,
        status: String
    },

    data() {
        return {
            form: this.$inertia.form({
                email: '',
                password: '',
                remember: false
            })
        }
    },

    methods: {
        submit() {
            this.form.post(this.route('login'), {
                onFinish: () => this.form.reset('password')
            })
        }
    }
}
</script>
