<template>
    <Head :title="$t('orders.order_pack') + ' - ' + order.number" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex items-center justify-between">
                <h1 class="mt-2 text-2xl font-semibold text-gray-900">{{ order.number }}</h1>

                <div class="mt-2 flex items-center justify-center space-x-4">
                    <NavButton v-if="can('view order')" :href="route('orders.show', { order: order.id })">{{ $t('orders.to_order') }}</NavButton>

                    <button class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-red-600 focus:border-red-900 focus:outline-none active:bg-red-900" @click="openDenyOrder = true">{{ $t('orders.to_check') }}</button>
                    <button v-if="can('create shipment') && order.shipments.length > 0" class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-gray-700 focus:border-gray-900 focus:outline-none active:bg-gray-900" @click="openSuccesModal = true">{{ $t('orders.mark_as_completed') }}</button>
                    <button v-if="can('create shipment')" class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-green-600 focus:border-green-900 focus:outline-none active:bg-green-900" @click="openPackModal = true">{{ $t('orders.pack_and_send') }}</button>
                </div>
            </div>

            <div class="mt-6">
                <div class="sm:hidden">
                    <label for="tabs" class="sr-only">Selecteer tab</label>
                    <select id="tabs" v-model="currentTab" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                        <option :selected="currentTab === 'general'">
                            {{ $t('orders.details') }}
                        </option>

                        <option :selected="currentTab === 'shipments'">
                            {{ $t('orders.shipments') }}
                        </option>

                        <option :selected="currentTab === 'tickets'">
                            {{ $t('orders.tickets') }}
                        </option>
                    </select>

                    <select id="tabs" v-model="currentTab" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                        <option v-for="tab in tabs" :key="tab.name" :selected="tab.action === currentTab">
                            {{ tab.name }}
                        </option>
                    </select>
                </div>

                <div class="hidden sm:block">
                    <nav class="relative z-0 flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">

                        <button :class="['general' === currentTab ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10 rounded-l-lg']"  @click="currentTab = 'general'">
                            {{ $t('orders.details') }}
                            <span aria-hidden="true" :class="['general' === currentTab ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
                        </button>

                        <button :class="['general' === currentTab ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10']"  @click="currentTab = 'shipments'">
                            {{ $t('orders.shipments') }} ({{ order.shipments.length }})
                            <span aria-hidden="true" :class="['shipments' === currentTab ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
                        </button>

                        <button :class="['general' === currentTab ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10 rounded-r-lg']"  @click="currentTab = 'tickets'">
                            {{ $t('orders.tickets') }} ({{ order.tickets.length }})
                            <span aria-hidden="true" :class="['tickets' === currentTab ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
                        </button>
                    </nav>
                </div>
            </div>
        </template>


        <div>
            <div v-if="currentTab === 'general'" class="grid grid-cols-12 gap-8">
                <div class="col-span-12 md:col-span-4">
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:p-0">
                            <dl class="sm:divide-y sm:divide-gray-200">
                                <div class="py-4 sm:py-5 sm:px-6">
                                    <h3 class="text-lg font-semibold">{{ $t('orders.order_info') }}</h3>
                                </div>
                                <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">{{ $t('orders.id') }}</dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ order.id }}</dd>
                                </div>
                                <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">{{ $t('orders.external_id') }}</dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ order.extern_id }}</dd>
                                </div>
                                <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">{{ $t('orders.ordered_on') }}</dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ dayjs(order.ordered_at).format('lll') }}</dd>
                                </div>

                                <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">{{ $t('orders.shipping_method') }}</dt>
                                    <dd class="text-m font-bold text-gray-900 sm:col-span-2" style="margin-top:-2px">{{ order.shippingMethod.name }}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>

                <div class="col-span-12 md:col-span-4">
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:p-0">
                            <dl class="sm:divide-y sm:divide-gray-200">
                                <div class="py-4 sm:py-5 sm:px-6">
                                    <h3 class="text-lg font-semibold">{{ $t('orders.deliver_info') }}</h3>
                                </div>
                                <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">{{ $t('orders.deliver_address') }}</dt>
                                    <dd class="mt-1 sm:col-span-2 sm:mt-0">
                                        <div class="text-sm font-medium text-gray-900">
                                            <div v-if="order.shipping_company_name" class="font-bold">
                                                {{ order.shipping_company_name }}
                                            </div>
                                            <div>
                                                {{ order.shipping_firstname }}
                                                {{ order.shipping_lastname }}
                                            </div>
                                        </div>
                                        <div class="text-sm text-gray-500">
                                            <div>
                                                {{ order.shipping_address }}
                                            </div>

                                            <div>
                                                {{ order.shipping_postcode }}
                                                {{ order.shipping_city }}
                                                {{ order.shipping_country }}
                                            </div>
                                        </div>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>


                <div class="col-span-12 md:col-span-4">
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:p-0">
                            <dl>
                                <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                                    <h3 class="text-lg font-semibold">{{ $t('orders.shipments') }}</h3>
                                </div>

                                <div class="flex flex-col">
                                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                            <div class="overflow-hidden">
                                                <table class="min-w-full">
                                                    <tbody>
                                                    <tr v-for="shipment in order.shipments" :key="shipment.id" class="border-b border-gray-200 bg-white">
                                                        <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                                            <span v-if="!shipment.error">
                                                                {{ shipment.barcode ?? $t('orders.no_barcode') }}
                                                            </span>
                                                            <span v-if="shipment.error" class="text-red-500">
                                                                {{ $t('orders.shipment_error') }}
                                                            </span>
                                                        </td>


                                                        <td class="flex items-center justify-end space-x-1 whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                                            <a
                                                                v-if="can('download shipment') && shipment.label_file"
                                                                :href="
                                                                    route('shipment.download', {
                                                                        order: order.id,
                                                                        shipment: shipment.id,
                                                                        type: 'label'
                                                                    })
                                                                "
                                                                class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-800 p-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-gray-700 focus:border-gray-900 focus:outline-none active:bg-gray-900"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                    <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
                                                                </svg>
                                                            </a>

                                                            <a
                                                                v-if="can('download shipment') && shipment.packing_file"
                                                                :href="
                                                                    route('shipment.download', {
                                                                        order: order.id,
                                                                        shipment: shipment.id,
                                                                        type: 'packing'
                                                                    })
                                                                "
                                                                class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-800 p-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-gray-700 focus:border-gray-900 focus:outline-none active:bg-gray-900"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                                                                </svg>
                                                            </a>
                                                            <a
                                                                v-if="can('cancel shipment')"
                                                                :href="
                                                                    route('shipment.cancel', {
                                                                        order: order.id,
                                                                        shipment: shipment.id
                                                                    })
                                                                "
                                                                class="focus:shadow-outline-red inline-flex items-center rounded-md border border-transparent bg-red-500 p-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-red-700 focus:border-red-900 focus:outline-none active:bg-red-900"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                </svg>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="py-4 sm:py-5 sm:px-6">
                                    <div>
                                    <span v-if="order.shipments.length == 0" class="text-sm text-gray-500">
                                        {{ $t('orders.no_shipments') }}
                                    </span>
                                    </div>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>

                <div v-if="order.sub_delivery_needed" class="col-span-12">
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:p-0">
                            <dl class="sm:divide-y sm:divide-gray-200">
                                <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6 bg-red-400">
                                    <h3 class="text-lg text-white font-semibold">{{ $t('orders.needed_delivery') }}</h3>
                                </div>
                                <div class="w-full md:w-auto">
                                    <table class="w-full divide-y divide-gray-300">
                                        <thead class="bg-gray-50">
                                        <tr class="divide-x divide-gray-200">
                                            <th scope="col" class="w-1/4 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                                            <th scope="col" class="w-1/2 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6">{{ $t('orders.product') }}</th>
                                            <th scope="col" class="w-1/4 px-4 py-3.5 text-left text-sm font-semibold text-gray-900">{{ $t('orders.need') }}</th>
                                        </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-200">
                                        <tr v-for="item in finalProductsForShipment" :key="item.id" class="divide-x divide-gray-200 even:bg-gray-100">
                                            <td class="w-1/4 whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
<!--                                                <img v-if="item.product.image" :src="item.product.image" :alt="item.name" class="mr-6 h-30 w-50 rounded object-cover object-center" />-->
                                            </td>
                                            <td class="w-1/2 whitespace-nowrap py-4 pl-4 pr-4 text-right text-xl font-bold text-gray-900 sm:pl-6">{{ item.name }}</td>
                                            <td class="w-1/4 whitespace-nowrap p-4 text-xl font-bold text-gray-500">{{ item.quantity }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>


                <div class="col-span-12">
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:p-0">
                            <dl class="sm:divide-y sm:divide-gray-200">
                                <div class="py-4 sm:py-5 sm:px-6">
                                    <h3 class="text-lg font-semibold">{{ $t('orders.ordered_products') }}</h3>
                                </div>
                                <div class="w-full md:w-auto">
                                    <table class="w-full divide-y divide-gray-300">
                                        <thead class="bg-gray-50">
                                        <tr class="divide-x divide-gray-200">
                                            <th scope="col" class="w-1/4 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                                            <th scope="col" class="w-1/2 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6">{{ $t('orders.product') }}</th>
                                            <th scope="col" class="w-1/4 px-4 py-3.5 text-left text-sm font-semibold text-gray-900">{{ $t('orders.need') }}</th>
                                        </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-200">
                                            <tr v-for="item in order.items" :key="item.id" class="divide-x divide-gray-200 even:bg-gray-100">
                                                <td class="w-1/4 whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                                                    <img v-if="item.product.image" :src="item.product.image" :alt="item.name" class="mr-6 h-30 w-50 rounded object-cover object-center" />
                                                </td>
                                                <td class="w-1/2 whitespace-nowrap py-4 pl-4 pr-4 text-right text-xl font-bold text-gray-900 sm:pl-6">
                                                    <template v-if="item.product.requires_packaging_attention === 1">
                                                        <span style="color: red;">{{ item.name }}</span>
                                                    </template>
                                                    <template v-if="item.product.requires_packaging_attention === 0">
                                                        {{ item.name }}
                                                    </template>
                                                </td>
                                                <td class="w-1/4 whitespace-nowrap p-4 text-xl font-bold text-gray-500">{{ item.quantity }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="currentTab === 'shipments'" class="grid grid-cols-12 gap-8">
                <div class="col-span-12">
                    <div v-for="shipment in order.shipments" :key="shipment.id" class="overflow-hidden shadow sm:rounded-lg mb-4">
                        <div class="px-4 py-5 sm:p-0 bg-white">
                            <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                                <div class="flex flex-wrap items-center justify-between">
                                    <div>
                                        <h3 class="text-lg font-semibold">
                                            {{ shipment.barcode }}
                                        </h3>
                                        <p>{{ shipment.carrier_status }}</p>
                                    </div>
                                    <p class="text-sm">{{ dayjs(shipment.created_at).format('LL LT') }}</p>
                                </div>
                            </div>

                            <div class="flex flex-col">
                                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <div class="overflow-hidden shadow sm:rounded-lg">
                                            <table class="min-w-full">
                                                <tbody>
                                                <tr v-for="shippedItem in shipment.items" :key="shippedItem.id" class="border-b border-gray-200 bg-white">
                                                    <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 w-24">
                                                        {{ shippedItem.quantity }}
                                                        x
                                                    </td>
                                                    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                                        <div>
                                                            {{ shippedItem.product.product.name }}
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <span v-if="order.shipments.length === 0">{{ $t('orders.no_shipments_created') }}</span>
                </div>
            </div>

            <div v-if="currentTab === 'tickets'" class="grid grid-cols-12 gap-8">
                <div class="col-span-12">
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:p-0">
                            <div class="border-b border-gray-200 py-4 sm:py-5 sm:px-6">
                                <h3 class="text-lg font-semibold">{{ $t('orders.tickets') }}</h3>
                            </div>

                            <div class="flex flex-col">
                                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <div class="overflow-hidden shadow sm:rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-200">
                                                <thead class="bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.id') }}</th>
                                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.created') }}</th>
                                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.prio') }}</th>
                                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.status') }}</th>

                                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.who_for') }}</th>
                                                    <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('customers.title') }}</th>

                                                    <th scope="col" class="relative px-4 py-2"><span class="sr-only">{{ $t('orders.view') }}</span></th>
                                                </tr>
                                                </thead>
                                                <tbody class="divide-y divide-gray-200 bg-white">
                                                <tr v-for="ticket in order.tickets" :key="ticket.id" >
                                                    <td class="whitespace-nowrap px-4 py-2 text-x font-medium text-gray-900">{{ ticket.id }}</td>
                                                    <td class="whitespace-nowrap px-4 py-2">
                                                        <div class="text-xs font-medium text-gray-900">
                                                            {{ dayjs(ticket.created_at).format('LL') }}
                                                        </div>
                                                        <div class="text-xs text-gray-500">
                                                            {{ dayjs(ticket.created_at).format('LT') }}
                                                        </div>
                                                    </td>
                                                    <td class="whitespace-nowrap px-4 py-2 text-x font-medium text-gray-900">{{ ticket.priority }}</td>
                                                    <td class="whitespace-nowrap px-4 py-2 text-x font-medium text-gray-900"><TicketStatusBadge :status="ticket.status"></TicketStatusBadge></td>
                                                    <td class="whitespace-nowrap px-4 py-2">
                                                        <div class="text-x font-medium text-gray-900">
                                                            A: {{ ticket.user.name }}
                                                        </div>
                                                        <div class="text-x font-medium text-gray-900">
                                                            V:
                                                            <span v-if="ticket.assigned_to_user">{{ ticket.assigned_to_user.name }}</span>
                                                            <span v-if="ticket.assigned_to_user && ticket.department"> / </span>
                                                            <span v-if="ticket.department">{{ ticket.department.name }}</span>
                                                        </div>
                                                    </td>
                                                    <td class="whitespace-nowrap px-4 py-2 text-m font-medium text-gray-900">{{ ticket.title }}</td>
                                                    <td class="whitespace-nowrap px-4 py-2 text-x font-medium">
                                                        <a class="text-indigo-600 hover:text-indigo-900" :href="route('tickets.show', { ticket: ticket.id })" target="_BLANK">{{ $t('orders.view') }}</a>
                                                    </td>
                                                </tr>

                                                <tr v-if="order.tickets.length == 0">
                                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900" colspan="6">{{ $t('orders.no_results_found') }}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmationModal v-if="can('create shipment')" :show="openPackModal" @close="openPackModal = false">
                <template #title>{{ $t('orders.products_present') }}</template>
                <template #icon>
                    <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <ClipboardIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                </template>

                <template #content>
                    <div class="w-full md:w-auto">
                        <table class="w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                            <tr class="divide-x divide-gray-200">
                                <th scope="col" class="w-1/2 py-3.5 pl-4 pr-4 text-right text-sm font-bold   text-gray-900 sm:pl-6">{{ $t('orders.product') }}</th>
                                <th scope="col" class="w-1/4 px-4 py-3.5 text-left text-sm font-bold text-gray-900">{{ $t('orders.need') }}</th>
                            </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="item in order.items" :key="item.id" class="divide-x divide-gray-200 even:bg-gray-100">
                                    <td class="w-1/2 whitespace-nowrap py-4 pl-4 pr-4 text-right text-xl font-bold text-gray-900 sm:pl-6">
                                        <template v-if="item.product.requires_packaging_attention === 1">
                                            <span style="color: red !important;">{{ item.name }}</span>
                                        </template>
                                        <template v-if="item.product.requires_packaging_attention === 0">
                                            {{ item.name }}
                                        </template>
                                    </td>
                                    <td class="w-1/4 whitespace-nowrap p-4 text-xl font-bold text-gray-500">{{ item.quantity }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>

                <template #footer>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-red-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="openPackModal = false; openItemsModal = true; packAndShipForm.all_items = false">{{ $t('orders.no') }}</button>
                    <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-green-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="packOrderAndShip();openPackModal = false;">{{ $t('orders.yes') }}</button>
                </template>
            </ConfirmationModal>

            <ConfirmationModal v-if="can('create shipment')" :show="openItemsModal" @close="openItemsModal = false">
                <template #title>{{ $t('orders.what_cant_shipped') }}</template>
                <template #icon>
                    <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <ClipboardIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                </template>

                <template #content>
                    <div class="w-full md:w-auto">
                        <table class="w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                            <tr class="divide-x divide-gray-200">
                                <th scope="col" class="w-5/12 py-3.5 pl-4 pr-4 text-right text-sm font-bold text-gray-900 sm:pl-6">{{ $t('orders.product') }}</th>
                                <th scope="col" class="w-1/4 px-4 py-3.5 text-left text-sm font-bold text-gray-900">{{ $t('orders.need') }}</th>
                                <th scope="col" class="w-1/4 px-4 py-3.5 text-left text-sm font-bold text-gray-900">{{ $t('orders.deliverable') }}</th>
                            </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">

                            <tr v-for="(item, index) in finalProductsForShipment" :key="item.id" class="divide-x divide-gray-200 even:bg-gray-100">
                                <td class="w-5/12 whitespace-nowrap py-4 pl-4 pr-4 text-right text-m font-bold text-gray-900 sm:pl-6">{{ item.name }}</td>
                                <td class="w-1/4 whitespace-nowrap p-4 text-m text-left font-bold text-gray-900">{{ item.quantity }}</td>
                                <td class="w-1/4 whitespace-nowrap p-4 text-m text-left font-bold text-gray-900">

                                    <Input
                                        id="packed_items"
                                        v-model="item.packed_items"
                                        type="text"
                                        :max="item.quantity"
                                        :class="[    'mt-1 block w-full',    validatePackedItems(item) && typeof $page.props.errors['items.' + index + '.packed_items'] == 'undefined' ? '' : 'border-red-500']"
                                        required
                                        @input="validatePackedItems(item) || toast.error('Je kunt niet meer items leveren dan de klant heeft besteld')"
                                    />

                                    <ValidationError :name="'items.' + index + '.packed_items'" />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </template>

                <template #footer>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="openItemsModal = false; openPackModal = true">{{ $t('orders.cancel') }}</button>
                    <button
                        type="button"
                        :disabled="!isFormValid"
                        class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-green-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                        @click="openShipmentModal = true; openItemsModal = false;"
                    >{{ $t('orders.next') }}</button>
                </template>
            </ConfirmationModal>

            <ConfirmationModal v-if="can('create shipment')" :show="openShipmentModal" @close="openShipmentModal = false">
                <template #title>{{ $t('orders.order_pack_and_ship')}} - {{ order.extern_id }}</template>

                <template #icon>
                    <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                </template>

                <template #content>
                    <div class="grid grid-cols-12 gap-4 text-left">
                        <div class="col-span-12">{{ $t('orders.sure_order_pack_and_ship')}}</div>

                        <div class="col-span-12">
                            <Label for="company_name" value="Bedrijfsnaam" />
                            <Input id="company_name" v-model="packAndShipForm.company_name" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="company_name" />
                        </div>

                        <div class="col-span-6">
                            <Label for="firstname" value="Voornaam *" />
                            <Input id="firstname" v-model="packAndShipForm.firstname" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="firstname" />
                        </div>
                        <div class="col-span-6">
                            <Label for="lastname" value="Achternaam *" />
                            <Input id="lastname" v-model="packAndShipForm.lastname" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="lastname" />
                        </div>
                        <div class="col-span-6">
                            <Label for="email" value="E-mailadres *" />
                            <Input id="email" v-model="packAndShipForm.email" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="email" />
                        </div>
                        <div class="col-span-6">
                            <Label for="phone" value="Telefoonnummer" />
                            <Input id="phone" v-model="packAndShipForm.phone" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="phone" />
                        </div>
                        <div class="col-span-7">
                            <Label for="street_name" value="Straatnaam *" />
                            <Input id="street_name" v-model="packAndShipForm.street_name" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="street_name" />
                        </div>
                        <div class="col-span-3">
                            <Label for="house_number" value="Huisnummer *" />
                            <Input id="house_number" v-model="packAndShipForm.house_number" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="house_number" />
                        </div>
                        <div class="col-span-2">
                            <Label for="house_number_ext" value="Toevoeging" />
                            <Input id="house_number_ext" v-model="packAndShipForm.house_number_ext" type="text" class="mt-1 block w-full" />
                            <ValidationError name="house_number_ext" />
                        </div>
                        <div class="col-span-4">
                            <Label for="postcode" value="Postcode *" />
                            <Input id="postcode" v-model="packAndShipForm.postcode" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="postcode" />
                        </div>

                        <div class="col-span-4">
                            <Label for="city" value="Stad *" />
                            <Input id="city" v-model="packAndShipForm.city" type="text" class="mt-1 block w-full" required />
                            <ValidationError name="city" />
                        </div>

                        <div class="col-span-4">
                            <Label for="country" value="Land *" />
                            <Input id="country" v-model="packAndShipForm.country" type="text" class="mt-1 block w-full" required disabled/>
                            <ValidationError name="country" />
                        </div>
                    </div>
                </template>

                <template #footer>
                    <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="packOrderAndShip()">{{ $t('orders.yes')}}</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="openShipmentModal = false; openPackModal = true">{{ $t('orders.no')}}</button>
                </template>
            </ConfirmationModal>

            <ConfirmationModal :show="openDenyOrder" @close="openDenyOrder = false">
                <template #title>{{ $t('orders.order_to_check')}} - {{ order.extern_id }}</template>

                <template #content>
                    {{ $t('orders.sure_order_to_check')}}

                    <div class="mt-4 text-left">
                        <Label for="packing_denied_message" value="Reden" />
                        <Textarea id="packing_denied_message" v-model="denyForm.packing_denied_message" class="mt-1 block w-full" />
                        <ValidationError name="packing_denied_message" />
                    </div>
                </template>

                <template #footer>
                    <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="denyOrder()">{{ $t('orders.yes')}}</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="openDenyOrder = false">{{ $t('orders.no')}}</button>
                </template>
            </ConfirmationModal>

            <ConfirmationModal :show="openSuccesModal" @close="openSuccesModal = false">
                <template #icon>
                    <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                </template>

                <template #title>{{ $t('orders.order-complete')}} {{ order.number }}</template>

                <template #footer>
                    <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-green-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="markOrderCompletedAndReturn()">{{ $t('orders.yes')}}</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="openSuccesModal = false">{{ $t('orders.no')}}</button>
                </template>
            </ConfirmationModal>
        </div>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import NavButton from '@/Components/NavButton.vue'
import { useToast } from 'vue-toastification'
import Input from '@/Components/Input.vue'
import ConfirmationModal from '@/Components/ConfirmationModal.vue'
import { CheckIcon, ClipboardIcon } from '@heroicons/vue/24/outline'
import ValidationError from '@/Components/ValidationError.vue'
import Label from '@/Components/Label.vue'
import {ref} from "vue"
import axios from 'axios'

export default {
    components: {
        Label,
        ValidationError,
        NavButton,
        AuthenticatedLayout,
        Head,
        Input,
        ConfirmationModal,
        CheckIcon,
        ClipboardIcon
    },
    props: {
        order: Object,
        packItems: Object,
        finalProductsForShipment: Object
    },
    data() {
        return {
            toast: useToast(),

            packItemsFinal: [],

            openPackOrderAndShip: false,
            openPackOrder: false,
            openDenyOrder: false,
            createShipment: false,

            openPackModal: false,
            openItemsModal: false,
            openShipmentModal: false,
            openSuccesModal: false,

            denyForm: this.$inertia.form({
                packing_denied_message: ''
            }),

            packForm: this.$inertia.form({
                items: null
            }),

            completeForm: this.$inertia.form({}),
            packAndShipForm: this.$inertia.form({
                items: null,
                all_items: true,
                order_number: null,
                company_name: null,
                firstname: null,
                lastname: null,
                email: null,
                phone: null,
                street_name: null,
                house_number: null,
                house_number_ext: null,
                postcode: null,
                city: null,
                country: null,
                carrier: 'PostNL',
                carrier_code: null,
                weight: 100,
                height: 10,
                volume: 50,
                width: 10,
                length: 18,

                type: null,
            }),

            currentTab: ref('general')
        }
    },

    computed: {
        isFormValid() {
            return this.order.items.every((item) => this.validatePackedItems(item));
        },
    },

    created() {
        window.addEventListener('beforeunload', this.onBeforeUnload)

        this.packItemsFinal = this.finalProductsForShipment

        this.packAndShipForm.order_number = this.order? this.order.number : null
        this.packAndShipForm.company_name = this.order? this.order.shipping_company_name : null
        this.packAndShipForm.firstname = this.order ? this.order.shipping_firstname : null
        this.packAndShipForm.lastname = this.order ? this.order.shipping_lastname : null
        this.packAndShipForm.email = this.order ? this.order.email : null
        this.packAndShipForm.phone = this.order ? this.order.shipping_address.phone : null
        this.packAndShipForm.street_name = this.order ? this.order.splitted_shipping_address.street_name : null
        this.packAndShipForm.house_number = this.order ? this.order.splitted_shipping_address.house_number : null
        this.packAndShipForm.house_number_ext = this.order ? this.order.splitted_shipping_address.house_number_ext : null
        this.packAndShipForm.postcode = this.order ? this.order.shipping_postcode : null
        this.packAndShipForm.city = this.order ? this.order.shipping_city : null
        this.packAndShipForm.country = this.order ? this.order.shipping_country : null
        this.packAndShipForm.type = this.order ? this.order.shippingMethod.bagisto_name : null
        this.packAndShipForm.carrier_code = this.order ? this.order.shippingMethod.parcel_code : null
    },
    beforeUnmount() {
        // Remove the event listener when the component is destroyed
        window.removeEventListener('beforeunload', this.onBeforeUnload);
    },

    methods: {
        async onBeforeUnload(event) {
            // Prevent the default behavior
            event.preventDefault();

            // Set a custom message (This may not work on all modern browsers due to security reasons)
            event.returnValue = 'Are you sure you want to leave?';

            await axios.post(route('orders.unlock', {
                order: this.order.id
            }), {});
        },

        packOrderAndShip() {
            this.packAndShipForm.items = this.finalProductsForShipment
            this.openShipmentModal = false

            this.packAndShipForm.put(
                route('pack.save-pack-and-ship', {
                    order: this.order.id
                }),
                {
                    onSuccess: () => {
                       this.openSuccesModal = true
                    },

                    onError: (errors) => {
                        if (Object.keys(errors).filter((s) => s.includes('items')).length >= 1) {
                            this.openItemsModal = true
                        } else {
                            this.openShipmentModal = true
                        }
                    }
                }
            )
        },

        denyOrder() {
            this.denyForm.put(
                route('pack.deny-pack', {
                    order: this.order.id
                }),
                {
                    onError: () => {
                        this.openDenyOrder = false
                    }
                }
            )
        },

        markOrderCompletedAndReturn() {
            this.completeForm.put(
                route('pack.mark-complete', {
                    order: this.order.id
                }),
                {
                    onError: () => {

                    }
                }
            )
        },

        imageSrc(imageName) {
            return `/storage/images/${imageName}`;
        },

        validatePackedItems(item) {
            return item.packed_items <= item.quantity;
        },
    }
}
</script>
