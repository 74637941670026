<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="fixed inset-0 z-10 overflow-y-auto" @close="close()">
            <div class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300"
                                 enter-from="opacity-0" enter-to="opacity-100"
                                 leave="ease-in duration-200"
                                 leave-from="opacity-100"
                                 leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>

                <TransitionChild as="template" enter="ease-out duration -300"
                                 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                 enter-to="opacity-100 translate-y-0 sm:scale-100"
                                 leave="ease-in duration-200"
                                 leave-from="opacity-100 translate-y-0 sm:scale-100"
                                 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle overflow-visible">
                        <div>
                            <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-indigo-100">
                                <ChartBarSquareIcon class="h-6 w-6 text-indigo-600" aria-hidden="true" />
                            </div>
                            <div class="mt-3 sm:mt-5">
                                <DialogTitle as="h3" class="text-center text-lg font-medium leading-6 text-gray-900">{{ $t('reports.generate_report') }}</DialogTitle>
                                <div class="mt-4 space-y-4">
                                    <div>
                                        <Label for="type" v-bind:value="$t('reports.type')" />
                                        <Select id="type" v-model="form.type" class="mt-1 block w-full">
                                            <option v-if="can('view default report')" value="default" :selected="form.type == 'default'">{{ $t('reports.standard') }}</option>
                                            <option v-if="can('view turnover report')" value="turnover_report" :selected="form.type == 'turnover_report'">{{ $t('reports.turnover') }}</option>
                                            <option v-if="can('view purchase report')" value="purchase_report" :selected="form.type == 'purchase_report'">{{ $t('reports.purchase') }}</option>
                                            <option v-if="can('view profit share report')" value="profit_share_report" :selected="form.type == 'profit_share_report'">{{ $t('reports.profit_sharing') }}</option>
                                            <option v-if="can('view sales report')" value="sales_report" :selected="form.type == 'sales_report'">{{ $t('reports.sales_per_product') }}</option>
                                            <option v-if="can('view unit sales report')" value="unit_sales_report" :selected="form.type == 'unit_sales_report'">{{ $t('reports.sales_per_unit') }}</option>
                                            <option v-if="can('view incoming shipments report')" value="incoming_shipments_report" :selected="form.type == 'incoming_shipments_report'">{{ $t('reports.incoming_shipments') }}</option>
                                        </Select>
                                    </div>
                                    <div v-show="form.type == 'default' || form.type == 'sales_report' || form.type == 'turnover_report'">
                                        <Label for="period" value="Periode" />
                                        <Select id="period" v-model="form.period" class="mt-1 block w-full">
                                            <option value="daily">{{  $t('reports.daily') }}</option>
                                            <option value="weekly">{{  $t('reports.weekly') }}</option>
                                            <option value="monthly">{{  $t('reports.monthly') }}</option>
                                            <option value="yearly">{{  $t('reports.yearly') }}</option>
                                        </Select>
                                    </div>
                                    <div>
                                        <Label for="from" v-bind:value="$t('reports.date_range')" />
                                        <VueDatePicker v-model="dateRange" range required locale="nl" :format="formatDate" auto-apply
                                            :week-numbers="{type: 'iso'}" :start-date="new Date()" :enable-time-picker="false"
                                            :month-picker="form.period === 'monthly'" :week-picker="form.period === 'weekly'" :year-picker="form.period === 'yearly'" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 sm:mt-6">
                            <Button class="w-full justify-center" @click="generateReport">{{  $t('reports.generate_report') }}</Button>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ChartBarSquareIcon } from '@heroicons/vue/24/outline'
import Button from '@/Components/Button.vue'
import Input from '@/Components/Input.vue'
import Label from '@/Components/Label.vue'
import Select from '@/Components/Select.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import {DateTime} from 'luxon';

export default {
    components: {
        Select,
        Label,
        Input,
        Button,
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        ChartBarSquareIcon,
        VueDatePicker,
    },
    props: {
        open: Boolean,
        type: String,
        from: String,
        to: String,
        period: String
    },
    emits: ['close'],

    data() {
        return {
            form: this.$inertia.form({
                type: this.type,
                from: this.from,
                to: this.to,
                period: this.period
            }),
            dateRange: [new Date(), new Date()],
        }
    },
    methods: {
        generateReport() {
            this.transformDateRangeToForm();
            this.form.get(this.route('report.index'));
        },
        close() {
            this.$emit('close');
        },
        transformDateRangeToForm() {
            if (this.form.period === 'weekly') {
                this.form.from = DateTime.fromJSDate(this.dateRange[0][0]).toISODate();
                this.form.to = DateTime.fromJSDate(this.dateRange[this.dateRange.length -1][1]).toISODate();
            } else if (this.form.period === 'monthly') {
                this.form.from = DateTime.fromObject({year: this.dateRange[0].year, month: this.dateRange[0].month + 1}).toISODate();
                this.form.to = DateTime.fromObject({year: this.dateRange[1].year, month: this.dateRange[1].month + 1}).toISODate();
            } else if(this.form.period === 'yearly') {
                this.form.from = DateTime.fromObject({year: this.dateRange[0]}).toISODate();
                this.form.to = DateTime.fromObject({year: this.dateRange[1], month: 12, day: 31}).toISODate();
            } else {
                this.form.from = DateTime.fromJSDate(this.dateRange[0]).toISODate();
                this.form.to = DateTime.fromJSDate(this.dateRange[1]).toISODate();
            }
        },
        formatDate(dateRange) {
            if (this.form.period === 'yearly') {
                if (dateRange[0].getFullYear() === dateRange[1].getFullYear()) return dateRange[0].getFullYear();
                return `${dateRange[0].getFullYear()} - ${dateRange[1].getFullYear()}`;
            }
            if (this.form.period === 'weekly') {
                const date1 = DateTime.fromJSDate(dateRange[0]);
                const date2 = DateTime.fromJSDate(dateRange[1]).plus({days: 6});
                return `Van ${date1.toLocaleString()} tot ${date2.toLocaleString()}`
            } else {
                const date1 = DateTime.fromJSDate(dateRange[0]);
                const date2 = DateTime.fromJSDate(dateRange[1]);
                return `Van ${date1.toLocaleString()} tot ${date2.toLocaleString()}`
            }
        },
    },
}
</script>
